import Vue from "vue";
import { ADD_RESPONSE_MUTATION, EDIT_RESPONSE_MUTATION, DELETE_RESPONSE_MUTATION, REORDER_RESPONSE_MUTATION, SET_RESPONSE_ERROR_MUTATION, } from "@/store/types";
import checkLogic from "@/lib/logic";
import Papa from "papaparse";
import { cloneDeep, debounce } from "lodash";
export default Vue.extend({
    name: "ResponseList",
    props: {
        responses: {
            type: Array,
            required: true,
        },
        subgroupId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            selected: null,
            form: null,
            search: "",
            type: "",
        };
    },
    watch: {
        subgroupId() {
            this.selected = null;
            this.type = "";
        },
        selected(current) {
            if (current === null) {
                this.type = "";
            }
            else if (current.isHeading) {
                this.type = "heading";
            }
            else if (current.isMoreDetail) {
                this.type = "moreDetail";
            }
            else if (current.isRiskScore) {
                this.type = "riskScore";
            }
            else {
                this.type = "";
            }
        },
        type(current) {
            const form = this.form;
            if (!form) {
                return;
            }
            form.isHeading = current === "heading";
            form.isMoreDetail = current === "moreDetail";
            form.isRiskScore = current === "riskScore";
            if (!form.isHeading) {
                form.headingSize = "";
            }
            else if (form.headingSize === "") {
                form.headingSize = "3";
            }
        },
    },
    methods: {
        checkLogic: debounce(function (e) {
            checkLogic(e.target);
        }, 500),
        onUpdate(e) {
            if (e.oldIndex !== undefined && e.newIndex !== undefined) {
                this.reorderResponse(e.oldIndex, e.newIndex);
            }
        },
        add() {
            this.selected = null;
            this.form = {
                id: "",
                name: "",
                text: "",
                status: "",
            };
        },
        addCSV(e) {
            Papa.parse(e.target.files[0], {
                header: true,
                transformHeader: (header) => header.toLowerCase(),
                skipEmptyLines: true,
                complete: (res) => {
                    const errorPrefix = `Cannot parse responses CSV file '${e.target.files[0].name}'.`;
                    e.target.closest("form").reset(); // reset value so next @change event is triggered
                    if (res.errors.length > 0) {
                        const errors = res.errors.map((v) => v.message).join(", ");
                        this.setError(`${errorPrefix} ${errors}`);
                        return;
                    }
                    const fields = res.meta.fields;
                    if (!(fields === null || fields === void 0 ? void 0 : fields.includes("name")) || !fields.includes("logic") || !fields.includes("text")) {
                        fields === null || fields === void 0 ? void 0 : fields.sort((a, b) => a.localeCompare(b));
                        this.setError(`${errorPrefix} Expected columns 'name', 'logic' and 'text', but found '${fields === null || fields === void 0 ? void 0 : fields.join("', '")}'.`);
                        return;
                    }
                    // remove responses if not listed in CSV data
                    this.responses.forEach(({ id }) => this.removeResponse(id));
                    // insert new responses
                    res.data.forEach((response) => this.saveResponse(response));
                },
            });
        },
        edit(response) {
            this.selected = response;
            this.form = cloneDeep(response);
        },
        cancel() {
            this.form = null;
        },
        filteredResponses() {
            if (this.search.length === 0) {
                return this.responses;
            }
            return this.responses.filter((v) => Object.values(v)
                .filter((v) => typeof v === "string")
                .some((v) => v.includes(this.search)));
        },
        setError(error) {
            this.$store.commit(`editor/responses/${SET_RESPONSE_ERROR_MUTATION}`, error);
        },
        removeResponse(responseId) {
            const { subgroupId } = this;
            this.$store.commit(`editor/responses/${DELETE_RESPONSE_MUTATION}`, { subgroupId, responseId });
            this.form = null;
            this.selected = null;
        },
        saveResponse(response) {
            if (response.id) {
                this.$store.commit(`editor/responses/${EDIT_RESPONSE_MUTATION}`, response);
            }
            else {
                const { subgroupId } = this;
                this.$store.commit(`editor/responses/${ADD_RESPONSE_MUTATION}`, { subgroupId, response });
            }
            this.selected = this.form;
            this.form = null;
        },
        reorderResponse(from, to) {
            const { subgroupId } = this;
            this.$store.commit(`editor/responses/${REORDER_RESPONSE_MUTATION}`, { subgroupId, from, to });
        },
    },
});

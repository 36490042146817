import Vue from "vue";
import { ADD_RESPONSE_SUBGROUP_MUTATION, EDIT_RESPONSE_SUBGROUP_MUTATION, DELETE_RESPONSE_SUBGROUP_MUTATION, REORDER_RESPONSE_SUBGROUP_MUTATION, } from "@/store/types";
import LanceResponses from "./Responses.vue";
import checkLogic from "@/lib/logic";
import { cloneDeep, debounce } from "lodash";
export default Vue.extend({
    name: "ResponseSubgroupList",
    components: { LanceResponses },
    props: {
        subgroups: {
            type: Array,
            required: true,
        },
        groupId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            selected: null,
            form: null,
        };
    },
    computed: {
        responses() {
            return this.$store.getters["editor/responses/responsesForSubgroup"](this.selected);
        },
    },
    watch: {
        groupId() {
            this.selected = null;
        },
    },
    methods: {
        checkLogic: debounce(function (e) {
            checkLogic(e.target);
        }, 500),
        onUpdate(e) {
            if (e.oldIndex !== undefined && e.newIndex !== undefined) {
                this.reorderSubgroup(e.oldIndex, e.newIndex);
            }
        },
        select(subgroup) {
            var _a;
            this.selected = ((_a = this.selected) === null || _a === void 0 ? void 0 : _a.id) === subgroup.id ? null : subgroup;
            this.form = null;
        },
        add() {
            this.selected = null;
            this.form = {
                id: "",
                name: "",
                logic: "",
                isLooped: false,
                loopKey: "",
                heading: "",
                headingSize: "",
                responses: [],
            };
        },
        edit(subgroup) {
            this.selected = subgroup;
            this.form = cloneDeep(subgroup);
        },
        cancel() {
            this.form = null;
        },
        saveSubgroup(subgroup) {
            if (subgroup.id) {
                this.$store.commit(`editor/responses/${EDIT_RESPONSE_SUBGROUP_MUTATION}`, subgroup);
            }
            else {
                const { groupId } = this;
                this.$store.commit(`editor/responses/${ADD_RESPONSE_SUBGROUP_MUTATION}`, { groupId, subgroup });
            }
            this.selected = this.form;
            this.form = null;
        },
        removeSubgroup(subgroupId) {
            const { groupId } = this;
            this.$store.commit(`editor/responses/${DELETE_RESPONSE_SUBGROUP_MUTATION}`, { groupId, subgroupId });
            this.selected = null;
            this.form = null;
        },
        reorderSubgroup(from, to) {
            const { groupId } = this;
            this.$store.commit(`editor/responses/${REORDER_RESPONSE_SUBGROUP_MUTATION}`, { groupId, from, to });
        },
    },
});

import Vue from "vue";
import LanceCustomerMetrics from "@/components/misc/CustomerMetrics.vue";
import { mapGetters } from "vuex";
import { GET_CUSTOMER_METRICS_ACTION } from "@/store/types";
import Datepicker from "vue2-datepicker";
import { lang } from "@/conf";
export default Vue.extend({
    name: "LanceMetrics",
    components: {
        LanceCustomerMetrics,
        Datepicker,
    },
    data() {
        return {
            datePickerOptions: {
                clearable: false,
                format: "DD/MM/YYYY",
                language: {
                    placeholder: {
                        date: "Select date",
                        dateRange: "Select date range",
                    },
                },
                shortcuts: [],
            },
            customerFilter: "",
            startDate: "",
            endDate: "",
            tempDate: [],
            content: {
                title: [
                    {
                        type: "heading1",
                        text: "Start a New Review",
                        spans: [],
                    },
                ],
                description: [
                    {
                        type: "paragraph",
                        text: "",
                        spans: [],
                    },
                ],
                userId: "User Id",
                userName: "User Name",
                userEmail: "User Email",
                reviewsStarted: "Review Started",
                reviewsSubmitted: "Review Submitted",
                entitiesSubmitted: "Entites Submitted",
                lastLogin: "Last Login",
            },
        };
    },
    computed: Object.assign(Object.assign({}, mapGetters("metrics", ["customerMetrics"])), { filteredCompanies() {
            var tmpArray = [];
            var customer;
            if (this.customerFilter === "ALL" || this.customerFilter === "") {
                return this.customerMetrics;
            }
            for (customer in this.customerMetrics) {
                if (this.customerMetrics[customer].customerId === this.customerFilter) {
                    tmpArray.push(this.customerMetrics[customer]);
                }
            }
            return tmpArray;
        } }),
    created() {
        this.getContent();
        var sd = new Date();
        var ed = new Date();
        sd.setMonth(sd.getMonth() - 1);
        this.startDate = Math.floor(sd.getTime() / 1000);
        this.endDate = Math.floor(new Date().getTime() / 1000);
        this.tempDate = [sd, ed];
        this.$store.dispatch(`metrics/${GET_CUSTOMER_METRICS_ACTION}`, {
            startDate: this.startDate,
            endDate: this.endDate,
        });
    },
    methods: {
        getContent() {
            this.$prismic.client.getSingle("metrics", { lang }).then((response) => {
                if (!response)
                    return;
                this.content = response.data;
            });
        },
        select(event) {
            this.customerFilter = event.target.value;
        },
        addDate(date) {
            var sd = Math.floor(new Date(date[0]).getTime() / 1000);
            var ed = Math.floor(new Date(date[1]).getTime() / 1000);
            if (sd !== this.startDate || ed !== this.endDate) {
                this.startDate = sd;
                this.endDate = ed;
                this.$store.dispatch(`metrics/${GET_CUSTOMER_METRICS_ACTION}`, {
                    startDate: this.startDate,
                    endDate: this.endDate,
                });
            }
        },
    },
});

import { async } from "@/api/util";
function getUserList() {
    return async({
        method: "get",
        url: `/users/users`,
        auth: true,
    });
}
function getUser(id) {
    return async({
        method: "get",
        url: `/users/user/${id}`,
        auth: true,
    });
}
function getUserReviews(id) {
    return async({
        method: "get",
        url: `/users/${id}/reviews`,
        auth: true,
    });
}
function createUser(data) {
    return async({
        method: "post",
        url: `/users/users`,
        data: data,
        auth: true,
    });
}
function deleteUser(id) {
    return async({
        method: "delete",
        url: `/users/${id}`,
        auth: true,
    });
}
function deleteCustomer(id) {
    return async({
        method: "delete",
        url: `/users/customer/${encodeURI(id)}`,
        auth: true,
    });
}
function updateUser(user) {
    const data = {
        name: user.user_name,
        company: user.company_name,
        is_user: user.is_user,
        is_trial_user: user.is_trial_user,
        is_lead: user.is_lead,
        is_enabled: user.is_enabled,
        terms_agreed: user.terms_agreed ? "yes" : "no",
        is_account_lead: user.is_account_lead,
        is_approver: user.is_approver,
        review_cap: user.review_cap,
    };
    return async({
        method: "put",
        url: `/users/user/${user.user_id}`,
        data: data,
        auth: true,
    });
}
export default {
    getUserList,
    getUser,
    getUserReviews,
    createUser,
    deleteUser,
    updateUser,
    deleteCustomer,
};

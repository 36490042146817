import Vue from "vue";
import { SAVE_DRAFT_SURVEY_ACTION, PUBLISH_DRAFT_SURVEY_ACTION } from "@/store/types";
import { mapActions, mapGetters } from "vuex";
export default Vue.extend({
    name: "SaveSurvey",
    computed: Object.assign({}, mapGetters("editor", [
        "saving",
        "errorSaving",
        "publishing",
        "errorPublishing",
        "savingSuccess",
        "publishSuccess",
    ])),
    methods: Object.assign({}, mapActions("editor", {
        saveSurvey: SAVE_DRAFT_SURVEY_ACTION,
        publishSurvey: PUBLISH_DRAFT_SURVEY_ACTION,
    })),
});

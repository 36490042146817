import Vue from "vue";
import LanceSurveyJS from "@/components/SurveyJS.vue";
import LanceSurveyError from "@/components/SurveyError.vue";
import { NEXT_QUESTIONS_ACTION } from "@/store/types";
import { mapGetters } from "vuex";
export default Vue.extend({
    name: "LanceSurvey",
    components: {
        LanceSurveyJS,
        LanceSurveyError,
    },
    data() {
        return {
            show: false,
        };
    },
    computed: Object.assign(Object.assign({}, mapGetters({
        startStatus: "review/start",
        nextStatus: "review/next",
        questions: "review/questions",
        part: "review/part",
        _sequence: "review/sequence",
        structure: "review/structure",
        fromBack: "review/fromBack",
    })), { error() {
            return this.$store.getters["review/nextError"];
        },
        sequence() {
            return this._sequence;
        },
        showLoader() {
            var _a, _b;
            return ((_a = this.startStatus) === null || _a === void 0 ? void 0 : _a.pending) || ((_b = this.nextStatus) === null || _b === void 0 ? void 0 : _b.pending);
        },
        // TODO: unused?
        // isCopiedFrom: (val: string): string => this.structure.entities[val].copiedFrom,
        showBackButton() {
            for (const i in this.sequence) {
                if (this.sequence[i].id === this.part.id && +i > 0) {
                    return true;
                }
            }
            return false;
        } }),
    mounted() {
        this.$store
            .dispatch(`review/${NEXT_QUESTIONS_ACTION}`, {
            resume: true,
            reviewId: this.$route.params.id,
            requestedPartId: this.$route.query.part ? this.$route.query.part : null,
        })
            .then((v) => {
            if (v === null || v === void 0 ? void 0 : v.submitted) {
                this.$router.push({ name: "Dashboard" });
                return;
            }
            this.show = true;
        });
    },
    methods: {
        completePart(answers) {
            this.sequence.forEach((item, i) => {
                if (this.sequence[i].id === this.part.id) {
                    const next = i < this.sequence.length - 1 ? i + 1 : null;
                    const params = {
                        resume: false,
                        answers: answers.data,
                        reviewId: this.$route.params.id,
                        back: false,
                    };
                    if (next)
                        params.requestedPartId = this.sequence[next].id;
                    this.$store.dispatch(`review/${NEXT_QUESTIONS_ACTION}`, params).then((resp) => {
                        return resp && resp.finished ? this.$router.push({ name: "Submit Review" }) : null;
                    });
                }
            });
        },
        // TODO: unused?
        // nextUnanswered(answers): void {
        //   const params = {
        //     resume: false,
        //     answers: answers.data,
        //     reviewId: this.$route.params.id,
        //     back: false,
        //   };
        //   this.$store
        //     .dispatch(`review/${NEXT_QUESTIONS_ACTION}`, params)
        //     .then(({ finished }) => (finished ? this.$router.push({ name: "Submit Review" }) : null));
        // },
        back(answers) {
            // TODO: for...in use on array? replace
            for (const i in this.sequence) {
                if (this.sequence[i].id === this.part.id) {
                    let back = +i - 1;
                    if (back < 0) {
                        this.completePart({});
                    }
                    else {
                        this.$store.dispatch(`review/${NEXT_QUESTIONS_ACTION}`, {
                            resume: false,
                            answers: answers,
                            reviewId: this.$route.params.id,
                            requestedPartId: this.sequence[back].id,
                            back: true,
                        });
                    }
                }
            }
        },
    },
});

import Vue from "vue";
import { SUBMIT_REVIEW_ACTION, NEXT_QUESTIONS_ACTION, GET_ANSWER_SUMMARY_ACTION } from "@/store/types";
import { mapGetters } from "vuex";
import LanceSurveySubmitError from "@/components/SurveySubmitError.vue";
import Showdown from "showdown";
const converter = new Showdown.Converter();
export default Vue.extend({
    name: "LanceSurvey",
    components: {
        LanceSurveySubmitError,
    },
    data() {
        return {
            submitted: false,
            show: false,
            showAnswers: false,
        };
    },
    computed: Object.assign(Object.assign({}, mapGetters({
        canSubmit: "auth/canSubmit",
    })), { answerSummary() {
            return this.$store.getters["review/answerSummary"];
        },
        error() {
            return this.$store.getters["review/submitError"];
        } }),
    created() {
        this.$store
            .dispatch(`review/${NEXT_QUESTIONS_ACTION}`, {
            resume: true,
            reviewId: this.$route.params.id,
        })
            .then(({ submitted, finished }) => {
            if (submitted) {
                this.$router.push({ name: "Dashboard" });
                return;
            }
            else if (!finished) {
                this.$router.push({ name: "Review", id: this.$route.params.id }); // TODO: Does id work? it does not exist on Location
                return;
            }
            this.show = true;
        });
        this.$store.dispatch(`review/${GET_ANSWER_SUMMARY_ACTION}`, this.$route.params.id);
    },
    methods: {
        parse(text) {
            return converter.makeHtml(text);
        },
        yes() {
            this.submitted = true;
            this.$store
                .dispatch(`review/${SUBMIT_REVIEW_ACTION}`, this.$route.params.id)
                .then((id) => {
                this.$router.push({ name: "Review Report", params: { id } });
            })
                .catch((err) => console.log(err));
        },
        no() {
            this.$router.push({
                name: "Review",
                params: { id: this.$route.params.id },
            });
        },
    },
});

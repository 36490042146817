import { of, from } from '../rxjsStub';
import { mergeMap, map } from '../rxjsStub';
import { DefaultApiRequestFactory, DefaultApiResponseProcessor } from "../apis/DefaultApi";
export class ObservableDefaultApi {
    constructor(configuration, requestFactory, responseProcessor) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new DefaultApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new DefaultApiResponseProcessor();
    }
    /**
     * @param id review id
     */
    deleteCompletedReview(id, _options) {
        const requestContextPromise = this.requestFactory.deleteCompletedReview(id, _options);
        // build promise chain
        let middlewarePreObservable = from(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx) => middleware.pre(ctx)));
        }
        return middlewarePreObservable.pipe(mergeMap((ctx) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response) => {
            let middlewarePostObservable = of(response);
            for (let middleware of this.configuration.middleware) {
                middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp) => middleware.post(rsp)));
            }
            return middlewarePostObservable.pipe(map((rsp) => this.responseProcessor.deleteCompletedReview(rsp)));
        }));
    }
    /**
     * @param id review id
     */
    deleteUncompletedReview(id, _options) {
        const requestContextPromise = this.requestFactory.deleteUncompletedReview(id, _options);
        // build promise chain
        let middlewarePreObservable = from(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx) => middleware.pre(ctx)));
        }
        return middlewarePreObservable.pipe(mergeMap((ctx) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response) => {
            let middlewarePostObservable = of(response);
            for (let middleware of this.configuration.middleware) {
                middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp) => middleware.post(rsp)));
            }
            return middlewarePostObservable.pipe(map((rsp) => this.responseProcessor.deleteUncompletedReview(rsp)));
        }));
    }
    /**
     * @param id review id
     */
    getReviewAnswerSummary(id, _options) {
        const requestContextPromise = this.requestFactory.getReviewAnswerSummary(id, _options);
        // build promise chain
        let middlewarePreObservable = from(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx) => middleware.pre(ctx)));
        }
        return middlewarePreObservable.pipe(mergeMap((ctx) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response) => {
            let middlewarePostObservable = of(response);
            for (let middleware of this.configuration.middleware) {
                middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp) => middleware.post(rsp)));
            }
            return middlewarePostObservable.pipe(map((rsp) => this.responseProcessor.getReviewAnswerSummary(rsp)));
        }));
    }
    /**
     * @param id review id
     * @param remediated only when requesting \&quot;application/pdf\&quot;
     */
    getReviewReport(id, remediated, _options) {
        const requestContextPromise = this.requestFactory.getReviewReport(id, remediated, _options);
        // build promise chain
        let middlewarePreObservable = from(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx) => middleware.pre(ctx)));
        }
        return middlewarePreObservable.pipe(mergeMap((ctx) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response) => {
            let middlewarePostObservable = of(response);
            for (let middleware of this.configuration.middleware) {
                middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp) => middleware.post(rsp)));
            }
            return middlewarePostObservable.pipe(map((rsp) => this.responseProcessor.getReviewReport(rsp)));
        }));
    }
    /**
     * @param surveyId
     */
    getSurveyDefinitions(surveyId, _options) {
        const requestContextPromise = this.requestFactory.getSurveyDefinitions(surveyId, _options);
        // build promise chain
        let middlewarePreObservable = from(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx) => middleware.pre(ctx)));
        }
        return middlewarePreObservable.pipe(mergeMap((ctx) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response) => {
            let middlewarePostObservable = of(response);
            for (let middleware of this.configuration.middleware) {
                middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp) => middleware.post(rsp)));
            }
            return middlewarePostObservable.pipe(map((rsp) => this.responseProcessor.getSurveyDefinitions(rsp)));
        }));
    }
    /**
     * @param surveyId
     */
    getSurveyResponses(surveyId, _options) {
        const requestContextPromise = this.requestFactory.getSurveyResponses(surveyId, _options);
        // build promise chain
        let middlewarePreObservable = from(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx) => middleware.pre(ctx)));
        }
        return middlewarePreObservable.pipe(mergeMap((ctx) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response) => {
            let middlewarePostObservable = of(response);
            for (let middleware of this.configuration.middleware) {
                middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp) => middleware.post(rsp)));
            }
            return middlewarePostObservable.pipe(map((rsp) => this.responseProcessor.getSurveyResponses(rsp)));
        }));
    }
    /**
     * @param surveyId
     */
    getSurveyRules(surveyId, _options) {
        const requestContextPromise = this.requestFactory.getSurveyRules(surveyId, _options);
        // build promise chain
        let middlewarePreObservable = from(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx) => middleware.pre(ctx)));
        }
        return middlewarePreObservable.pipe(mergeMap((ctx) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response) => {
            let middlewarePostObservable = of(response);
            for (let middleware of this.configuration.middleware) {
                middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp) => middleware.post(rsp)));
            }
            return middlewarePostObservable.pipe(map((rsp) => this.responseProcessor.getSurveyRules(rsp)));
        }));
    }
    /**
     * @param id user id
     */
    getUser(id, _options) {
        const requestContextPromise = this.requestFactory.getUser(id, _options);
        // build promise chain
        let middlewarePreObservable = from(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx) => middleware.pre(ctx)));
        }
        return middlewarePreObservable.pipe(mergeMap((ctx) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response) => {
            let middlewarePostObservable = of(response);
            for (let middleware of this.configuration.middleware) {
                middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp) => middleware.post(rsp)));
            }
            return middlewarePostObservable.pipe(map((rsp) => this.responseProcessor.getUser(rsp)));
        }));
    }
    /**
     * @param id review id
     */
    getUserReviews(id, _options) {
        const requestContextPromise = this.requestFactory.getUserReviews(id, _options);
        // build promise chain
        let middlewarePreObservable = from(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx) => middleware.pre(ctx)));
        }
        return middlewarePreObservable.pipe(mergeMap((ctx) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response) => {
            let middlewarePostObservable = of(response);
            for (let middleware of this.configuration.middleware) {
                middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp) => middleware.post(rsp)));
            }
            return middlewarePostObservable.pipe(map((rsp) => this.responseProcessor.getUserReviews(rsp)));
        }));
    }
    /**
     */
    getUsers(_options) {
        const requestContextPromise = this.requestFactory.getUsers(_options);
        // build promise chain
        let middlewarePreObservable = from(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx) => middleware.pre(ctx)));
        }
        return middlewarePreObservable.pipe(mergeMap((ctx) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response) => {
            let middlewarePostObservable = of(response);
            for (let middleware of this.configuration.middleware) {
                middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp) => middleware.post(rsp)));
            }
            return middlewarePostObservable.pipe(map((rsp) => this.responseProcessor.getUsers(rsp)));
        }));
    }
    /**
     * @param id review id
     * @param ReviewNextRequest
     */
    nextReview(id, ReviewNextRequest, _options) {
        const requestContextPromise = this.requestFactory.nextReview(id, ReviewNextRequest, _options);
        // build promise chain
        let middlewarePreObservable = from(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx) => middleware.pre(ctx)));
        }
        return middlewarePreObservable.pipe(mergeMap((ctx) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response) => {
            let middlewarePostObservable = of(response);
            for (let middleware of this.configuration.middleware) {
                middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp) => middleware.post(rsp)));
            }
            return middlewarePostObservable.pipe(map((rsp) => this.responseProcessor.nextReview(rsp)));
        }));
    }
    /**
     * @param id review id
     */
    resetReviewScore(id, _options) {
        const requestContextPromise = this.requestFactory.resetReviewScore(id, _options);
        // build promise chain
        let middlewarePreObservable = from(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx) => middleware.pre(ctx)));
        }
        return middlewarePreObservable.pipe(mergeMap((ctx) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response) => {
            let middlewarePostObservable = of(response);
            for (let middleware of this.configuration.middleware) {
                middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp) => middleware.post(rsp)));
            }
            return middlewarePostObservable.pipe(map((rsp) => this.responseProcessor.resetReviewScore(rsp)));
        }));
    }
    /**
     * @param id review id
     */
    resubmitReview(id, _options) {
        const requestContextPromise = this.requestFactory.resubmitReview(id, _options);
        // build promise chain
        let middlewarePreObservable = from(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx) => middleware.pre(ctx)));
        }
        return middlewarePreObservable.pipe(mergeMap((ctx) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response) => {
            let middlewarePostObservable = of(response);
            for (let middleware of this.configuration.middleware) {
                middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp) => middleware.post(rsp)));
            }
            return middlewarePostObservable.pipe(map((rsp) => this.responseProcessor.resubmitReview(rsp)));
        }));
    }
    /**
     * @param id review id
     */
    revertReview(id, _options) {
        const requestContextPromise = this.requestFactory.revertReview(id, _options);
        // build promise chain
        let middlewarePreObservable = from(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx) => middleware.pre(ctx)));
        }
        return middlewarePreObservable.pipe(mergeMap((ctx) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response) => {
            let middlewarePostObservable = of(response);
            for (let middleware of this.configuration.middleware) {
                middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp) => middleware.post(rsp)));
            }
            return middlewarePostObservable.pipe(map((rsp) => this.responseProcessor.revertReview(rsp)));
        }));
    }
    /**
     * @param ReviewStartRequest
     */
    startReview(ReviewStartRequest, _options) {
        const requestContextPromise = this.requestFactory.startReview(ReviewStartRequest, _options);
        // build promise chain
        let middlewarePreObservable = from(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx) => middleware.pre(ctx)));
        }
        return middlewarePreObservable.pipe(mergeMap((ctx) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response) => {
            let middlewarePostObservable = of(response);
            for (let middleware of this.configuration.middleware) {
                middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp) => middleware.post(rsp)));
            }
            return middlewarePostObservable.pipe(map((rsp) => this.responseProcessor.startReview(rsp)));
        }));
    }
    /**
     * @param id review id
     */
    submitReview(id, _options) {
        const requestContextPromise = this.requestFactory.submitReview(id, _options);
        // build promise chain
        let middlewarePreObservable = from(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx) => middleware.pre(ctx)));
        }
        return middlewarePreObservable.pipe(mergeMap((ctx) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response) => {
            let middlewarePostObservable = of(response);
            for (let middleware of this.configuration.middleware) {
                middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp) => middleware.post(rsp)));
            }
            return middlewarePostObservable.pipe(map((rsp) => this.responseProcessor.submitReview(rsp)));
        }));
    }
    /**
     * @param id review id
     * @param ReviewUpdateRemediationRequest
     */
    updateReviewRemediation(id, ReviewUpdateRemediationRequest, _options) {
        const requestContextPromise = this.requestFactory.updateReviewRemediation(id, ReviewUpdateRemediationRequest, _options);
        // build promise chain
        let middlewarePreObservable = from(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx) => middleware.pre(ctx)));
        }
        return middlewarePreObservable.pipe(mergeMap((ctx) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response) => {
            let middlewarePostObservable = of(response);
            for (let middleware of this.configuration.middleware) {
                middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp) => middleware.post(rsp)));
            }
            return middlewarePostObservable.pipe(map((rsp) => this.responseProcessor.updateReviewRemediation(rsp)));
        }));
    }
    /**
     * @param id review id
     * @param ReviewUpdateRequest
     */
    updateReviewReport(id, ReviewUpdateRequest, _options) {
        const requestContextPromise = this.requestFactory.updateReviewReport(id, ReviewUpdateRequest, _options);
        // build promise chain
        let middlewarePreObservable = from(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx) => middleware.pre(ctx)));
        }
        return middlewarePreObservable.pipe(mergeMap((ctx) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response) => {
            let middlewarePostObservable = of(response);
            for (let middleware of this.configuration.middleware) {
                middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp) => middleware.post(rsp)));
            }
            return middlewarePostObservable.pipe(map((rsp) => this.responseProcessor.updateReviewReport(rsp)));
        }));
    }
    /**
     * @param id review id
     */
    useLatestSurvey(id, _options) {
        const requestContextPromise = this.requestFactory.useLatestSurvey(id, _options);
        // build promise chain
        let middlewarePreObservable = from(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx) => middleware.pre(ctx)));
        }
        return middlewarePreObservable.pipe(mergeMap((ctx) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response) => {
            let middlewarePostObservable = of(response);
            for (let middleware of this.configuration.middleware) {
                middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp) => middleware.post(rsp)));
            }
            return middlewarePostObservable.pipe(map((rsp) => this.responseProcessor.useLatestSurvey(rsp)));
        }));
    }
}

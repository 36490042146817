import metrics from "@/api/metrics";
import { GET_CUSTOMER_METRICS_ASYNC, GET_CUSTOMER_METRICS_ACTION } from "@/store/types";
// initial state
export const state = {
    customerMetrics: null,
    getCustomerMetrics: {
        pending: false,
        error: null,
    },
};
// getters
export const getters = {
    customerMetrics: (state) => state.customerMetrics,
};
// actions
export const actions = {
    [GET_CUSTOMER_METRICS_ACTION]({ commit }, data) {
        commit(GET_CUSTOMER_METRICS_ASYNC.PENDING);
        metrics
            .getCustomerMetrics(data)
            .then((res) => commit(GET_CUSTOMER_METRICS_ASYNC.SUCCESS, res))
            .catch((err) => commit(GET_CUSTOMER_METRICS_ASYNC.FAILURE, err));
    },
};
// mutations
export const mutations = {
    [GET_CUSTOMER_METRICS_ASYNC.PENDING](state) {
        state.getCustomerMetrics.pending = true;
    },
    [GET_CUSTOMER_METRICS_ASYNC.SUCCESS](state, res) {
        state.customerMetrics = res.customerMetrics;
        state.getCustomerMetrics.pending = false;
    },
    [GET_CUSTOMER_METRICS_ASYNC.FAILURE](state, error) {
        state.getCustomerMetrics.pending = false;
        state.getCustomerMetrics.error = error;
    },
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

export default function nomargin() {
    return [
        {
            type: "output",
            regex: /%no-margin%([^]+?)%no-margin%/gi,
            replace(s, match) {
                return '<div style="margin-top: -1em;">' + match + "</div>";
            },
        },
    ];
}

import Vue from "vue";
import Vuetify from "vuetify";
Vue.use(Vuetify);
export default new Vuetify({
    icons: {
        iconfont: "fa",
    },
    theme: {
        themes: {
            light: {
                primary: "33cccc",
                secondary: "#282f47",
            },
        },
    },
});

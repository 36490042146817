import Vue from "vue";
import LanceAttachments from "@/components/misc/Attachments.vue";
import { lang } from "@/conf";
export default Vue.extend({
    name: "LanceProduct",
    components: {
        LanceAttachments,
    },
    data() {
        return {
            content: {
                title: [],
                introduction: [],
                hero: [],
                workflow_items: [],
                summary: [],
                attachments: [],
                benefits: [],
            },
        };
    },
    created() {
        this.getContent();
    },
    methods: {
        getContent() {
            this.$prismic.client.getByUID("product", "about-lance", { lang }).then((response) => {
                if (!response)
                    return;
                this.content = response.data;
            });
        },
    },
});

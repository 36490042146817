export const ADD_STRUCTURE_ANSWERS = "ADD_STRUCTURE_ANSWERS";
export const RESET_ENTITIES = "RESET_ENTITIES";
// structure
export const STRUCTURE_ADD_ENTITY = "STRUCTURE_ADD_ENTITY";
export const STRUCTURE_DELETE_ENTITY = "STRUCTURE_DELETE_ENTITY";
export const STRUCTURE_UPDATE_ENTITY = "STRUCTURE_UPDATE_ENTITY";
// TODO: use openapi enum instead.
// STRUCTURE
export const STRUCTURE_ENTITY = {
    TRUST: "trust",
    FOUNDATION: "foundation",
    COMPANY: "company",
    SETTLOR: "settlor",
    FOUNDER: "founder",
    SETTLEMENT: "settlement",
    CONTRIBUTION: "contribution",
    SHAREHOLDER: "shareholder",
    SHAREHOLDER_DATES: "shareholderdates",
    NOMINEE: "nominee",
};

import { SET_QUESTIONS, UPDATE_QUESTIONS_MUTATION, GET_DRAFT_SURVEY_ACTION_QUESTIONS, GET_DRAFT_SURVEY_ASYNC_QUESTIONS, } from "@/store/types";
import { pickBy, merge, cloneDeep } from "lodash";
import editor from "@/api/editor";
const firstLoad = {
    chunkList: {
        trust: {
            chunks: {
                pre: {},
                post: {},
            },
        },
        settlor: {
            chunks: {
                pre: {},
                post: {},
                settlor: {},
                founder: {},
            },
        },
        settlement: {
            chunks: {
                pre: {},
                post: {},
            },
        },
        foundation: {
            chunks: {
                pre: {},
                post: {},
            },
        },
        founder: {
            chunks: {
                pre: {},
                post: {},
                settlor: {},
                founder: {},
            },
        },
        contribution: {
            chunks: {
                pre: {},
                post: {},
            },
        },
        company: {
            chunks: {
                pre: {},
                post: {},
            },
        },
        shareholder: {
            chunks: {
                pre: {},
                post: {},
                settlor: {},
                founder: {},
            },
        },
    },
};
// initial state
export const state = {
    get: {
        pending: false,
        error: null,
        fetched: false,
    },
    original: firstLoad,
    questions: firstLoad,
};
// getters
export const getters = {
    questions: (state) => state.questions,
    original: (state) => state.original,
};
// actions
export const actions = {
    [GET_DRAFT_SURVEY_ACTION_QUESTIONS]({ commit }) {
        if (!state.get.fetched) {
            commit(GET_DRAFT_SURVEY_ASYNC_QUESTIONS.PENDING);
            editor
                .getSurvey("questions")
                .then((res) => {
                commit(GET_DRAFT_SURVEY_ASYNC_QUESTIONS.SUCCESS);
                commit(SET_QUESTIONS, res);
            })
                .catch((err) => commit(GET_DRAFT_SURVEY_ASYNC_QUESTIONS.FAILURE, err));
        }
    },
};
// mutations
export const mutations = {
    // ASYNC
    [GET_DRAFT_SURVEY_ASYNC_QUESTIONS.PENDING](state) {
        state.get.pending = true;
    },
    [GET_DRAFT_SURVEY_ASYNC_QUESTIONS.SUCCESS](state) {
        state.get.fetched = true;
        state.get.pending = false;
    },
    [GET_DRAFT_SURVEY_ASYNC_QUESTIONS.FAILURE](state, error) {
        state.get.pending = false;
        state.get.error = error;
    },
    [SET_QUESTIONS](state, questions) {
        questions = pickBy(questions);
        state.original = cloneDeep(questions);
        state.questions = merge(state.questions, questions);
    },
    [UPDATE_QUESTIONS_MUTATION](state, { type, subtype, questions }) {
        state.questions.chunkList[type].chunks[subtype] = questions;
    },
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

import Vue from "vue";
import { GET_DRAFT_SURVEY_ACTION_DEFINITIONS } from "@/store/types";
import LanceGroups from "@/components/editor/definition/group/Groups.vue";
import { mapGetters } from "vuex";
export default Vue.extend({
    name: "LanceDefinition",
    components: {
        LanceGroups,
    },
    computed: Object.assign({}, mapGetters("editor/definitions", ["error"])),
    created() {
        this.$store.dispatch(`editor/definitions/${GET_DRAFT_SURVEY_ACTION_DEFINITIONS}`, true);
    },
});

import { __awaiter } from "tslib";
import Vue from "vue";
import { mapState, mapActions, mapGetters } from "vuex";
export default Vue.extend({
    name: "LanceLogin",
    data() {
        return {
            username: "",
            password: "",
        };
    },
    computed: Object.assign(Object.assign(Object.assign({}, mapGetters("auth", ["isAuthenticated", "pending", "authenticationStatus", "passwordChangeRequired"])), mapState({
        auth: (state) => state.auth,
    })), { prod: () => process.env.NODE_ENV === "production" }),
    methods: Object.assign(Object.assign(Object.assign({}, mapActions("auth", ["signIn", "clearAuthenticationStatus"])), mapGetters("auth", ["authenticatedUser"])), { login(username, password) {
            return __awaiter(this, void 0, void 0, function* () {
                this.signIn({ username, password }).then((user) => {
                    if (this.passwordChangeRequired) {
                        this.clearAuthenticationStatus();
                        this.$router.push({ name: "ChangePassword" });
                    }
                    else {
                        this.$router.push({ name: "Dashboard" });
                    }
                });
            });
        } }),
});

import { __awaiter } from "tslib";
// TODO: better import syntax?
import { BaseAPIRequestFactory, RequiredError } from './baseapi';
import { HttpMethod } from '../http/http';
import { ObjectSerializer } from '../models/ObjectSerializer';
import { ApiException } from './exception';
import { isCodeInRange } from '../util';
/**
 * no description
 */
export class DefaultApiRequestFactory extends BaseAPIRequestFactory {
    /**
     * @param id review id
     */
    deleteCompletedReview(id, _options) {
        return __awaiter(this, void 0, void 0, function* () {
            let _config = _options || this.configuration;
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('Required parameter id was null or undefined when calling deleteCompletedReview.');
            }
            // Path Params
            const localVarPath = '/review/{id}'
                .replace('{' + 'id' + '}', encodeURIComponent(String(id)));
            // Make Request Context
            const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.DELETE);
            requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8");
            // Query Params
            // Header Params
            // Form Params
            // Body Params
            let authMethod = null;
            // Apply auth methods
            authMethod = _config.authMethods["bearerAuth"];
            if (authMethod) {
                yield authMethod.applySecurityAuthentication(requestContext);
            }
            return requestContext;
        });
    }
    /**
     * @param id review id
     */
    deleteUncompletedReview(id, _options) {
        return __awaiter(this, void 0, void 0, function* () {
            let _config = _options || this.configuration;
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('Required parameter id was null or undefined when calling deleteUncompletedReview.');
            }
            // Path Params
            const localVarPath = '/review/{id}/deletereview'
                .replace('{' + 'id' + '}', encodeURIComponent(String(id)));
            // Make Request Context
            const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.DELETE);
            requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8");
            // Query Params
            // Header Params
            // Form Params
            // Body Params
            let authMethod = null;
            // Apply auth methods
            authMethod = _config.authMethods["bearerAuth"];
            if (authMethod) {
                yield authMethod.applySecurityAuthentication(requestContext);
            }
            return requestContext;
        });
    }
    /**
     * @param id review id
     */
    getReviewAnswerSummary(id, _options) {
        return __awaiter(this, void 0, void 0, function* () {
            let _config = _options || this.configuration;
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('Required parameter id was null or undefined when calling getReviewAnswerSummary.');
            }
            // Path Params
            const localVarPath = '/review/{id}/answersummary'
                .replace('{' + 'id' + '}', encodeURIComponent(String(id)));
            // Make Request Context
            const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
            requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8");
            // Query Params
            // Header Params
            // Form Params
            // Body Params
            let authMethod = null;
            // Apply auth methods
            authMethod = _config.authMethods["bearerAuth"];
            if (authMethod) {
                yield authMethod.applySecurityAuthentication(requestContext);
            }
            return requestContext;
        });
    }
    /**
     * @param id review id
     * @param remediated only when requesting \&quot;application/pdf\&quot;
     */
    getReviewReport(id, remediated, _options) {
        return __awaiter(this, void 0, void 0, function* () {
            let _config = _options || this.configuration;
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('Required parameter id was null or undefined when calling getReviewReport.');
            }
            // Path Params
            const localVarPath = '/review/{id}/report'
                .replace('{' + 'id' + '}', encodeURIComponent(String(id)));
            // Make Request Context
            const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
            requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8");
            // Query Params
            if (remediated !== undefined) {
                requestContext.setQueryParam("remediated", ObjectSerializer.serialize(remediated, "boolean", ""));
            }
            // Header Params
            // Form Params
            // Body Params
            let authMethod = null;
            // Apply auth methods
            authMethod = _config.authMethods["bearerAuth"];
            if (authMethod) {
                yield authMethod.applySecurityAuthentication(requestContext);
            }
            return requestContext;
        });
    }
    /**
     * @param surveyId
     */
    getSurveyDefinitions(surveyId, _options) {
        return __awaiter(this, void 0, void 0, function* () {
            let _config = _options || this.configuration;
            // verify required parameter 'surveyId' is not null or undefined
            if (surveyId === null || surveyId === undefined) {
                throw new RequiredError('Required parameter surveyId was null or undefined when calling getSurveyDefinitions.');
            }
            // Path Params
            const localVarPath = '/editor/{surveyId}/definitions'
                .replace('{' + 'surveyId' + '}', encodeURIComponent(String(surveyId)));
            // Make Request Context
            const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
            requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8");
            // Query Params
            // Header Params
            // Form Params
            // Body Params
            let authMethod = null;
            // Apply auth methods
            authMethod = _config.authMethods["bearerAuth"];
            if (authMethod) {
                yield authMethod.applySecurityAuthentication(requestContext);
            }
            return requestContext;
        });
    }
    /**
     * @param surveyId
     */
    getSurveyResponses(surveyId, _options) {
        return __awaiter(this, void 0, void 0, function* () {
            let _config = _options || this.configuration;
            // verify required parameter 'surveyId' is not null or undefined
            if (surveyId === null || surveyId === undefined) {
                throw new RequiredError('Required parameter surveyId was null or undefined when calling getSurveyResponses.');
            }
            // Path Params
            const localVarPath = '/editor/{surveyId}/responses'
                .replace('{' + 'surveyId' + '}', encodeURIComponent(String(surveyId)));
            // Make Request Context
            const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
            requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8");
            // Query Params
            // Header Params
            // Form Params
            // Body Params
            let authMethod = null;
            // Apply auth methods
            authMethod = _config.authMethods["bearerAuth"];
            if (authMethod) {
                yield authMethod.applySecurityAuthentication(requestContext);
            }
            return requestContext;
        });
    }
    /**
     * @param surveyId
     */
    getSurveyRules(surveyId, _options) {
        return __awaiter(this, void 0, void 0, function* () {
            let _config = _options || this.configuration;
            // verify required parameter 'surveyId' is not null or undefined
            if (surveyId === null || surveyId === undefined) {
                throw new RequiredError('Required parameter surveyId was null or undefined when calling getSurveyRules.');
            }
            // Path Params
            const localVarPath = '/editor/{surveyId}/rules'
                .replace('{' + 'surveyId' + '}', encodeURIComponent(String(surveyId)));
            // Make Request Context
            const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
            requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8");
            // Query Params
            // Header Params
            // Form Params
            // Body Params
            let authMethod = null;
            // Apply auth methods
            authMethod = _config.authMethods["bearerAuth"];
            if (authMethod) {
                yield authMethod.applySecurityAuthentication(requestContext);
            }
            return requestContext;
        });
    }
    /**
     * @param id user id
     */
    getUser(id, _options) {
        return __awaiter(this, void 0, void 0, function* () {
            let _config = _options || this.configuration;
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('Required parameter id was null or undefined when calling getUser.');
            }
            // Path Params
            const localVarPath = '/users/user/{id}'
                .replace('{' + 'id' + '}', encodeURIComponent(String(id)));
            // Make Request Context
            const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
            requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8");
            // Query Params
            // Header Params
            // Form Params
            // Body Params
            let authMethod = null;
            // Apply auth methods
            authMethod = _config.authMethods["bearerAuth"];
            if (authMethod) {
                yield authMethod.applySecurityAuthentication(requestContext);
            }
            return requestContext;
        });
    }
    /**
     * @param id review id
     */
    getUserReviews(id, _options) {
        return __awaiter(this, void 0, void 0, function* () {
            let _config = _options || this.configuration;
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('Required parameter id was null or undefined when calling getUserReviews.');
            }
            // Path Params
            const localVarPath = '/users/{id}/reviews'
                .replace('{' + 'id' + '}', encodeURIComponent(String(id)));
            // Make Request Context
            const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
            requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8");
            // Query Params
            // Header Params
            // Form Params
            // Body Params
            let authMethod = null;
            // Apply auth methods
            authMethod = _config.authMethods["bearerAuth"];
            if (authMethod) {
                yield authMethod.applySecurityAuthentication(requestContext);
            }
            return requestContext;
        });
    }
    /**
     */
    getUsers(_options) {
        return __awaiter(this, void 0, void 0, function* () {
            let _config = _options || this.configuration;
            // Path Params
            const localVarPath = '/users/users';
            // Make Request Context
            const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
            requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8");
            // Query Params
            // Header Params
            // Form Params
            // Body Params
            let authMethod = null;
            // Apply auth methods
            authMethod = _config.authMethods["bearerAuth"];
            if (authMethod) {
                yield authMethod.applySecurityAuthentication(requestContext);
            }
            return requestContext;
        });
    }
    /**
     * @param id review id
     * @param ReviewNextRequest
     */
    nextReview(id, ReviewNextRequest, _options) {
        return __awaiter(this, void 0, void 0, function* () {
            let _config = _options || this.configuration;
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('Required parameter id was null or undefined when calling nextReview.');
            }
            // verify required parameter 'ReviewNextRequest' is not null or undefined
            if (ReviewNextRequest === null || ReviewNextRequest === undefined) {
                throw new RequiredError('Required parameter ReviewNextRequest was null or undefined when calling nextReview.');
            }
            // Path Params
            const localVarPath = '/review/{id}/next'
                .replace('{' + 'id' + '}', encodeURIComponent(String(id)));
            // Make Request Context
            const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
            requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8");
            // Query Params
            // Header Params
            // Form Params
            // Body Params
            const contentType = ObjectSerializer.getPreferredMediaType([
                "application/json"
            ]);
            requestContext.setHeaderParam("Content-Type", contentType);
            const serializedBody = ObjectSerializer.stringify(ObjectSerializer.serialize(ReviewNextRequest, "ReviewNextRequest", ""), contentType);
            requestContext.setBody(serializedBody);
            let authMethod = null;
            // Apply auth methods
            authMethod = _config.authMethods["bearerAuth"];
            if (authMethod) {
                yield authMethod.applySecurityAuthentication(requestContext);
            }
            return requestContext;
        });
    }
    /**
     * @param id review id
     */
    resetReviewScore(id, _options) {
        return __awaiter(this, void 0, void 0, function* () {
            let _config = _options || this.configuration;
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('Required parameter id was null or undefined when calling resetReviewScore.');
            }
            // Path Params
            const localVarPath = '/review/{id}/resetscore'
                .replace('{' + 'id' + '}', encodeURIComponent(String(id)));
            // Make Request Context
            const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
            requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8");
            // Query Params
            // Header Params
            // Form Params
            // Body Params
            let authMethod = null;
            // Apply auth methods
            authMethod = _config.authMethods["bearerAuth"];
            if (authMethod) {
                yield authMethod.applySecurityAuthentication(requestContext);
            }
            return requestContext;
        });
    }
    /**
     * @param id review id
     */
    resubmitReview(id, _options) {
        return __awaiter(this, void 0, void 0, function* () {
            let _config = _options || this.configuration;
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('Required parameter id was null or undefined when calling resubmitReview.');
            }
            // Path Params
            const localVarPath = '/review/{id}/resubmit'
                .replace('{' + 'id' + '}', encodeURIComponent(String(id)));
            // Make Request Context
            const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
            requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8");
            // Query Params
            // Header Params
            // Form Params
            // Body Params
            let authMethod = null;
            // Apply auth methods
            authMethod = _config.authMethods["bearerAuth"];
            if (authMethod) {
                yield authMethod.applySecurityAuthentication(requestContext);
            }
            return requestContext;
        });
    }
    /**
     * @param id review id
     */
    revertReview(id, _options) {
        return __awaiter(this, void 0, void 0, function* () {
            let _config = _options || this.configuration;
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('Required parameter id was null or undefined when calling revertReview.');
            }
            // Path Params
            const localVarPath = '/review/{id}/return'
                .replace('{' + 'id' + '}', encodeURIComponent(String(id)));
            // Make Request Context
            const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
            requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8");
            // Query Params
            // Header Params
            // Form Params
            // Body Params
            let authMethod = null;
            // Apply auth methods
            authMethod = _config.authMethods["bearerAuth"];
            if (authMethod) {
                yield authMethod.applySecurityAuthentication(requestContext);
            }
            return requestContext;
        });
    }
    /**
     * @param ReviewStartRequest
     */
    startReview(ReviewStartRequest, _options) {
        return __awaiter(this, void 0, void 0, function* () {
            let _config = _options || this.configuration;
            // verify required parameter 'ReviewStartRequest' is not null or undefined
            if (ReviewStartRequest === null || ReviewStartRequest === undefined) {
                throw new RequiredError('Required parameter ReviewStartRequest was null or undefined when calling startReview.');
            }
            // Path Params
            const localVarPath = '/review/start';
            // Make Request Context
            const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
            requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8");
            // Query Params
            // Header Params
            // Form Params
            // Body Params
            const contentType = ObjectSerializer.getPreferredMediaType([
                "application/json"
            ]);
            requestContext.setHeaderParam("Content-Type", contentType);
            const serializedBody = ObjectSerializer.stringify(ObjectSerializer.serialize(ReviewStartRequest, "ReviewStartRequest", ""), contentType);
            requestContext.setBody(serializedBody);
            let authMethod = null;
            // Apply auth methods
            authMethod = _config.authMethods["bearerAuth"];
            if (authMethod) {
                yield authMethod.applySecurityAuthentication(requestContext);
            }
            return requestContext;
        });
    }
    /**
     * @param id review id
     */
    submitReview(id, _options) {
        return __awaiter(this, void 0, void 0, function* () {
            let _config = _options || this.configuration;
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('Required parameter id was null or undefined when calling submitReview.');
            }
            // Path Params
            const localVarPath = '/review/{id}/submit'
                .replace('{' + 'id' + '}', encodeURIComponent(String(id)));
            // Make Request Context
            const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
            requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8");
            // Query Params
            // Header Params
            // Form Params
            // Body Params
            let authMethod = null;
            // Apply auth methods
            authMethod = _config.authMethods["bearerAuth"];
            if (authMethod) {
                yield authMethod.applySecurityAuthentication(requestContext);
            }
            return requestContext;
        });
    }
    /**
     * @param id review id
     * @param ReviewUpdateRemediationRequest
     */
    updateReviewRemediation(id, ReviewUpdateRemediationRequest, _options) {
        return __awaiter(this, void 0, void 0, function* () {
            let _config = _options || this.configuration;
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('Required parameter id was null or undefined when calling updateReviewRemediation.');
            }
            // Path Params
            const localVarPath = '/review/{id}/updateremediation'
                .replace('{' + 'id' + '}', encodeURIComponent(String(id)));
            // Make Request Context
            const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
            requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8");
            // Query Params
            // Header Params
            // Form Params
            // Body Params
            const contentType = ObjectSerializer.getPreferredMediaType([
                "application/json"
            ]);
            requestContext.setHeaderParam("Content-Type", contentType);
            const serializedBody = ObjectSerializer.stringify(ObjectSerializer.serialize(ReviewUpdateRemediationRequest, "ReviewUpdateRemediationRequest", ""), contentType);
            requestContext.setBody(serializedBody);
            let authMethod = null;
            // Apply auth methods
            authMethod = _config.authMethods["bearerAuth"];
            if (authMethod) {
                yield authMethod.applySecurityAuthentication(requestContext);
            }
            return requestContext;
        });
    }
    /**
     * @param id review id
     * @param ReviewUpdateRequest
     */
    updateReviewReport(id, ReviewUpdateRequest, _options) {
        return __awaiter(this, void 0, void 0, function* () {
            let _config = _options || this.configuration;
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('Required parameter id was null or undefined when calling updateReviewReport.');
            }
            // Path Params
            const localVarPath = '/review/{id}/report'
                .replace('{' + 'id' + '}', encodeURIComponent(String(id)));
            // Make Request Context
            const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
            requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8");
            // Query Params
            // Header Params
            // Form Params
            // Body Params
            const contentType = ObjectSerializer.getPreferredMediaType([
                "application/json"
            ]);
            requestContext.setHeaderParam("Content-Type", contentType);
            const serializedBody = ObjectSerializer.stringify(ObjectSerializer.serialize(ReviewUpdateRequest, "ReviewUpdateRequest", ""), contentType);
            requestContext.setBody(serializedBody);
            let authMethod = null;
            // Apply auth methods
            authMethod = _config.authMethods["bearerAuth"];
            if (authMethod) {
                yield authMethod.applySecurityAuthentication(requestContext);
            }
            return requestContext;
        });
    }
    /**
     * @param id review id
     */
    useLatestSurvey(id, _options) {
        return __awaiter(this, void 0, void 0, function* () {
            let _config = _options || this.configuration;
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('Required parameter id was null or undefined when calling useLatestSurvey.');
            }
            // Path Params
            const localVarPath = '/review/{id}/latestsurvey'
                .replace('{' + 'id' + '}', encodeURIComponent(String(id)));
            // Make Request Context
            const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.PUT);
            requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8");
            // Query Params
            // Header Params
            // Form Params
            // Body Params
            let authMethod = null;
            // Apply auth methods
            authMethod = _config.authMethods["bearerAuth"];
            if (authMethod) {
                yield authMethod.applySecurityAuthentication(requestContext);
            }
            return requestContext;
        });
    }
}
export class DefaultApiResponseProcessor {
    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to deleteCompletedReview
     * @throws ApiException if the response code was not in [200, 299]
     */
    deleteCompletedReview(response) {
        return __awaiter(this, void 0, void 0, function* () {
            const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
            if (isCodeInRange("200", response.httpStatusCode)) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "ReviewResponse", "");
                return body;
            }
            if (isCodeInRange("0", response.httpStatusCode)) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "ErrorResponse", "");
                throw new ApiException(0, body);
            }
            // Work around for missing responses in specification, e.g. for petstore.yaml
            if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "ReviewResponse", "");
                return body;
            }
            let body = response.body || "";
            throw new ApiException(response.httpStatusCode, "Unknown API Status Code!\nBody: \"" + body + "\"");
        });
    }
    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to deleteUncompletedReview
     * @throws ApiException if the response code was not in [200, 299]
     */
    deleteUncompletedReview(response) {
        return __awaiter(this, void 0, void 0, function* () {
            const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
            if (isCodeInRange("200", response.httpStatusCode)) {
                return;
            }
            if (isCodeInRange("0", response.httpStatusCode)) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "ErrorResponse", "");
                throw new ApiException(0, body);
            }
            // Work around for missing responses in specification, e.g. for petstore.yaml
            if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "void", "");
                return body;
            }
            let body = response.body || "";
            throw new ApiException(response.httpStatusCode, "Unknown API Status Code!\nBody: \"" + body + "\"");
        });
    }
    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to getReviewAnswerSummary
     * @throws ApiException if the response code was not in [200, 299]
     */
    getReviewAnswerSummary(response) {
        return __awaiter(this, void 0, void 0, function* () {
            const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
            if (isCodeInRange("200", response.httpStatusCode)) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "ReviewAnswerSummary", "");
                return body;
            }
            if (isCodeInRange("0", response.httpStatusCode)) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "ErrorResponse", "");
                throw new ApiException(0, body);
            }
            // Work around for missing responses in specification, e.g. for petstore.yaml
            if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "ReviewAnswerSummary", "");
                return body;
            }
            let body = response.body || "";
            throw new ApiException(response.httpStatusCode, "Unknown API Status Code!\nBody: \"" + body + "\"");
        });
    }
    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to getReviewReport
     * @throws ApiException if the response code was not in [200, 299]
     */
    getReviewReport(response) {
        return __awaiter(this, void 0, void 0, function* () {
            const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
            if (isCodeInRange("200", response.httpStatusCode)) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "ReportResponse", "");
                return body;
            }
            if (isCodeInRange("0", response.httpStatusCode)) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "ErrorResponse", "");
                throw new ApiException(0, body);
            }
            // Work around for missing responses in specification, e.g. for petstore.yaml
            if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "ReportResponse", "");
                return body;
            }
            let body = response.body || "";
            throw new ApiException(response.httpStatusCode, "Unknown API Status Code!\nBody: \"" + body + "\"");
        });
    }
    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to getSurveyDefinitions
     * @throws ApiException if the response code was not in [200, 299]
     */
    getSurveyDefinitions(response) {
        return __awaiter(this, void 0, void 0, function* () {
            const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
            if (isCodeInRange("200", response.httpStatusCode)) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "Definitions", "");
                return body;
            }
            if (isCodeInRange("0", response.httpStatusCode)) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "ErrorResponse", "");
                throw new ApiException(0, body);
            }
            // Work around for missing responses in specification, e.g. for petstore.yaml
            if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "Definitions", "");
                return body;
            }
            let body = response.body || "";
            throw new ApiException(response.httpStatusCode, "Unknown API Status Code!\nBody: \"" + body + "\"");
        });
    }
    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to getSurveyResponses
     * @throws ApiException if the response code was not in [200, 299]
     */
    getSurveyResponses(response) {
        return __awaiter(this, void 0, void 0, function* () {
            const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
            if (isCodeInRange("200", response.httpStatusCode)) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "ResponseResponses", "");
                return body;
            }
            if (isCodeInRange("0", response.httpStatusCode)) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "ErrorResponse", "");
                throw new ApiException(0, body);
            }
            // Work around for missing responses in specification, e.g. for petstore.yaml
            if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "ResponseResponses", "");
                return body;
            }
            let body = response.body || "";
            throw new ApiException(response.httpStatusCode, "Unknown API Status Code!\nBody: \"" + body + "\"");
        });
    }
    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to getSurveyRules
     * @throws ApiException if the response code was not in [200, 299]
     */
    getSurveyRules(response) {
        return __awaiter(this, void 0, void 0, function* () {
            const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
            if (isCodeInRange("200", response.httpStatusCode)) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "Rules", "");
                return body;
            }
            if (isCodeInRange("0", response.httpStatusCode)) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "ErrorResponse", "");
                throw new ApiException(0, body);
            }
            // Work around for missing responses in specification, e.g. for petstore.yaml
            if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "Rules", "");
                return body;
            }
            let body = response.body || "";
            throw new ApiException(response.httpStatusCode, "Unknown API Status Code!\nBody: \"" + body + "\"");
        });
    }
    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to getUser
     * @throws ApiException if the response code was not in [200, 299]
     */
    getUser(response) {
        return __awaiter(this, void 0, void 0, function* () {
            const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
            if (isCodeInRange("200", response.httpStatusCode)) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "GetUserResponse", "");
                return body;
            }
            // Work around for missing responses in specification, e.g. for petstore.yaml
            if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "GetUserResponse", "");
                return body;
            }
            let body = response.body || "";
            throw new ApiException(response.httpStatusCode, "Unknown API Status Code!\nBody: \"" + body + "\"");
        });
    }
    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to getUserReviews
     * @throws ApiException if the response code was not in [200, 299]
     */
    getUserReviews(response) {
        return __awaiter(this, void 0, void 0, function* () {
            const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
            if (isCodeInRange("200", response.httpStatusCode)) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "Array<UserReview>", "");
                return body;
            }
            // Work around for missing responses in specification, e.g. for petstore.yaml
            if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "Array<UserReview>", "");
                return body;
            }
            let body = response.body || "";
            throw new ApiException(response.httpStatusCode, "Unknown API Status Code!\nBody: \"" + body + "\"");
        });
    }
    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to getUsers
     * @throws ApiException if the response code was not in [200, 299]
     */
    getUsers(response) {
        return __awaiter(this, void 0, void 0, function* () {
            const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
            if (isCodeInRange("200", response.httpStatusCode)) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "Array<CompanyUsers>", "");
                return body;
            }
            if (isCodeInRange("0", response.httpStatusCode)) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "ErrorResponse", "");
                throw new ApiException(0, body);
            }
            // Work around for missing responses in specification, e.g. for petstore.yaml
            if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "Array<CompanyUsers>", "");
                return body;
            }
            let body = response.body || "";
            throw new ApiException(response.httpStatusCode, "Unknown API Status Code!\nBody: \"" + body + "\"");
        });
    }
    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to nextReview
     * @throws ApiException if the response code was not in [200, 299]
     */
    nextReview(response) {
        return __awaiter(this, void 0, void 0, function* () {
            const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
            if (isCodeInRange("200", response.httpStatusCode)) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "ReviewNextResponse", "");
                return body;
            }
            if (isCodeInRange("0", response.httpStatusCode)) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "ErrorResponse", "");
                throw new ApiException(0, body);
            }
            // Work around for missing responses in specification, e.g. for petstore.yaml
            if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "ReviewNextResponse", "");
                return body;
            }
            let body = response.body || "";
            throw new ApiException(response.httpStatusCode, "Unknown API Status Code!\nBody: \"" + body + "\"");
        });
    }
    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to resetReviewScore
     * @throws ApiException if the response code was not in [200, 299]
     */
    resetReviewScore(response) {
        return __awaiter(this, void 0, void 0, function* () {
            const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
            if (isCodeInRange("200", response.httpStatusCode)) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "ReviewResetScoreResponse", "");
                return body;
            }
            if (isCodeInRange("0", response.httpStatusCode)) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "ErrorResponse", "");
                throw new ApiException(0, body);
            }
            // Work around for missing responses in specification, e.g. for petstore.yaml
            if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "ReviewResetScoreResponse", "");
                return body;
            }
            let body = response.body || "";
            throw new ApiException(response.httpStatusCode, "Unknown API Status Code!\nBody: \"" + body + "\"");
        });
    }
    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to resubmitReview
     * @throws ApiException if the response code was not in [200, 299]
     */
    resubmitReview(response) {
        return __awaiter(this, void 0, void 0, function* () {
            const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
            if (isCodeInRange("200", response.httpStatusCode)) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "ReviewSubmitResponse", "");
                return body;
            }
            if (isCodeInRange("0", response.httpStatusCode)) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "ErrorResponse", "");
                throw new ApiException(0, body);
            }
            // Work around for missing responses in specification, e.g. for petstore.yaml
            if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "ReviewSubmitResponse", "");
                return body;
            }
            let body = response.body || "";
            throw new ApiException(response.httpStatusCode, "Unknown API Status Code!\nBody: \"" + body + "\"");
        });
    }
    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to revertReview
     * @throws ApiException if the response code was not in [200, 299]
     */
    revertReview(response) {
        return __awaiter(this, void 0, void 0, function* () {
            const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
            if (isCodeInRange("200", response.httpStatusCode)) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "ReviewResponse", "");
                return body;
            }
            if (isCodeInRange("0", response.httpStatusCode)) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "ErrorResponse", "");
                throw new ApiException(0, body);
            }
            // Work around for missing responses in specification, e.g. for petstore.yaml
            if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "ReviewResponse", "");
                return body;
            }
            let body = response.body || "";
            throw new ApiException(response.httpStatusCode, "Unknown API Status Code!\nBody: \"" + body + "\"");
        });
    }
    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to startReview
     * @throws ApiException if the response code was not in [200, 299]
     */
    startReview(response) {
        return __awaiter(this, void 0, void 0, function* () {
            const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
            if (isCodeInRange("201", response.httpStatusCode)) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "ReviewStartResponse", "");
                return body;
            }
            if (isCodeInRange("0", response.httpStatusCode)) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "ErrorResponse", "");
                throw new ApiException(0, body);
            }
            // Work around for missing responses in specification, e.g. for petstore.yaml
            if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "ReviewStartResponse", "");
                return body;
            }
            let body = response.body || "";
            throw new ApiException(response.httpStatusCode, "Unknown API Status Code!\nBody: \"" + body + "\"");
        });
    }
    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to submitReview
     * @throws ApiException if the response code was not in [200, 299]
     */
    submitReview(response) {
        return __awaiter(this, void 0, void 0, function* () {
            const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
            if (isCodeInRange("200", response.httpStatusCode)) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "ReviewSubmitResponse", "");
                return body;
            }
            if (isCodeInRange("0", response.httpStatusCode)) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "ErrorResponse", "");
                throw new ApiException(0, body);
            }
            // Work around for missing responses in specification, e.g. for petstore.yaml
            if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "ReviewSubmitResponse", "");
                return body;
            }
            let body = response.body || "";
            throw new ApiException(response.httpStatusCode, "Unknown API Status Code!\nBody: \"" + body + "\"");
        });
    }
    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to updateReviewRemediation
     * @throws ApiException if the response code was not in [200, 299]
     */
    updateReviewRemediation(response) {
        return __awaiter(this, void 0, void 0, function* () {
            const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
            if (isCodeInRange("200", response.httpStatusCode)) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "ReviewUpdateRemediationResponse", "");
                return body;
            }
            if (isCodeInRange("0", response.httpStatusCode)) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "ErrorResponse", "");
                throw new ApiException(0, body);
            }
            // Work around for missing responses in specification, e.g. for petstore.yaml
            if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "ReviewUpdateRemediationResponse", "");
                return body;
            }
            let body = response.body || "";
            throw new ApiException(response.httpStatusCode, "Unknown API Status Code!\nBody: \"" + body + "\"");
        });
    }
    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to updateReviewReport
     * @throws ApiException if the response code was not in [200, 299]
     */
    updateReviewReport(response) {
        return __awaiter(this, void 0, void 0, function* () {
            const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
            if (isCodeInRange("201", response.httpStatusCode)) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "ReviewUpdateRequest", "");
                return body;
            }
            if (isCodeInRange("0", response.httpStatusCode)) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "ErrorResponse", "");
                throw new ApiException(0, body);
            }
            // Work around for missing responses in specification, e.g. for petstore.yaml
            if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "ReviewUpdateRequest", "");
                return body;
            }
            let body = response.body || "";
            throw new ApiException(response.httpStatusCode, "Unknown API Status Code!\nBody: \"" + body + "\"");
        });
    }
    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to useLatestSurvey
     * @throws ApiException if the response code was not in [200, 299]
     */
    useLatestSurvey(response) {
        return __awaiter(this, void 0, void 0, function* () {
            const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
            if (isCodeInRange("204", response.httpStatusCode)) {
                return;
            }
            // Work around for missing responses in specification, e.g. for petstore.yaml
            if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
                const body = ObjectSerializer.deserialize(ObjectSerializer.parse(yield response.body.text(), contentType), "void", "");
                return body;
            }
            let body = response.body || "";
            throw new ApiException(response.httpStatusCode, "Unknown API Status Code!\nBody: \"" + body + "\"");
        });
    }
}

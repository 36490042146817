import { __awaiter } from "tslib";
import { mapGetters } from "vuex";
import LanceScoreTable from "./report/ScoreTable.vue";
import LanceResponses from "./report/Responses.vue";
import LanceComments from "./report/Comments.vue";
import { GET_REPORT_ACTION, RESET_REPORT_SCORE_ACTION, RESUBMIT_REPORT_ACTION, DOWNLOAD_REPORT_ACTION, } from "@/store/types";
import Vue from "vue";
export default Vue.extend({
    name: "LanceReport",
    components: {
        LanceScoreTable,
        LanceResponses,
        LanceComments,
    },
    data() {
        return {
            reportType: "original",
            showScoreTopbar: false,
        };
    },
    computed: Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, mapGetters({
        companyName: "review/companyName",
        originalReport: "review/originalReport",
    })), mapGetters("review", ["changeReport", "gettingReport"])), mapGetters("report", ["downloading"])), mapGetters("auth", ["isAccountLead", "isAdmin"])), { report() {
            return this.$store.getters["review/report"];
        },
        orderedResponses() {
            return this.report
                ? Object.values(this.report.responses).sort((a, b) => a.order - b.order)
                : [];
        },
        isReportRemediable() {
            var _a;
            return (((_a = this.report) === null || _a === void 0 ? void 0 : _a.originalScore) || 0) > 0;
        } }),
    created() {
        this.$store.dispatch(`review/${GET_REPORT_ACTION}`, this.$route.params.id);
    },
    methods: {
        showPdfDownload() {
            return process.env.VUE_APP_SHOW_PDF_DOWNLOAD === "true";
        },
        resetRemediations() {
            var _a;
            this.$store.dispatch(`review/${RESET_REPORT_SCORE_ACTION}`, (_a = this.report) === null || _a === void 0 ? void 0 : _a.report_id);
        },
        resubmitReport() {
            var _a;
            this.$store.dispatch(`review/${RESUBMIT_REPORT_ACTION}`, (_a = this.report) === null || _a === void 0 ? void 0 : _a.report_id);
        },
        downloadPDF() {
            return __awaiter(this, void 0, void 0, function* () {
                const report = this.report;
                if (!(report === null || report === void 0 ? void 0 : report.report_id)) {
                    return;
                }
                this.$store.dispatch(`report/${DOWNLOAD_REPORT_ACTION}`, {
                    id: report.report_id,
                    remediated: this.reportType === "remediated",
                    structure: report.structure,
                });
            });
        },
    },
});

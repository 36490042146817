import Vue from "vue";
import { ADD_RULE_MUTATION, EDIT_RULE_MUTATION, DELETE_RULE_MUTATION, REORDER_RULE_MUTATION } from "@/store/types";
import checkLogic from "@/lib/logic";
import { mapMutations } from "vuex";
import { cloneDeep, debounce } from "lodash";
export default Vue.extend({
    name: "RuleList",
    props: {
        rules: {
            type: Array,
            required: true,
        },
        subgroupId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            selected: null,
            form: null,
            search: "",
        };
    },
    computed: {
        responses() {
            const responses = this.$store.getters["editor/responses/responses"];
            return Object.values(responses.responses)
                .filter((v) => { var _a; return v.isRiskScore || v.id === ((_a = this.selected) === null || _a === void 0 ? void 0 : _a.response); })
                .sort((a, b) => a.name.localeCompare(b.name));
        },
    },
    watch: {
        subgroupId() {
            this.selected = null;
            this.form = null;
        },
    },
    methods: Object.assign(Object.assign({}, mapMutations("editor/rules", {
        doAdd: ADD_RULE_MUTATION,
        doEdit: EDIT_RULE_MUTATION,
        doReorder: REORDER_RULE_MUTATION,
    })), { checkLogic: debounce(function (e) {
            checkLogic(e.target);
        }, 500), onUpdate(e) {
            this.doReorder({
                subgroupId: this.subgroupId,
                from: e.oldIndex,
                to: e.newIndex,
            });
        },
        add() {
            this.selected = null;
            this.form = {
                id: "",
                name: "",
                logic: "",
                score: 0,
                response: "",
            };
        },
        edit(rule) {
            this.form = cloneDeep(rule);
            this.selected = rule;
        },
        remove() {
            if (!this.selected) {
                return;
            }
            this.$store.commit(`editor/rules/${DELETE_RULE_MUTATION}`, {
                subgroupId: this.subgroupId,
                ruleId: this.selected.id,
            });
            this.selected = null;
            this.form = null;
        },
        save() {
            var _a;
            if ((_a = this.form) === null || _a === void 0 ? void 0 : _a.id) {
                this.doEdit(this.form);
            }
            else {
                this.doAdd({
                    subgroupId: this.subgroupId,
                    rule: this.form,
                });
            }
            this.selected = this.form;
            this.form = null;
        },
        filteredRules() {
            return this.search.length > 0
                ? this.rules.filter(({ name, logic, response }) => [name, logic, response].some((v) => v.includes(this.search)))
                : this.rules;
        } }),
});

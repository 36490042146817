/**
 * LANCE API
 * This API provides access to LANCE backend services.  PLEASE NOTE! This should be in the root repository as it is SPOT place for this file. However as the web repo is the only consumer of this API, keep it here temporary until it makes sense to move it to root repository.
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
export class ResponseResponse {
    constructor() {
    }
    static getAttributeTypeMap() {
        return ResponseResponse.attributeTypeMap;
    }
}
ResponseResponse.discriminator = undefined;
ResponseResponse.attributeTypeMap = [
    {
        "name": "id",
        "baseName": "id",
        "type": "string",
        "format": ""
    },
    {
        "name": "name",
        "baseName": "name",
        "type": "string",
        "format": ""
    },
    {
        "name": "text",
        "baseName": "text",
        "type": "string",
        "format": ""
    },
    {
        "name": "logic",
        "baseName": "logic",
        "type": "string",
        "format": ""
    },
    {
        "name": "isHeading",
        "baseName": "isHeading",
        "type": "boolean",
        "format": ""
    },
    {
        "name": "isMoreDetail",
        "baseName": "isMoreDetail",
        "type": "boolean",
        "format": ""
    },
    {
        "name": "isRiskScore",
        "baseName": "isRiskScore",
        "type": "boolean",
        "format": ""
    },
    {
        "name": "headingSize",
        "baseName": "headingSize",
        "type": "string",
        "format": ""
    },
    {
        "name": "status",
        "baseName": "status",
        "type": "ResponseResponseStatusEnum",
        "format": ""
    }
];

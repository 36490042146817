import { __awaiter } from "tslib";
import { ApiException, } from "@/api/openapi";
import { api } from "@/api";
import review from "@/api/review";
import entities from "@/lib/entities";
import { DELETE_COMPLETED_REVIEW_ACTION, DELETE_UNCOMPLETED_REVIEW_ACTION, DELETE_COMPLETED_REVIEW_ASYNC, DELETE_UNCOMPLETED_REVIEW_ASYNC, REVERT_REVIEW_ACTION, REVERT_REVIEW_ASYNC, START_REVIEW_ACTION, START_REVIEW_ASYNC, NEXT_QUESTIONS_ACTION, NEXT_QUESTIONS_ASYNC, SUBMIT_REVIEW_ACTION, SUBMIT_REVIEW_ASYNC, GET_REPORT_ACTION, GET_REPORT_ASYNC, UPDATE_REPORT_ACTION, UPDATE_REPORT_ASYNC, UPDATE_REMEDIATION_ISSUE_ACTION, UPDATE_REMEDIATION_ISSUE_ASYNC, RESET_REPORT_SCORE_ACTION, RESET_REPORT_SCORE_ASYNC, RESUBMIT_REPORT_ACTION, RESUBMIT_REPORT_ASYNC, GET_ANSWER_SUMMARY_ACTION, GET_ANSWER_SUMMARY_ASYNC, } from "@/store/types";
import { RESET_ENTITIES, STRUCTURE_ADD_ENTITY, STRUCTURE_DELETE_ENTITY, STRUCTURE_UPDATE_ENTITY } from "./review/types";
import Vue from "vue";
export const state = {
    reviewId: null,
    finished: false,
    progress: {
        current: 0,
        total: 0,
    },
    questions: {
        questions: null,
        part: {},
        sequence: [],
    },
    structure: {
        name: "",
        entities: {},
        rootEntitiesIds: [],
    },
    report: {},
    delete: {
        pending: false,
        error: null,
    },
    revert: {
        pending: false,
        error: null,
    },
    start: {
        pending: false,
        error: null,
    },
    next: {
        pending: false,
        error: null,
    },
    submit: {
        pending: false,
        error: null,
    },
    fetchReport: {
        pending: false,
        error: null,
    },
    fromBack: false,
    changeReport: {
        pending: false,
        error: null,
    },
    answerSummary: {
        Pages: {},
    },
    fetchAnswerSummary: {
        pending: false,
        error: null,
    },
};
export const getters = {
    entities: (state) => Object.assign({}, state.structure.entities),
    rootEntities: (state) => state.structure.rootEntitiesIds.map((id) => state.structure.entities[id]),
    rootEntitiesIds: (state) => state.structure.rootEntitiesIds.slice(0),
    entityByID: (state) => (id) => state.structure.entities[id],
    structure: (state) => state.structure,
    questions: (state) => state.questions.questions,
    progress: (state) => state.progress,
    part: (state) => state.questions.part,
    delete: (state) => state.delete,
    revert: (state) => state.revert,
    start: (state) => state.start,
    next: (state) => state.next,
    report: ({ report }) => report.report,
    companyName: (state) => state.report.companyName,
    sequence: (state) => state.questions.sequence,
    originalReport: ({ report }) => {
        var _a, _b;
        return ((_a = report.report) === null || _a === void 0 ? void 0 : _a.originalScore) && ((_b = report.report) === null || _b === void 0 ? void 0 : _b.originalTable)
            ? {
                score: report.report.originalScore,
                table: report.report.originalTable,
            }
            : {};
    },
    fromBack: (state) => state.fromBack,
    submittingAction: (state) => state.fetchReport.pending,
    startingReview: (state) => state.start.pending,
    changeReport: (state) => state.changeReport.pending,
    gettingReport: (state) => state.fetchReport.pending,
    answerSummary: (state) => state.answerSummary,
    nextError: (state) => state.next.error,
    submitError: (state) => state.submit.error,
    remediableResponseIds: (state) => {
        const report = state.report.report;
        if (!report) {
            return [];
        }
        const remediableResponseIds = [];
        Object.values(report.originalTable.rowGroups).forEach((rowGroup) => {
            Object.values(rowGroup.rows).forEach((row) => {
                Object.values(row.columns).forEach((column) => {
                    Object.values(column.values).forEach((value) => {
                        if (value.isLooped) {
                            Object.values(value.loopedValues).forEach((loopedValue) => {
                                if (loopedValue.score > 0) {
                                    remediableResponseIds.push(loopedValue.responseId);
                                }
                            });
                        }
                        else if (value.score > 0) {
                            remediableResponseIds.push(value.responseId);
                        }
                    });
                });
            });
        });
        return remediableResponseIds;
    },
    responseLookup: (state) => {
        return Object.values(state.report.report.responses).reduce((r, responses) => {
            Object.values(responses.groups).forEach((group) => Object.values(group.subgroups).forEach((subgroup) => {
                var _a;
                (_a = subgroup.responses) === null || _a === void 0 ? void 0 : _a.forEach((response) => (r[`${responses.id}:${response.id}`] = response));
                Object.values(subgroup.loopedResponses).forEach((loopedResponse) => loopedResponse.responses.forEach((response) => (r[`${responses.id}:${response.id}`] = response)));
            }));
            return r;
        }, {});
    },
    selectResponse: (state, getters) => (selector) => {
        return getters.responseLookup[`${selector.entityId}:${selector.responseId}`];
    },
    tableValue: (state) => (selector) => {
        const rows = state.report.report.table.rowGroups[selector.entityId].rows;
        for (const rowId in rows) {
            for (const columnId in rows[rowId].columns) {
                for (const valueId in rows[rowId].columns[columnId].values) {
                    const value = rows[rowId].columns[columnId].values[valueId];
                    if (value.isLooped) {
                        if (Object.values(value.loopedValues).some((v) => v.responseId === selector.responseId)) {
                            return value;
                        }
                    }
                    else if (value.responseId === selector.responseId) {
                        return value;
                    }
                }
            }
        }
        return undefined;
    },
};
export const actions = {
    // structure
    // others
    [DELETE_COMPLETED_REVIEW_ACTION]({ commit }, id) {
        commit(DELETE_COMPLETED_REVIEW_ASYNC.PENDING);
        return review
            .deleteCompletedReview(id)
            .then((res) => {
            commit(DELETE_COMPLETED_REVIEW_ASYNC.SUCCESS, res);
        })
            .catch((err) => {
            commit(DELETE_COMPLETED_REVIEW_ASYNC.FAILURE, err);
            throw new Error(err);
        });
    },
    [DELETE_UNCOMPLETED_REVIEW_ACTION]({ commit }, id) {
        commit(DELETE_UNCOMPLETED_REVIEW_ASYNC.PENDING);
        return review
            .deleteUncompletedReview(id)
            .then((res) => {
            commit(DELETE_UNCOMPLETED_REVIEW_ASYNC.SUCCESS, res);
        })
            .catch((err) => {
            commit(DELETE_UNCOMPLETED_REVIEW_ASYNC.FAILURE, err);
            throw new Error(err);
        });
    },
    [REVERT_REVIEW_ACTION]({ commit }, id) {
        commit(REVERT_REVIEW_ASYNC.PENDING);
        return review
            .revert(id)
            .then((res) => {
            commit(REVERT_REVIEW_ASYNC.SUCCESS, res);
        })
            .catch((err) => {
            commit(REVERT_REVIEW_ASYNC.FAILURE, err);
            throw new Error(err);
        });
    },
    [START_REVIEW_ACTION]({ commit }, data) {
        const preview = data.preview;
        const structure = entities(data.structure);
        commit(START_REVIEW_ASYNC.PENDING);
        return review
            .start({ preview, structure })
            .then((res) => {
            commit(START_REVIEW_ASYNC.SUCCESS, res);
            return res.reviewId;
        })
            .catch((err) => {
            commit(START_REVIEW_ASYNC.FAILURE, err);
            throw new Error(err);
        });
    },
    [NEXT_QUESTIONS_ACTION]({ commit, state }, data) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                let part = undefined;
                if (!data.resume) {
                    part = Object.assign(Object.assign({}, state.questions.part), { answers: data.answers });
                }
                commit(NEXT_QUESTIONS_ASYNC.PENDING);
                const res = yield api.nextReview(data.reviewId, {
                    requestedPartId: data.requestedPartId,
                    resume: data.resume,
                    part: part,
                    back: data.back,
                });
                commit(NEXT_QUESTIONS_ASYNC.SUCCESS, res.finished ? Object.assign(Object.assign({}, res), { requestedPartId: state.questions.part.id }) : res);
                return res;
            }
            catch (err) {
                if (err instanceof ApiException) {
                    commit(NEXT_QUESTIONS_ASYNC.FAILURE, err.body);
                }
                else {
                    console.error(err);
                }
            }
        });
    },
    [SUBMIT_REVIEW_ACTION]({ commit }, reviewId) {
        commit(SUBMIT_REVIEW_ASYNC.PENDING);
        return review
            .submit({
            reviewId,
        })
            .then((res) => {
            commit(SUBMIT_REVIEW_ASYNC.SUCCESS, res);
            return reviewId;
        })
            .catch((err) => {
            commit(SUBMIT_REVIEW_ASYNC.FAILURE, err);
            throw err;
        });
    },
    [GET_REPORT_ACTION]({ commit }, reviewId) {
        commit(GET_REPORT_ASYNC.PENDING);
        return review
            .report(reviewId)
            .then((res) => commit(GET_REPORT_ASYNC.SUCCESS, res))
            .catch((err) => commit(GET_REPORT_ASYNC.FAILURE, err));
    },
    [UPDATE_REPORT_ACTION]({ commit }, data) {
        commit(UPDATE_REPORT_ASYNC.PENDING);
        return review
            .update(data)
            .then((res) => commit(UPDATE_REPORT_ASYNC.SUCCESS, res))
            .catch((err) => commit(UPDATE_REPORT_ASYNC.FAILURE, err));
    },
    [UPDATE_REMEDIATION_ISSUE_ACTION]({ commit, state }, data) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                commit(UPDATE_REMEDIATION_ISSUE_ASYNC.PENDING);
                const res = yield api.updateReviewRemediation(state.report.report.report_id, data);
                commit(UPDATE_REMEDIATION_ISSUE_ASYNC.SUCCESS, res);
            }
            catch (err) {
                commit(UPDATE_REMEDIATION_ISSUE_ASYNC.FAILURE, err);
            }
        });
    },
    [RESET_REPORT_SCORE_ACTION]({ commit }, data) {
        commit(RESET_REPORT_SCORE_ASYNC.PENDING);
        return review
            .resetScore(data)
            .then((res) => commit(RESET_REPORT_SCORE_ASYNC.SUCCESS, res))
            .catch((err) => commit(RESET_REPORT_SCORE_ASYNC.FAILURE, err));
    },
    [RESUBMIT_REPORT_ACTION]({ commit }, data) {
        commit(RESUBMIT_REPORT_ASYNC.PENDING);
        return review
            .resubmit(data)
            .then((res) => commit(RESUBMIT_REPORT_ASYNC.SUCCESS, res))
            .catch((err) => commit(RESUBMIT_REPORT_ASYNC.FAILURE, err));
    },
    [GET_ANSWER_SUMMARY_ACTION]({ commit }, id) {
        commit(GET_ANSWER_SUMMARY_ASYNC.PENDING);
        return review
            .getAnswerSummary(id)
            .then((res) => commit(GET_ANSWER_SUMMARY_ASYNC.SUCCESS, res))
            .catch((err) => commit(GET_ANSWER_SUMMARY_ASYNC.FAILURE, err));
    },
};
export const mutations = {
    // structure
    [STRUCTURE_ADD_ENTITY](state, entity) {
        Vue.set(state.structure.entities, entity.id, entity);
        if (entity.parent) {
            state.structure.entities[entity.parent].children.push(entity.id);
        }
        else {
            // root entity
            state.structure.rootEntitiesIds.push(entity.id);
        }
    },
    [STRUCTURE_DELETE_ENTITY](state, entityId) {
        state.structure.entities[entityId].children.forEach((v) => this.commit(`review/${STRUCTURE_DELETE_ENTITY}`, v));
        Vue.delete(state.structure.entities, entityId);
        if (state.structure.rootEntitiesIds.includes(entityId)) {
            state.structure.rootEntitiesIds.splice(state.structure.rootEntitiesIds.indexOf(entityId), 1);
        }
    },
    [STRUCTURE_UPDATE_ENTITY](state, entity) {
        state.structure.entities[entity.id] = entity;
    },
    // others
    [RESET_ENTITIES](state) {
        state.structure = {
            name: "",
            entities: {},
            rootEntitiesIds: [],
        };
    },
    [DELETE_COMPLETED_REVIEW_ASYNC.PENDING](state) {
        state.delete.pending = true;
    },
    [DELETE_COMPLETED_REVIEW_ASYNC.SUCCESS](state) {
        state.delete.pending = false;
    },
    [DELETE_COMPLETED_REVIEW_ASYNC.FAILURE](state) {
        state.delete.pending = false;
    },
    [DELETE_UNCOMPLETED_REVIEW_ASYNC.PENDING](state) {
        state.delete.pending = true;
    },
    [DELETE_UNCOMPLETED_REVIEW_ASYNC.SUCCESS](state) {
        state.delete.pending = false;
    },
    [DELETE_UNCOMPLETED_REVIEW_ASYNC.FAILURE](state) {
        state.delete.pending = false;
    },
    [REVERT_REVIEW_ASYNC.PENDING](state) {
        state.revert.pending = true;
    },
    [REVERT_REVIEW_ASYNC.SUCCESS](state) {
        state.revert.pending = false;
    },
    [REVERT_REVIEW_ASYNC.FAILURE](state) {
        state.revert.pending = false;
    },
    [START_REVIEW_ASYNC.PENDING](state) {
        state.start.pending = true;
    },
    [START_REVIEW_ASYNC.SUCCESS](state, data) {
        state.start.pending = false;
        state.questions = data;
    },
    [START_REVIEW_ASYNC.FAILURE](state, error) {
        state.start.pending = false;
        state.start.error = error;
    },
    [NEXT_QUESTIONS_ASYNC.PENDING](state) {
        state.next.pending = true;
    },
    [NEXT_QUESTIONS_ASYNC.SUCCESS](state, data) {
        state.progress = data.progress;
        state.next.pending = false;
        state.reviewId = data.reviewId;
        state.finished = data.finished;
        state.structure = data.structure;
        state.questions.questions = data.questions;
        state.questions.part = data.part;
        state.questions.sequence = data.sequence.parts;
        state.fromBack = data.fromBack;
        state.next.error = null;
    },
    [NEXT_QUESTIONS_ASYNC.FAILURE](state, error) {
        state.next.pending = false;
        state.next.error = error;
    },
    [SUBMIT_REVIEW_ASYNC.PENDING](state) {
        state.submit.pending = true;
    },
    [SUBMIT_REVIEW_ASYNC.SUCCESS](state, data) {
        state.submit.pending = false;
        state.report = data.report;
    },
    [SUBMIT_REVIEW_ASYNC.FAILURE](state, error) {
        state.submit.pending = false;
        state.submit.error = error;
    },
    [GET_REPORT_ASYNC.PENDING](state) {
        state.fetchReport.pending = true;
    },
    [GET_REPORT_ASYNC.SUCCESS](state, data) {
        state.fetchReport.pending = false;
        state.report = data;
    },
    [GET_REPORT_ASYNC.FAILURE](state, error) {
        state.fetchReport.pending = false;
        state.fetchReport.error = error;
    },
    [UPDATE_REPORT_ASYNC.PENDING](state) {
        state.fetchReport.pending = true;
    },
    [UPDATE_REPORT_ASYNC.SUCCESS](state, data) {
        state.fetchReport.pending = false;
        state.report = data;
    },
    [UPDATE_REPORT_ASYNC.FAILURE](state, error) {
        state.fetchReport.pending = false;
        state.fetchReport.error = error;
    },
    [UPDATE_REMEDIATION_ISSUE_ASYNC.PENDING](state) {
        state.fetchReport.pending = true;
    },
    [UPDATE_REMEDIATION_ISSUE_ASYNC.SUCCESS](state, data) {
        state.fetchReport.pending = false;
        state.report = data;
    },
    [UPDATE_REMEDIATION_ISSUE_ASYNC.FAILURE](state, error) {
        state.fetchReport.pending = false;
        state.fetchReport.error = error;
    },
    [RESET_REPORT_SCORE_ASYNC.PENDING](state) {
        state.changeReport.pending = true;
    },
    [RESET_REPORT_SCORE_ASYNC.SUCCESS](state, data) {
        state.changeReport.pending = false;
        state.report = data;
    },
    [RESET_REPORT_SCORE_ASYNC.FAILURE](state, error) {
        state.changeReport.pending = false;
        state.changeReport.error = error;
    },
    [RESUBMIT_REPORT_ASYNC.PENDING](state) {
        state.changeReport.pending = true;
    },
    [RESUBMIT_REPORT_ASYNC.SUCCESS](state, data) {
        state.changeReport.pending = false;
        state.report = data;
    },
    [RESUBMIT_REPORT_ASYNC.FAILURE](state, error) {
        state.changeReport.pending = false;
        state.changeReport.error = error;
    },
    [GET_ANSWER_SUMMARY_ASYNC.PENDING](state) {
        state.fetchAnswerSummary.pending = true;
    },
    [GET_ANSWER_SUMMARY_ASYNC.SUCCESS](state, data) {
        state.fetchAnswerSummary.pending = false;
        state.answerSummary = data;
    },
    [GET_ANSWER_SUMMARY_ASYNC.FAILURE](state, error) {
        state.fetchAnswerSummary.pending = false;
        state.fetchAnswerSummary.error = error;
    },
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

import { __awaiter } from "tslib";
import Vue from "vue";
import { mapGetters } from "vuex";
import { GET_USER_REVIEWS_ACTION, DELETE_COMPLETED_REVIEW_ACTION, REVERT_REVIEW_ACTION } from "@/store/types";
import { api } from "@/api";
export default Vue.extend({
    name: "UserDetailReports",
    data() {
        return {
            validationErrors: [],
            localState: null,
            content: {
                title: [
                    {
                        type: "heading1",
                        text: "Reviews",
                        spans: [],
                    },
                ],
                instruction: [
                    {
                        type: "paragraph",
                        text: "Use the filters to find partially completed reviews or access reports for completed reviews.",
                        spans: [],
                    },
                ],
                show_all: "All",
                show_complete: "Complete",
                show_incomplete: "Incomplete",
                search: "Search Reviews",
                reference: "Review Id",
                creator: "Created By",
                entity_count: "Num. Entities",
                submitted_date: "Submitted",
                structure_name: "Structure Name",
                risk_score: "Risk Score",
                completed: "Completed",
                review: "View Report",
                resume: "Continue Review",
            },
            snackbar: false,
            snackbarText: "",
        };
    },
    computed: Object.assign(Object.assign({}, mapGetters("auth", ["isAdmin"])), { userReviews() {
            const reviews = this.$store.getters["users/userReviews"];
            return reviews.sort((a, b) => b.submitted - a.submitted);
        } }),
    created() {
        this.$store.dispatch(`users/${GET_USER_REVIEWS_ACTION}`, this.$route.params.id);
    },
    methods: {
        deleteDialog(id) {
            if (confirm("Are you sure you want to delete this review?\nThis action cannot be undone.")) {
                this.$store.dispatch(`review/${DELETE_COMPLETED_REVIEW_ACTION}`, id).then(() => {
                    this.$store.dispatch(`users/${GET_USER_REVIEWS_ACTION}`, this.$route.params.id);
                });
            }
        },
        revertDialog(id) {
            if (confirm("Are you sure you want to revert this review?\nThis action cannot be undone.")) {
                this.$store.dispatch(`review/${REVERT_REVIEW_ACTION}`, id).then(() => {
                    this.$store.dispatch(`users/${GET_USER_REVIEWS_ACTION}`, this.$route.params.id);
                });
            }
        },
        useLatestSurvey(id) {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    yield api.useLatestSurvey(id);
                    this.snackbarText = "Review was updated to use the most recent survey.";
                    this.snackbar = true;
                }
                catch (e) {
                    this.snackbarText = "Failed to update the review to the most recent survey.";
                    this.snackbar = true;
                }
            });
        },
    },
});

import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
export default Vue.extend({
    name: "ConfirmPasswordReset",
    data() {
        return {
            username: "",
            code: "",
            password: "",
            passwordConfirm: "",
            err: "",
        };
    },
    computed: Object.assign({}, mapGetters("auth", ["pending", "authenticationStatus"])),
    methods: Object.assign(Object.assign({}, mapActions("auth", ["confirmPasswordReset"])), { passwordReset() {
            if (this.password !== this.passwordConfirm) {
                this.err = "Passwords do not match";
            }
            else {
                this.err = "";
                this.confirmPasswordReset({
                    username: this.username,
                    code: this.code,
                    password: this.password,
                }).then(() => {
                    this.password = "";
                    this.passwordConfirm = "";
                    this.code = "";
                    this.username = "";
                });
            }
        } }),
});

import users from "@/api/users";
import { api } from "@/api";
import { GET_USER_LIST_ASYNC, GET_USER_LIST_ACTION, GET_USER_REVIEWS_ASYNC, GET_USER_REVIEWS_ACTION, GET_USER_ASYNC, GET_USER_ACTION, UPDATE_USER_ASYNC, UPDATE_USER_ACTION, UPDATE_USER_FILTER_MUTATION, CREATE_USER_ASYNC, CREATE_USER_ACTION, DELETE_USER_ACTION, DELETE_USER_ASYNC, DELETE_CUSTOMER_ACTION, DELETE_CUSTOMER_ASYNC, } from "@/store/types";
export const state = {
    companyFilter: "",
    userList: [],
    user: null,
    userReviews: [],
    getUserList: {
        pending: false,
        error: null,
    },
    updateUser: {
        pending: false,
        error: null,
        success: null,
    },
    createUser: {
        pending: false,
        error: null,
        success: null,
    },
    getUser: {
        pending: false,
        error: null,
    },
    deleteUser: {
        pending: false,
        error: null,
    },
    deleteCustomer: {
        pending: false,
        error: null,
    },
    getUserReviews: {
        pending: false,
        error: null,
        success: null,
    },
};
// getters
export const getters = {
    userList: (state) => state.userList,
    user: (state) => state.user,
    userReviews: (state) => state.userReviews,
    updateUser: (state) => state.updateUser,
    createUser: (state) => state.createUser,
    deleteUser: (state) => state.deleteUser,
    deleteCustomer: (state) => state.deleteCustomer,
    companyFilter: (state) => state.companyFilter,
};
// actions
export const actions = {
    [GET_USER_LIST_ACTION]({ commit }) {
        commit(GET_USER_LIST_ASYNC.PENDING);
        api
            .getUsers()
            .then((res) => commit(GET_USER_LIST_ASYNC.SUCCESS, res))
            .catch((err) => commit(GET_USER_LIST_ASYNC.FAILURE, err));
    },
    [GET_USER_REVIEWS_ACTION]({ commit }, id) {
        commit(GET_USER_REVIEWS_ASYNC.PENDING);
        users
            .getUserReviews(id)
            .then((res) => commit(GET_USER_REVIEWS_ASYNC.SUCCESS, res))
            .catch((err) => commit(GET_USER_REVIEWS_ASYNC.FAILURE, err));
    },
    [GET_USER_ACTION]({ commit }, id) {
        commit(GET_USER_ASYNC.PENDING);
        api
            .getUser(id)
            .then((res) => commit(GET_USER_ASYNC.SUCCESS, res))
            .catch((err) => commit(GET_USER_ASYNC.FAILURE, err));
    },
    [CREATE_USER_ACTION]({ commit }, user) {
        commit(CREATE_USER_ASYNC.PENDING);
        users
            .createUser(user)
            .then((res) => commit(CREATE_USER_ASYNC.SUCCESS, res))
            .catch((err) => commit(CREATE_USER_ASYNC.FAILURE, err));
    },
    [UPDATE_USER_ACTION]({ commit }, user) {
        commit(UPDATE_USER_ASYNC.PENDING);
        users
            .updateUser(user)
            .then((res) => commit(UPDATE_USER_ASYNC.SUCCESS, res))
            .catch((err) => commit(UPDATE_USER_ASYNC.FAILURE, err));
    },
    [DELETE_USER_ACTION]({ commit }, id) {
        commit(DELETE_USER_ASYNC.PENDING);
        users
            .deleteUser(id)
            .then((res) => commit(DELETE_USER_ASYNC.SUCCESS, res))
            .catch((err) => commit(DELETE_USER_ASYNC.FAILURE, err));
    },
    [DELETE_CUSTOMER_ACTION]({ commit, dispatch }, id) {
        commit(DELETE_CUSTOMER_ASYNC.PENDING);
        users
            .deleteCustomer(id)
            .then((res) => {
            commit(DELETE_CUSTOMER_ASYNC.SUCCESS, res);
            dispatch(GET_USER_LIST_ACTION);
        })
            .catch((err) => commit(DELETE_CUSTOMER_ASYNC.FAILURE, err));
    },
};
// mutations
export const mutations = {
    [UPDATE_USER_FILTER_MUTATION](state, filter) {
        state.companyFilter = filter;
    },
    [GET_USER_ASYNC.PENDING](state) {
        state.user = null;
        state.getUser.pending = true;
        state.updateUser.success = null;
        state.updateUser.error = null;
    },
    [GET_USER_ASYNC.SUCCESS](state, res) {
        state.user = res.users;
        state.getUser.pending = false;
    },
    [GET_USER_ASYNC.FAILURE](state, error) {
        state.getUser.pending = false;
        state.getUser.error = error;
    },
    [GET_USER_REVIEWS_ASYNC.PENDING](state) {
        state.userReviews = [];
        state.getUserReviews.pending = true;
        state.getUserReviews.success = null;
        state.getUserReviews.error = null;
    },
    [GET_USER_REVIEWS_ASYNC.SUCCESS](state, res) {
        state.userReviews = res;
        state.getUserReviews.pending = false;
    },
    [GET_USER_REVIEWS_ASYNC.FAILURE](state, error) {
        state.getUserReviews.pending = false;
        state.getUserReviews.error = error;
    },
    [CREATE_USER_ASYNC.PENDING](state) {
        state.createUser.pending = true;
        state.createUser.error = null;
        state.createUser.success = null;
    },
    [CREATE_USER_ASYNC.SUCCESS](state, res) {
        state.createUser.pending = false;
        state.createUser.error = null;
        state.createUser.success = res;
    },
    [CREATE_USER_ASYNC.FAILURE](state, error) {
        state.createUser.pending = false;
        state.createUser.error = error;
        state.createUser.success = null;
    },
    [UPDATE_USER_ASYNC.PENDING](state) {
        state.updateUser.pending = true;
        state.updateUser.success = null;
        state.updateUser.error = null;
    },
    [UPDATE_USER_ASYNC.SUCCESS](state, res) {
        state.updateUser.pending = false;
        state.updateUser.error = null;
        state.updateUser.success = res;
    },
    [UPDATE_USER_ASYNC.FAILURE](state, error) {
        state.updateUser.pending = false;
        state.updateUser.error = error;
        state.updateUser.success = null;
    },
    [DELETE_USER_ASYNC.PENDING](state) {
        state.deleteUser.pending = true;
        state.deleteUser.error = null;
    },
    [DELETE_USER_ASYNC.SUCCESS](state) {
        state.deleteUser.error = null;
        state.deleteUser.pending = false;
    },
    [DELETE_USER_ASYNC.FAILURE](state, error) {
        state.deleteUser.pending = false;
        state.deleteUser.error = error;
    },
    [DELETE_CUSTOMER_ASYNC.PENDING](state) {
        state.deleteCustomer.pending = true;
        state.deleteCustomer.error = null;
    },
    [DELETE_CUSTOMER_ASYNC.SUCCESS](state) {
        state.deleteCustomer.error = null;
        state.deleteCustomer.pending = false;
    },
    [DELETE_CUSTOMER_ASYNC.FAILURE](state, error) {
        state.deleteCustomer.pending = false;
        state.deleteCustomer.error = error;
    },
    [GET_USER_LIST_ASYNC.PENDING](state) {
        state.getUserList.pending = true;
    },
    [GET_USER_LIST_ASYNC.SUCCESS](state, res) {
        state.userList = res;
        state.getUserList.pending = false;
    },
    [GET_USER_LIST_ASYNC.FAILURE](state, error) {
        state.getUserList.pending = false;
        state.getUserList.error = error;
    },
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

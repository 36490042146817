import Vue from "vue";
import LanceApp from "./App.vue";
import router from "./router";
import store from "./store";
import VueAnalytics from "vue-analytics";
import VueI18n from "vue-i18n";
import { messages } from "./translations";
import Amplify from "aws-amplify";
import Storage from "@aws-amplify/storage";
import Analytics from "@aws-amplify/analytics";
import Sortable from "sortablejs";
import VueIntercom from "vue-intercom";
import PrismicVue from "prismic-vue";
import { BootstrapVue } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import { createPinia, PiniaVuePlugin } from "pinia";
import vuetify from "./plugins/vuetify";
Vue.directive("sortable", {
    inserted(el, binding) {
        /* eslint-disable no-new */
        new Sortable(el, binding.value || {});
    },
});
Amplify.configure({
    aws_app_analytics: process.env.VUE_APP_AWS_APP_ANALYTICS,
    aws_cognito_identity_pool_id: process.env.VUE_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    aws_cognito_region: process.env.VUE_APP_AWS_COGNITO_REGION,
    aws_mobile_analytics_app_id: process.env.VUE_APP_AWS_MOBILE_ANALYTICS_APP_ID,
    aws_mobile_analytics_app_region: process.env.VUE_APP_AWS_MOBILE_ANALYTICS_APP_REGION,
    aws_project_id: process.env.VUE_APP_AWS_PROJECT_ID,
    aws_project_name: process.env.VUE_APP_AWS_PROJECT_NAME,
    aws_project_region: process.env.VUE_APP_AWS_PROJECT_REGION,
    aws_resource_name_prefix: process.env.VUE_APP_AWS_RESOURCE_NAME_PREFIX,
    aws_sign_in_enabled: process.env.VUE_APP_AWS_SIGN_IN_ENABLED,
    aws_user_pools: process.env.VUE_APP_AWS_USER_POOLS,
    aws_user_pools_id: process.env.VUE_APP_AWS_USER_POOLS_ID,
    aws_user_pools_web_client_id: process.env.VUE_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
});
Storage.configure({
    AWSS3: {
        bucket: process.env.VUE_APP_AWS_S3_BUCKET,
        region: process.env.VUE_APP_AWS_S3_REGION,
    },
});
Analytics.configure({ disabled: true });
Analytics.disable();
Vue.config.productionTip = false;
Vue.use(PrismicVue, {
    endpoint: "https://lance.prismic.io/api/v2",
});
Vue.use(VueIntercom, { appId: process.env.VUE_APP_INTERCOM_APP_ID });
Vue.use(VueAnalytics, {
    id: "UA-122584706-1",
});
Vue.use(BootstrapVue);
Vue.use(VueCompositionAPI);
Vue.filter("capitalize", (value) => {
    if (!value)
        return "";
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
});
Vue.filter("upper", (value) => value.toUpperCase());
Vue.filter("formatDate", function (timestamp) {
    if (timestamp) {
        const date = new Date(timestamp * 1000);
        return `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
    }
});
// TODO: unused?
Vue.filter("formatDateShort", function (timestamp) {
    if (timestamp) {
        const date = new Date(timestamp * 1000);
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear().toString().substr(-2)}`;
    }
});
Vue.filter("formatDateEnglish", function (timestamp) {
    if (timestamp) {
        const date = new Date(timestamp * 1000);
        return `${date.getDate()} ${date.toLocaleString("en-GB", {
            month: "long",
        })} ${date.getFullYear()}`;
    }
});
Vue.filter("formatDateTime", function (timestamp) {
    if (timestamp) {
        const date = new Date(timestamp * 1000);
        return `${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}/${date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1}/${date.getFullYear()} ${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`;
    }
});
Vue.filter("truncate", function (text, length, clamp) {
    text = text || "";
    clamp = clamp || "…";
    length = length || 30;
    if (text.length <= length)
        return text;
    let tcText = text.slice(0, length - clamp.length);
    let last = tcText.length - 1;
    while (last > 0 && tcText[last] !== " " && tcText[last] !== clamp[0])
        last -= 1;
    last = last || length - clamp.length;
    tcText = tcText.slice(0, last);
    return tcText + clamp;
});
const browserLocale = () => navigator.language ||
    navigator.browserLanguage || // TODO: verify if this ever worked
    (navigator.languages || ["en-GB"])[0];
Vue.use(VueI18n);
const i18n = new VueI18n({
    locale: browserLocale(),
    fallbackLocale: "en-GB",
    messages,
});
Vue.use(PiniaVuePlugin);
const pinia = createPinia();
/* eslint-disable no-new */
new Vue({
    i18n,
    el: "#app",
    router,
    store,
    pinia,
    components: { LanceApp },
    vuetify,
    template: "<LanceApp/>",
});

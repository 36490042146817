import { __awaiter } from "tslib";
import axios from "axios";
import { Auth } from "@aws-amplify/auth";
export function async({ method, url, data, auth, }) {
    return __awaiter(this, void 0, void 0, function* () {
        let headers = {};
        if (auth) {
            const session = yield Auth.currentSession();
            headers = {
                Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
            };
        }
        // url = url.includes("submit") ? `http://localhost:3000${url}` : `${process.env.VUE_APP_API_ENDPOINT}${url}`;
        url = `${process.env.VUE_APP_API_ENDPOINT}${url}`;
        return axios({
            method,
            url,
            data,
            headers,
        })
            .then((v) => v === null || v === void 0 ? void 0 : v.data)
            .catch((e) => {
            throw e.response.data ? e.response.data : "server_unreachable";
        });
    });
}

import { __awaiter } from "tslib";
import Vue from "vue";
import LanceSave from "@/components/editor/Save.vue";
import { mapGetters, mapActions } from "vuex";
export default Vue.extend({
    name: "LanceNavigation",
    components: {
        LanceSave,
    },
    props: {
        content: {
            type: Object,
            required: true,
        },
    },
    computed: Object.assign({}, mapGetters("auth", ["isAuthenticated", "isAdmin", "isAccountLead", "passwordChangeRequired"])),
    methods: Object.assign(Object.assign({}, mapActions("auth", ["signOut"])), { logOut() {
            return __awaiter(this, void 0, void 0, function* () {
                yield this.signOut();
                this.$router.push({ name: "Home" });
            });
        },
        getNavigationDetailsByKey(key) {
            try {
                return this.content.links.find(function (item) {
                    return item.key === key;
                });
            }
            catch (exception) {
                return { key, title: "missing", description: "" };
            }
        } }),
});

import { START_REVIEW_ACTION, RESET_ENTITIES, DECEASED, STRUCTURE_ENTITY } from "@/store/types";
import { v4 as uuidv4 } from "uuid";
import Datepicker from "vue2-datepicker";
import Vue from "vue";
import { mapGetters } from "vuex";
import { lang, maxDate } from "@/conf";
const storageKey = "reviewStructureModel";
export default Vue.extend({
    name: "LanceStructure",
    components: {
        Datepicker,
    },
    props: {
        preview: {
            type: Boolean,
        },
    },
    data() {
        return {
            name: "",
            tempDate: "",
            tempIncorporationDate: "",
            tempExitDate: "",
            globalErrorMsg: "",
            error: "",
            parents: 0,
            dateAddDisabled: false,
            datePickerOptions: {
                clearable: false,
                format: "DD/MM/YYYY",
                language: {
                    placeholder: {
                        date: "Select date",
                        dateRange: "Select date range",
                    },
                },
                shortcuts: [],
            },
            endDate: new Date(maxDate),
            invalidEntities: 0,
            entities: {},
            rootEntitiesIds: [],
            helper: {},
            entity_type_content: [
                {
                    type: "trust",
                    owner_name: "Corporate Settlors",
                    owner_description: [
                        {
                            type: "paragraph",
                            text: "It is possible for a trust to be settled by another offshore entity, i.e. by the trustees of another trust, by a foundation, or by a corporate entity. Please enter all corporate settlors of trust: {entity}, throughout it's lifetime. Please ensure any corporate settlor is added to the structure for the purpose of the LANCE review and then it will be available to be selected from the dropdown menu. The date of the settlement will need to be entered also.",
                            spans: [],
                        },
                    ],
                    party_name: "Settlors",
                    party_description: [
                        {
                            type: "paragraph",
                            text: "Please enter all individual settlors, of trust: {entity}, throughout the lifetime of the trust. Please enter each settlor's name, dates of any contributions to the trust, and marking whether or not they were deceased at the time of settlement.",
                            spans: [],
                        },
                        {
                            type: "paragraph",
                            text: "If the trust was formed as a consequence of the provisions in a will then please enter the deceased individual as a settlor, marking them deceased. If the trust was formed as a consequence of a death where there was no will, or where a deed of variation was passed; then please enter the deceased individual marking them as deceased, and enter the person(s) who actually enacted the settlement of the trust.",
                            spans: [],
                        },
                    ],
                    table_headings: {
                        name: "Name",
                        date: "Contribution date",
                    },
                },
                {
                    type: "foundation",
                    owner_name: "Non-individual Founders",
                    owner_description: [
                        {
                            type: "paragraph",
                            text: "It is possible for a foundation to be founded by another offshore entity, i.e. by the trustees a trust, by another foundation, or by a corporate entity. Please enter all non-individual founders of foundation: {entity}, throughout it's lifetime. Please ensure any non-individual founder is added to the structure for the purpose of the LANCE review and then it will be available to be selected from the dropdown menu. The date of the contribution will need to be entered also.",
                            spans: [],
                        },
                    ],
                    party_name: "Founders",
                    party_description: [
                        {
                            type: "paragraph",
                            text: "Please enter all individual founders of foundation: {entity}. Please enter each founder's name, dates of any contributions to the foundation, and marking whether or not they were deceased at the time of settlement, i.e. was the foundation formed as a consequence of the provisions in a will.",
                            spans: [],
                        },
                    ],
                    table_headings: {
                        name: "Name",
                        date: "Contribution date",
                    },
                },
                {
                    type: "company",
                    owner_name: "Trustee or Corporate Shareholders",
                    owner_description: [
                        {
                            type: "paragraph",
                            text: "If company: {entity} is owned by another entity, i.e. by a trust, foundation or another company, then please enter all such entity shareholders, past and present. Please ensure any entity shareholder is added to the structure for the purpose of the LANCE review and then it will be available to be selected from the dropdown menu. The dates of ownership will need to be entered also.",
                            spans: [],
                        },
                    ],
                    party_name: "Individual Shareholders",
                    party_description: [
                        {
                            type: "paragraph",
                            text: "Please enter any individual shareholders of company: {entity}. Please enter each founder's name, dates of any contributions to the foundation, and marking whether or not they were deceased at the time of settlement, i.e. was the foundation formed as a consequence of the provisions in a will.",
                            spans: [],
                        },
                    ],
                    table_headings: {
                        name: "Name",
                        date: "Period of ownership",
                    },
                },
                {
                    type: "nominee",
                    description: [
                        {
                            type: "paragraph",
                            text: "For UK tax purposes a company acting under a nominee arrangement will create a complete look-through position for the entity who is the actual owner under the terms of the custodial agreement. This means that any holdings and activities of the nominee company will be considered to have been held or conducted by the actual owner. For the purpose of a LANCE review, when answering questions for the actual owner please answer the questions on behalf of both the actual owner and the nominee company.",
                            spans: [],
                        },
                    ],
                },
            ],
            content: {
                title: [
                    {
                        type: "heading1",
                        text: "Start a New Review",
                        spans: [],
                    },
                ],
                instruction: [
                    {
                        type: "paragraph",
                        text: "All connected entities need to be added to the structure to be reviewed together. Individual entities should not be reviewed separately as there may exist interactions between entities which may affect the report. For example, to review a trust with an underlying company both trust and company will need to be added to the structure.",
                        spans: [],
                    },
                    {
                        type: "paragraph",
                        text: "Please note: We require no personal identifiable information to be included in the LANCE review. As such, please use your in-house client reference number or otherwise codename whilst naming entities and individuals.",
                        spans: [
                            {
                                start: 0,
                                end: 12,
                                type: "strong",
                            },
                        ],
                    },
                ],
            },
        };
    },
    computed: Object.assign(Object.assign({}, mapGetters("review", ["startingReview"])), { replaceEntityInHtml() {
            return (elements, id) => JSON.parse(JSON.stringify(elements)).map((v) => {
                v.text = v.text.replace("{entity}", this.entities[id].name);
                return v;
            });
        } }),
    watch: {
        $data: {
            handler(data) {
                // save all changes to localStorage. So user is able to continue structure page later.
                localStorage.setItem(storageKey, JSON.stringify(data));
            },
            deep: true,
        },
    },
    mounted() {
        this.$store.commit(`review/${RESET_ENTITIES}`);
        // load structure page if exists to continue review.
        const s = localStorage.getItem(storageKey);
        if (!s) {
            // user has not started review
            this.addEntity();
            return;
        }
        // user started review previously, but hasn't yet completed structure page.
        Object.entries(JSON.parse(s))
            .filter(([k]) => k !== "endDate") // TODO: temporary, can be removed later
            .forEach(([k, v]) => Vue.set(this, k, v));
    },
    created() {
        this.getContent();
    },
    methods: {
        addEntitiesToOptionDropdown(rootEntity) {
            const etc = this.entity_type_content.find((x) => x.type === rootEntity.type);
            let entitiesToAdd = [];
            for (const i in this.rootEntitiesIds) {
                let entity = this.entities[this.rootEntitiesIds[i]];
                if (entity.type === "nominee") {
                    continue;
                }
                if (entity.id === rootEntity.id ||
                    entity.unders.find((x) => x === rootEntity.id) ||
                    rootEntity.unders.find((x) => x === entity.id)) {
                    continue;
                }
                if (etc && etc.owner_name === "Corporate Settlors") {
                    if (entity.type === STRUCTURE_ENTITY.TRUST) {
                        continue;
                    }
                    // check if owned by trust indirectly
                    if (entity.overs.some((e) => this.entities[e].type === STRUCTURE_ENTITY.TRUST)) {
                        continue;
                    }
                }
                entitiesToAdd.push(entity);
            }
            return entitiesToAdd;
        },
        entityTypeChange(entityId) {
            this.entities[entityId].meta["entity_type"] = this.entities[entityId].type;
        },
        lastDateAvailable(rootEntityId) {
            return this.entities[rootEntityId].exit_date || this.endDate;
        },
        canShowEntityInOption(entityId, rootEntityId) {
            if (this.entities[entityId].type === STRUCTURE_ENTITY.NOMINEE) {
                return false;
            }
            if (rootEntityId === this.entities[entityId].id) {
                return false;
            }
            /* Check to make sure there isn't a loop in the entity structure */
            return this.checkChildUndersArray(entityId, this.entities[rootEntityId].unders, []);
        },
        addOwnersDisabled(rootEntityId) {
            // allow only 1 corporate settlor
            const entity = this.entities[rootEntityId];
            if (entity.type === STRUCTURE_ENTITY.TRUST) {
                if (entity.overs && entity.overs.length > 0) {
                    return true;
                }
            }
            if (this.rootEntitiesIds === undefined) {
                return true;
            }
            if (this.addEntitiesToOptionDropdown(this.entities[rootEntityId]).length === 0) {
                return true;
            }
            for (const i in this.rootEntitiesIds) {
                if (this.canShowEntityInOption(this.rootEntitiesIds[i], rootEntityId)) {
                    return false;
                }
            }
            return true;
        },
        deceasedAlreadyAdded(children) {
            /* This method is called to disable the deceased yes option
            if there is already a settler/founder that is already deceased */
            var childId;
            for (childId in children) {
                // TODO: YES does not exist
                if (this.entities[children[childId]].meta.deceased === STRUCTURE_ENTITY.YES) {
                    return true;
                }
            }
            return false;
        },
        startReview() {
            this.rootEntitiesIds.forEach((id) => {
                const e = this.entities[id];
                e.meta = Object.assign(Object.assign({}, e.meta), { name: e.name, active: "yes" });
                this.entities[id] = e;
            });
            this.validateEntities();
            if (this.invalidEntities > 0)
                return;
            this.$store
                .dispatch(`review/${START_REVIEW_ACTION}`, {
                preview: this.preview,
                structure: {
                    name: this.name,
                    entities: this.entities,
                    rootEntitiesIds: this.rootEntitiesIds,
                },
            })
                .then((reviewId) => {
                if (reviewId) {
                    // user completed structure page, remove temporary stored state
                    // of structure page.
                    localStorage.removeItem(storageKey);
                    this.error = "";
                    this.$router.push({ name: "Review", params: { id: reviewId } });
                }
            })
                .catch((err) => {
                this.error = err.message;
            });
        },
        checkChildUndersArray(parentId, unders, alreadyCheckedUnderChild) {
            var under;
            if (unders.length === 0) {
                return true;
            }
            for (under in unders) {
                if (unders[under] === parentId) {
                    return false;
                }
                else {
                    if (!alreadyCheckedUnderChild.includes(unders[under])) {
                        alreadyCheckedUnderChild.push(unders[under]);
                        if (!this.checkChildUndersArray(parentId, this.entities[unders[under]].unders, alreadyCheckedUnderChild)) {
                            return false;
                        }
                    }
                }
            }
            return true;
        },
        checkForEntityLoops() {
            /* This function checks the child unders array recersivily
            of every entity to make sure that it is not repeated anywhere to avoid loops */
            var entity;
            for (entity in this.entities) {
                if (this.entities[entity].unders === undefined) {
                    continue;
                }
                /* the alreadyCheckedUnderChild array is to prevent checking the same child structure many times as 2 parents can own a child */
                if (!this.checkChildUndersArray(this.entities[entity].id, this.entities[entity].unders, [])) {
                    return false;
                }
            }
            return true;
        },
        parseDate(date) {
            var parts = date.split("/");
            return new Date(+parts[2], +parts[1] - 1, +parts[0]);
        },
        validateEntities() {
            this.invalidEntities = 0;
            if (!this.checkForEntityLoops()) {
                this.globalErrorMsg = "Review cannot be started there is a loop in the entites";
                this.invalidEntities += 1;
                return;
            }
            else {
                this.globalErrorMsg = "";
            }
            if (this.rootEntitiesIds.length === 1) {
                if (this.entities[this.rootEntitiesIds[0]].type === "nominee") {
                    this.globalErrorMsg = "Review cannot be started with just a company acting as nominee only";
                    this.invalidEntities += 1;
                    return;
                }
                else {
                    this.entities[this.rootEntitiesIds[0]].errorMessage = "";
                }
            }
            this.rootEntitiesIds
                .map((v) => this.entities[v])
                .forEach((entity) => {
                if (entity.meta.answerable === "yes" &&
                    (entity.type === undefined || (entity.children.length === 0 && entity.type !== "nominee")) &&
                    entity.overs.length === 0) {
                    entity.errorMessage =
                        "Please ensure that you have completed all relevant fields and this entity has at least one associated party.";
                    this.invalidEntities += 1;
                }
                else {
                    entity.errorMessage = "";
                }
            });
            if (this.invalidEntities > 0) {
                return;
            }
            const allDeceasedSettlors = [];
            const allShareholders = [];
            this.rootEntitiesIds.forEach((entityId) => {
                const entity = this.entities[entityId];
                this.entities[entityId].errorMessage = "";
                const deceasedSettlors = [];
                const livingSettlors = [];
                if (entity.type === STRUCTURE_ENTITY.TRUST) {
                    entity.children.forEach((childId) => {
                        const child = this.entities[childId];
                        if (child.type === STRUCTURE_ENTITY.SETTLOR) {
                            if (child.meta.deceased === "no") {
                                if (deceasedSettlors.some((c) => this.parseDate(child.meta.first_settlement) > this.parseDate(c.meta.first_settlement))) {
                                    this.invalidEntities += 1;
                                    this.entities[entityId].errorMessage =
                                        "Living settlor cannot have a settlement date after a deceased settlor";
                                }
                                if (livingSettlors.some((c) => c.name === child.name)) {
                                    this.invalidEntities += 1;
                                    this.entities[entityId].errorMessage = `Unable to add two living settlors with the name '${child.name}'`;
                                }
                                livingSettlors.push(child);
                            }
                            if (child.meta.deceased === "yes") {
                                if (livingSettlors.some((c) => this.parseDate(child.meta.first_settlement) < this.parseDate(c.meta.first_settlement))) {
                                    this.invalidEntities += 1;
                                    this.entities[entityId].errorMessage =
                                        "Deceased settlor cannot have a settlement date before a living settlor";
                                }
                                deceasedSettlors.push(child);
                            }
                        }
                    });
                }
                const shareholders = [];
                if (entity.type === STRUCTURE_ENTITY.COMPANY) {
                    entity.children.forEach((childId) => {
                        const child = this.entities[childId];
                        if (child.type === STRUCTURE_ENTITY.SHAREHOLDER) {
                            const isDuplicate = shareholders.some((c) => c.name === child.name);
                            if (isDuplicate) {
                                this.invalidEntities += 1;
                                this.entities[entityId].errorMessage = `Cannot have two shareholders with the same name: '${child.name}'`;
                            }
                            shareholders.push(child);
                        }
                    });
                }
                allDeceasedSettlors.push(...deceasedSettlors);
                allShareholders.push(...shareholders);
            });
            allShareholders.some((shareholder) => {
                const isInvalid = allDeceasedSettlors.some((settlor) => settlor.name === shareholder.name &&
                    this.parseDate(shareholder.meta.end_date) > this.parseDate(settlor.meta.first_settlement));
                if (isInvalid) {
                    this.invalidEntities += 1;
                    this.entities[shareholder.parent].errorMessage = `The end of deceased shareholder ${shareholder.name}'s period of ownership must not be after their settlement date`;
                }
                return isInvalid;
            });
        },
        removeEntity(id) {
            this.entities[id].children.forEach((childId) => {
                this.removeChild(childId, id);
            });
            this.entities[id].unders.forEach((underId) => {
                this.entities[underId].overs = this.entities[underId].overs.filter((value) => value !== id);
                this.entities[underId].dates = this.entities[underId].dates.filter((value) => value.id !== id);
            });
            this.entities[id].overs.forEach((overId) => {
                this.entities[overId].unders = this.entities[overId].unders.filter((value) => value !== id);
            });
            Vue.delete(this.entities, id);
            Vue.delete(this.helper, id);
            this.rootEntitiesIds = this.rootEntitiesIds.filter((value) => value !== id);
            this.parents--;
        },
        addEntity() {
            const entity = {
                id: uuidv4(),
                errorMessage: "",
                children: [],
                overs: [],
                unders: [],
                meta: {
                    answerable: "yes",
                },
                added: false,
                dates: [],
                order: this.parents++,
                parent: null,
            };
            this.addHelper(entity.id);
            Vue.set(this.entities, entity.id, entity);
            this.rootEntitiesIds.push(entity.id);
        },
        addHelper(id) {
            const helper = {
                showAddChild: false,
                showAddOwner: false,
                owner: {
                    id: null,
                    dates: [],
                    invalid: false,
                },
                child: {
                    name: null,
                    meta: {
                        deceased: "no",
                    },
                    dates: [],
                    date: null,
                },
            };
            Vue.set(this.helper, id, helper);
        },
        entitiesForId() {
            return this.rootEntitiesIds.map((id) => {
                const entity = this.entities[id];
                entity.meta.answerable = entity.meta.answerable || "yes";
                return entity;
            });
        },
        toggleAddOwner(entityId) {
            this.helper[entityId].showAddOwner = !this.helper[entityId].showAddOwner;
        },
        addOwner(entityId) {
            if (this.helper[entityId].owner.dates.length === 0) {
                this.helper[entityId].owner.invalid = true;
                return;
            }
            const owner = this.helper[entityId].owner;
            const ownerId = owner.id;
            owner.dates.forEach((date) => (date.id = ownerId));
            this.entities[entityId].overs.push(ownerId);
            this.entities[entityId].dates = [...this.entities[entityId].dates, ...owner.dates];
            this.entities[ownerId].unders.push(entityId);
            this.entities[ownerId].added = true;
            this.toggleAddOwner(entityId);
            this.helper[entityId].owner = {
                id: null,
                dates: [],
                invalid: false,
            };
            this.tempDate = "";
        },
        removeOwner(entityId, ownerId) {
            this.entities[entityId].overs = this.entities[entityId].overs.filter((id) => id !== ownerId);
            this.entities[entityId].dates = this.entities[entityId].dates.filter((date) => date.id !== ownerId);
            this.entities[ownerId].added = false;
            this.entities[ownerId].unders = this.entities[ownerId].unders.filter((id) => id !== entityId);
        },
        toggleAddChild(entityId) {
            this.helper[entityId].showAddChild = !this.helper[entityId].showAddChild;
        },
        addChild(entityId) {
            let type = "";
            switch (this.entities[entityId].type) {
                case STRUCTURE_ENTITY.TRUST:
                    type = "settlor";
                    break;
                case STRUCTURE_ENTITY.FOUNDATION:
                    type = "founder";
                    break;
                case STRUCTURE_ENTITY.COMPANY:
                    type = "shareholder";
                    break;
            }
            const getEarliestDate = () => { var _a, _b; return ((_b = (_a = this.helper[entityId]) === null || _a === void 0 ? void 0 : _a.child) === null || _b === void 0 ? void 0 : _b.dates).sort(sortDates)[0].meta.date; };
            // Accept string 'DD-MM-YYYY', convert it to 'YYYY-MM-DD' then return as data for comparison
            const formatDate = (dateString) => new Date(dateString.split("/").reverse().join("-"));
            const sortDates = (a, b) => {
                const d1 = formatDate(a.meta.date);
                const d2 = formatDate(b.meta.date);
                if (d1 > d2)
                    return 1;
                if (d1 < d2)
                    return -1;
                return 0;
            };
            const id = uuidv4();
            const child = {
                id,
                name: this.helper[entityId].child.name,
                children: [],
                parent: entityId,
                type,
                meta: type === "shareholder"
                    ? {
                        name: this.helper[entityId].child.name,
                        start_date: this.helper[entityId].child.dates[0].meta.date,
                        end_date: this.helper[entityId].child.dates[0].meta.end,
                        entity_type: type,
                    }
                    : {
                        name: this.helper[entityId].child.name,
                        deceased: this.helper[entityId].child.meta.deceased,
                        first_settlement: getEarliestDate(),
                        entity_type: type,
                    },
            };
            if (!this.helper[entityId].child.name || this.helper[entityId].child.dates.length === 0) {
                this.helper[entityId].child.invalid = true;
                return;
            }
            this.helper[entityId].child.dates.forEach((date) => {
                var _a;
                this.entities[date.id] = date;
                date.parent = id;
                (_a = child.children) === null || _a === void 0 ? void 0 : _a.push(date.id);
            });
            this.entities[id] = child;
            child.ordinal = this.entities[entityId].children.length;
            this.entities[entityId].children.push(id);
            this.toggleAddChild(entityId);
            this.helper[entityId].child = {
                name: null,
                dates: [],
                date: null,
                meta: {
                    deceased: DECEASED.NO,
                },
            };
            this.validateEntities();
            this.dateAddDisabled = false;
        },
        removeChild(entityId, ownerId) {
            this.entities[entityId].children.forEach((childId) => {
                Vue.delete(this.entities, childId);
            });
            let ii = 0;
            this.entities[ownerId].children.forEach((childId) => {
                this.entities[childId].ordinal = ii++;
            });
            Vue.delete(this.entities, entityId);
            this.entities[ownerId].children = this.entities[ownerId].children.filter((id) => id !== entityId);
        },
        addDate(entityId, date) {
            this.helper[entityId].child.dates.push({
                id: uuidv4(),
                type: this.entities[entityId].type === "trust" ? "settlement" : "contribution",
                meta: {
                    localeDateStart: date.toLocaleDateString(),
                    date: date.toLocaleDateString("en-GB"),
                    entity_type: this.entities[entityId].type === "trust" ? "settlement" : "contribution",
                },
            });
            if (this.helper[entityId].child.meta.deceased === DECEASED.YES &&
                this.helper[entityId].child.dates.length === 1) {
                this.dateAddDisabled = true;
            }
        },
        updateSettlementDates(entityId, deceased) {
            if (deceased) {
                if (this.helper[entityId].child.dates.length > 0) {
                    this.dateAddDisabled = true;
                }
                if (this.helper[entityId].child.dates.length > 1) {
                    for (let i = this.helper[entityId].child.dates.length - 1; i > 0; i--) {
                        this.removeDate(entityId, this.helper[entityId].child.dates[i].id);
                    }
                }
            }
            else {
                this.dateAddDisabled = false;
            }
        },
        addDateRange(entityId, dateRange) {
            this.helper[entityId].child.dates.push({
                id: uuidv4(),
                type: "shareholderdates",
                meta: {
                    date: dateRange[0].toLocaleDateString("en-GB"),
                    end: dateRange[1].toLocaleDateString("en-GB"),
                    localeDateStart: dateRange[0].toLocaleDateString(),
                    localeDateEnd: dateRange[1].toLocaleDateString(),
                },
            });
        },
        showEntityStillActive(rootEntity) {
            if (rootEntity.type === undefined) {
                return false;
            }
            if (rootEntity.type === STRUCTURE_ENTITY.NOMINEE) {
                return false;
            }
            if (rootEntity.type === STRUCTURE_ENTITY.COMPANY) {
                if (!rootEntity.meta["incorporation_date"]) {
                    return false;
                }
            }
            return true;
        },
        addIncorporationDate(entityId, date) {
            Vue.set(this.entities[entityId].meta, "incorporation_date", date === null || date === void 0 ? void 0 : date.toLocaleDateString("en-GB"));
            Vue.set(this.entities[entityId], "incorporation_date", date);
        },
        addDateAssociation(entityId, date) {
            this.helper[entityId].owner.dates.push({
                date: date.toLocaleDateString("en-GB"),
            });
        },
        addDateRangeAssociation(entityId, dateRange) {
            this.helper[entityId].owner.dates.push({
                date: dateRange[0].toLocaleDateString("en-GB"),
                end: dateRange[1].toLocaleDateString("en-GB"),
            });
        },
        removeDate(entityId, dateId) {
            this.helper[entityId].child.dates = this.helper[entityId].child.dates.filter((date) => date.id !== dateId);
            if (this.helper[entityId].child.meta.deceased === DECEASED.YES &&
                this.helper[entityId].child.dates.length === 0) {
                this.dateAddDisabled = false;
            }
        },
        ownerDisplay(rootEntity, overId) {
            const ownerDateByOverEntity = rootEntity.dates.filter((x) => x.id === overId)[0];
            let display = "";
            if (ownerDateByOverEntity) {
                display = ownerDateByOverEntity.date;
                if (rootEntity.type === STRUCTURE_ENTITY.COMPANY) {
                    display += "~" + ownerDateByOverEntity.end;
                }
            }
            return display;
        },
        getContent() {
            this.$prismic.client.getSingle("survey", { lang }).then((response) => {
                if (!response)
                    return;
                this.content.title = response.data.title;
                this.content.instruction = response.data.instruction;
                let obj = this.entity_type_content.find((obj) => obj.type === "trust");
                obj.owner_description = response.data.nonIndividualSettlors;
                obj.party_description = response.data.settlors;
                obj = this.entity_type_content.find((obj) => obj.type === "foundation");
                obj.owner_description = response.data.nonIndividualFounders;
                obj.party_description = response.data.founders;
                obj = this.entity_type_content.find((obj) => obj.type === "company");
                obj.owner_description = response.data.owners;
                obj.party_description = response.data.shareholders;
                obj = this.entity_type_content.find((obj) => obj.type === "nominee");
                obj.description = response.data.nominee;
            });
        },
    },
});

import Vue from "vue";
import { ADD_RESPONSE_GROUP_MUTATION, EDIT_RESPONSE_GROUP_MUTATION, DELETE_RESPONSE_GROUP_MUTATION, REORDER_RESPONSE_GROUP_MUTATION, } from "@/store/types";
import checkLogic from "@/lib/logic";
import LanceSubgroups from "./Subgroups.vue";
import { cloneDeep, debounce } from "lodash";
export default Vue.extend({
    name: "ResponseGroupList",
    components: {
        LanceSubgroups,
    },
    props: {
        groups: {
            type: Array,
            required: true,
        },
        categoryId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            selected: null,
            form: null,
        };
    },
    computed: {
        subgroups() {
            return this.$store.getters["editor/responses/subgroupsForGroup"](this.selected);
        },
    },
    watch: {
        categoryId() {
            this.selected = null;
        },
    },
    methods: {
        checkLogic: debounce(function (e) {
            checkLogic(e.target);
        }, 500),
        onUpdate(e) {
            if (e.oldIndex !== undefined && e.newIndex !== undefined) {
                this.reorderGroup(e.oldIndex, e.newIndex);
            }
        },
        select(group) {
            var _a;
            this.selected = ((_a = this.selected) === null || _a === void 0 ? void 0 : _a.id) === group.id ? null : group;
            this.form = null;
        },
        add() {
            this.selected = null;
            this.form = {
                id: "",
                name: "",
                logic: "",
                heading: "",
                headingSize: "",
                subgroups: [],
            };
        },
        edit(group) {
            this.selected = group;
            this.form = cloneDeep(group);
        },
        remove() {
            if (this.selected) {
                this.removeGroup(this.selected.id);
            }
        },
        cancel() {
            this.form = null;
        },
        save() {
            if (this.form) {
                if (this.form.id) {
                    this.updateGroup(this.form);
                }
                else {
                    this.addGroup(this.form);
                }
            }
            this.selected = this.form;
            this.form = null;
        },
        addGroup(group) {
            const { categoryId } = this;
            this.$store.commit(`editor/responses/${ADD_RESPONSE_GROUP_MUTATION}`, { categoryId, group });
        },
        removeGroup(groupId) {
            const { categoryId } = this;
            this.$store.commit(`editor/responses/${DELETE_RESPONSE_GROUP_MUTATION}`, { categoryId, groupId });
            this.selected = null;
            this.form = null;
        },
        updateGroup(group) {
            this.$store.commit(`editor/responses/${EDIT_RESPONSE_GROUP_MUTATION}`, group);
        },
        reorderGroup(from, to) {
            const { categoryId } = this;
            this.$store.commit(`editor/responses/${REORDER_RESPONSE_GROUP_MUTATION}`, { categoryId, from, to });
        },
    },
});

var _a;
import Vuex, { createLogger } from "vuex";
import auth from "@/store/modules/auth";
import survey from "@/store/modules/survey";
import editor from "@/store/modules/editor";
import report from "@/store/modules/report";
import review from "@/store/modules/review";
import metrics from "@/store/modules/metrics";
import users from "@/store/modules/users";
import Vue from "vue";
Vue.use(Vuex);
const strict = process.env.NODE_ENV !== "production";
const plugins = ((_a = process.env.VUE_APP_DEBUG) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === "true" ? [createLogger()] : [];
export default new Vuex.Store({
    modules: {
        auth,
        survey,
        editor,
        report,
        review,
        metrics,
        users,
    },
    strict,
    plugins,
});

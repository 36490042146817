import { __awaiter } from "tslib";
import Vue from "vue";
import { converter } from "@/lib/shodown";
import LanceScoreTriangle from "./ScoreTriangle.vue";
import { UPDATE_REMEDIATION_ISSUE_ACTION } from "@/store/types";
export default Vue.extend({
    components: { LanceScoreTriangle },
    props: {
        entityId: {
            type: String,
            required: true,
        },
        response: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            remediationText: "",
            remediationAction: "unresolved",
            dialogVisible: false,
        };
    },
    computed: {
        loading() {
            return this.$store.getters["review/gettingReport"];
        },
        riskScoreType() {
            const tableValue = this.tableValue;
            const riskPresent = (tableValue.isLooped ? Object.values(tableValue.loopedValues) : [tableValue]).some((v) => (v.remediationStatus === "unresolved" || !v.remediationStatus) && v.score > 0);
            return riskPresent ? "riskPresent" : "noRisk";
        },
        text() {
            return converter.makeHtml(this.response.text);
        },
        tableValue() {
            return this.$store.getters["review/tableValue"]({
                entityId: this.entityId,
                responseId: this.response.id,
            });
        },
    },
    created() {
        var _a;
        if (this.tableValue.isLooped) {
            this.remediationAction =
                ((_a = Object.values(this.tableValue.loopedValues).find((v) => v.responseId === this.response.id)) === null || _a === void 0 ? void 0 : _a.remediationStatus) ||
                    "unresolved";
            return;
        }
        this.remediationAction = this.tableValue.remediationStatus || "unresolved";
    },
    methods: {
        submit() {
            return __awaiter(this, void 0, void 0, function* () {
                const request = {
                    selector: {
                        entityId: this.entityId,
                        responseId: this.response.id,
                    },
                    action: this.remediationAction,
                    text: this.remediationText,
                };
                yield this.$store.dispatch(`review/${UPDATE_REMEDIATION_ISSUE_ACTION}`, request);
                this.dialogVisible = false;
            });
        },
    },
});

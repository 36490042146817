import Showdown from "showdown";
import tabs from "./tabs";
import nomargin from "./nomargin";
import commalist from "./commalist";
import indent1 from "./indent1";
import indent2 from "./indent2";
import indent3 from "./indent3";
export const converter = new Showdown.Converter();
converter.addExtension(tabs, "tabs");
converter.addExtension(nomargin, "nomargin");
converter.addExtension(commalist, "commalist");
converter.addExtension(indent1, "indent1");
converter.addExtension(indent2, "indent2");
converter.addExtension(indent3, "indent3");

import Vue from "vue";
import LanceSurveyJSEditor from "@/components/SurveyJSEditor.vue";
import { mapGetters } from "vuex";
import { GET_DRAFT_SURVEY_ACTION_QUESTIONS } from "@/store/types";
export default Vue.extend({
    name: "SurveyEditor",
    components: {
        LanceSurveyJSEditor,
    },
    data() {
        return {
            type: null,
            subtype: null,
            types: ["trust", "settlor", "settlement", "foundation", "founder", "contribution", "company", "shareholder"],
            subtypes: ["pre", "post"],
            subtypes_extended: ["pre", "post", "settlor", "founder"],
        };
    },
    computed: Object.assign(Object.assign({}, mapGetters({
        questions: "editor/questions/questions",
    })), { typeQuestions() {
            if (this.type && this.subtype) {
                return this.questions.chunkList[this.type].chunks[this.subtype];
            }
            return null;
        } }),
    created() {
        this.$store.dispatch(`editor/questions/${GET_DRAFT_SURVEY_ACTION_QUESTIONS}`, true);
    },
    methods: {
        giveExtendedNames(subtype) {
            if (subtype === "settlor" || subtype === "founder") {
                return "acting as " + subtype;
            }
            else {
                return subtype;
            }
        },
    },
});

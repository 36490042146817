import Vue from "vue";
import { ADD_RULE_SUBGROUP_MUTATION, EDIT_RULE_SUBGROUP_MUTATION, DELETE_RULE_SUBGROUP_MUTATION, REORDER_RULE_SUBGROUP_MUTATION, } from "@/store/types";
import LanceRules from "@/components/editor/rule/Rules.vue";
import checkLogic from "@/lib/logic";
import { mapMutations, mapGetters } from "vuex";
import { cloneDeep, debounce } from "lodash";
export default Vue.extend({
    name: "RuleSubgroupList",
    components: {
        LanceRules,
    },
    props: {
        subgroups: {
            type: Array,
            required: true,
        },
        groupId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            selected: null,
            form: null,
        };
    },
    computed: Object.assign(Object.assign({}, mapGetters("editor/rules", ["rulesForSubgroup"])), { rules() {
            return this.selected !== null ? this.rulesForSubgroup(this.selected) : null;
        } }),
    watch: {
        groupId() {
            this.selected = null;
            this.form = null;
        },
    },
    methods: Object.assign(Object.assign({}, mapMutations("editor/rules", {
        doAdd: ADD_RULE_SUBGROUP_MUTATION,
        doEdit: EDIT_RULE_SUBGROUP_MUTATION,
        doReorder: REORDER_RULE_SUBGROUP_MUTATION,
    })), { checkLogic: debounce(function (e) {
            checkLogic(e.target);
        }, 500), onUpdate(e) {
            this.doReorder({
                groupId: this.groupId,
                from: e.oldIndex,
                to: e.newIndex,
            });
        },
        select(subgroup) {
            var _a;
            this.selected = ((_a = this.selected) === null || _a === void 0 ? void 0 : _a.id) === subgroup.id ? null : subgroup;
            this.form = null;
        },
        add() {
            this.selected = null;
            this.form = {
                id: "",
                name: "",
                logic: "",
                isLooped: false,
                loopKey: "",
                rules: [],
                context: "",
            };
        },
        edit(subgroup) {
            this.selected = subgroup;
            this.form = cloneDeep(subgroup);
        },
        remove() {
            if (!this.selected) {
                return;
            }
            this.$store.commit(`editor/rules/${DELETE_RULE_SUBGROUP_MUTATION}`, {
                groupId: this.groupId,
                subgroupId: this.selected.id,
            });
            this.selected = null;
            this.form = null;
        },
        save() {
            var _a;
            if ((_a = this.form) === null || _a === void 0 ? void 0 : _a.id) {
                this.doEdit(this.form);
            }
            else {
                this.doAdd({
                    groupId: this.groupId,
                    subgroup: this.form,
                });
            }
            this.selected = this.form;
            this.form = null;
        } }),
});

import { async } from "@/api/util";
function getCustomerMetrics(data) {
    return async({
        method: "post",
        url: `/metrics/customermetrics`,
        auth: true,
        data: data,
    });
}
export default {
    getCustomerMetrics,
};

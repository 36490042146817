import Vue from "vue";
import { mapMutations } from "vuex";
import { EDIT_DEFINITION_GROUP_MUTATION, DELETE_DEFINITION_GROUP_MUTATION } from "@/store/types";
export default Vue.extend({
    name: "LanceEdit",
    props: {
        group: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            name: "",
        };
    },
    computed: {
        groupIds() {
            return this.$store.getters["editor/definitions/groups"]
                .filter((v) => v.id !== this.group.id)
                .map((v) => v.id);
        },
        valid() {
            return this.name !== "" && !this.groupIds.includes(this.name);
        },
    },
    watch: {
        group(v) {
            this.name = v.id;
        },
    },
    mounted() {
        this.name = this.group.id;
    },
    methods: Object.assign(Object.assign({}, mapMutations("editor/definitions", {
        editGroup: EDIT_DEFINITION_GROUP_MUTATION,
        deleteGroup: DELETE_DEFINITION_GROUP_MUTATION,
    })), { save() {
            this.editGroup({ oldId: this.group.id, newId: this.name });
            this.name = "";
            this.$emit("closeEdit");
        },
        cancel() {
            this.name = "";
            this.$emit("closeEdit");
        },
        remove() {
            this.deleteGroup(this.group.id);
            this.name = "";
            this.$emit("closeEdit");
        } }),
});

import Vue from "vue";
export default Vue.extend({
    props: {
        type: {
            type: String,
            required: true,
        },
        size: {
            type: Number,
            default: () => 10,
        },
    },
});

import { cloneDeep } from "lodash";
import { SET_DEFINITIONS, GET_DRAFT_SURVEY_ACTION_DEFINITIONS, GET_DRAFT_SURVEY_ASYNC_DEFINITIONS, ADD_DEFINITION_GROUP_MUTATION, EDIT_DEFINITION_GROUP_MUTATION, DELETE_DEFINITION_GROUP_MUTATION, ADD_DEFINITION_MUTATION, EDIT_DEFINITION_MUTATION, DELETE_DEFINITION_MUTATION, REORDER_DEFINITION_GROUP_MUTATION, REORDER_DEFINITION_MUTATION, } from "@/store/types";
import Vue from "vue";
import editor from "@/api/editor";
// initial state
export const state = {
    current: {
        definitions: {},
        groups: [],
    },
    previous: {
        definitions: {},
        groups: [],
    },
    get: {
        pending: false,
        fetched: false,
    },
    error: null,
};
// getters
export const getters = {
    toSave: (state) => state,
    original: (state) => state.previous,
    definitions: (state) => state.current.definitions,
    groups: (state) => state.current.groups,
    error: (state) => state.error,
    definitionsToSave: (state) => state.current,
};
// actions
export const actions = {
    [GET_DRAFT_SURVEY_ACTION_DEFINITIONS]({ commit }) {
        if (!state.get.fetched) {
            commit(GET_DRAFT_SURVEY_ASYNC_DEFINITIONS.PENDING);
            editor
                .getSurvey("definitions")
                .then((res) => {
                commit(GET_DRAFT_SURVEY_ASYNC_DEFINITIONS.SUCCESS);
                commit(SET_DEFINITIONS, res);
            })
                .catch((err) => commit(GET_DRAFT_SURVEY_ASYNC_DEFINITIONS.FAILURE, err));
        }
    },
};
// mutations
export const mutations = {
    // get
    [GET_DRAFT_SURVEY_ASYNC_DEFINITIONS.PENDING](state) {
        state.get.pending = true;
    },
    [GET_DRAFT_SURVEY_ASYNC_DEFINITIONS.SUCCESS](state) {
        state.get.fetched = true;
        state.get.pending = false;
    },
    [GET_DRAFT_SURVEY_ASYNC_DEFINITIONS.FAILURE](state, error) {
        state.get.pending = false;
        state.error = error;
    },
    // set
    [SET_DEFINITIONS](state, definitions) {
        definitions = clean(definitions);
        state.previous = cloneDeep(definitions);
        state.current = definitions;
    },
    // add
    [ADD_DEFINITION_GROUP_MUTATION](state, groupName) {
        for (const group in state.current.groups) {
            if (state.current.groups[group].id === groupName) {
                state.error = `Group Name '${groupName}' already used`;
                return;
            }
        }
        state.error = null;
        const newGroup = {
            id: groupName,
            definitions: [],
        };
        if (!state.current.groups) {
            state.current.groups = [];
        }
        const groupLen = state.current.groups.length;
        Vue.set(state.current.groups, groupLen, newGroup);
    },
    [ADD_DEFINITION_MUTATION](state, data) {
        if (state.current.definitions[data.definition.id]) {
            state.error = `Definition Name '${data.definition.id}' already exists`;
            return;
        }
        const group = state.current.groups.find((v) => v.id === data.groupId);
        if (!group) {
            state.error = `No group "${data.groupId}" found`;
            return;
        }
        group.definitions.push(data.definition.id);
        Vue.set(state.current.definitions, data.definition.id, data.definition);
    },
    // edit
    [EDIT_DEFINITION_GROUP_MUTATION](state, data) {
        for (const group in state.current.groups) {
            if (state.current.groups[group].id === data.oldId) {
                state.current.groups[group].id = data.newId;
                return;
            }
        }
        state.error = `Group Name '${data.id}' does not exist`;
    },
    [EDIT_DEFINITION_MUTATION](state, data) {
        if (data.previousDefinitionId === data.definition.id) {
            state.current.definitions[data.definition.id].logic = data.definition.logic;
            return;
        }
        if (state.current.definitions[data.definition.id]) {
            state.error = `Definition Name '${data.definition.id}' already exists`;
            return;
        }
        Vue.delete(state.current.definitions, data.definition.id);
        state.current.definitions[data.definition.id] = data.definition;
        const group = state.current.groups.find((v) => v.id === data.groupId);
        if (!group) {
            state.error = `No group "${data.groupId}" found`;
            return;
        }
        const i = group.definitions.indexOf(data.previousDefinitionId);
        if (i === -1) {
            state.error = `No definition "${data.previousDefinitionId}" found`;
            return;
        }
        Vue.set(group.definitions, i, data.definition.id);
    },
    // delete
    [DELETE_DEFINITION_GROUP_MUTATION](state, groupId) {
        const groupIndex = state.current.groups.findIndex((v) => v.id === groupId);
        // delete all definitions referenced by the definition group.
        state.current.groups[groupIndex].definitions.forEach((definitionId) => this.commit(`editor/definitions/${DELETE_DEFINITION_MUTATION}`, { groupId, definitionId }));
        // delete the definition group.
        state.current.groups.splice(groupIndex, 1);
    },
    [DELETE_DEFINITION_MUTATION](state, { definitionId, groupId }) {
        Vue.delete(state.current.definitions, definitionId);
        const group = state.current.groups.find((v) => v.id === groupId);
        if (!group) {
            return;
        }
        group.definitions.splice(group.definitions.indexOf(definitionId), 1);
    },
    // reorder
    [REORDER_DEFINITION_GROUP_MUTATION](state, { from, to }) {
        state.current.groups = reorder(state.current.groups, from, to);
    },
    [REORDER_DEFINITION_MUTATION](state, { groupId, from, to }) {
        for (const groupIndex in state.current.groups) {
            if (state.current.groups[groupIndex].id === groupId) {
                state.current.groups[groupIndex].definitions = reorder(state.current.groups[groupIndex].definitions, from, to);
            }
        }
    },
};
const reorder = (arr, from, to) => {
    const movedItem = arr.find((item, index) => index === from);
    const remainingItems = arr.filter((item, index) => index !== from);
    const reorderedItems = [...remainingItems.slice(0, to), movedItem, ...remainingItems.slice(to)];
    return reorderedItems;
};
// TODO: can be removed after first persist
function clean(definitions) {
    const tidy = cloneDeep(definitions);
    tidy.groups = tidy.groups.filter((v) => v.id !== "NOT WORKING");
    const definitionIds = tidy.groups.reduce((r, v) => r.concat(v.definitions), []);
    for (const definitionId in definitions.definitions) {
        if (!definitionIds.includes(definitionId)) {
            delete definitions.definitions[definitionId];
        }
    }
    return tidy;
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

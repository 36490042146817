import Vue from "vue";
import { REORDER_DEFINITION_GROUP_MUTATION } from "@/store/types";
import LanceDefinitions from "../definition/Definitions.vue";
import LanceAdd from "./Add.vue";
import LanceEdit from "./Edit.vue";
export default Vue.extend({
    name: "LanceGroups",
    components: {
        LanceDefinitions,
        LanceAdd,
        LanceEdit,
    },
    data() {
        return {
            selected: null,
            action: "select",
        };
    },
    computed: {
        groups() {
            return this.$store.getters["editor/definitions/groups"];
        },
    },
    methods: {
        closeAddFunc() {
            this.selected = null;
        },
        select(group) {
            var _a;
            this.selected = ((_a = this.selected) === null || _a === void 0 ? void 0 : _a.id) === group.id ? null : group;
            this.action = "select";
        },
        edit(group) {
            this.selected = group;
            this.action = "edit";
        },
        add() {
            this.selected = {
                id: "",
                definitions: [],
            };
            this.action = "add";
        },
        onUpdate(e) {
            this.$store.dispatch(`editor/definitions/${REORDER_DEFINITION_GROUP_MUTATION}`, {
                from: e.oldIndex,
                to: e.newIndex,
            });
        },
    },
});

export default function commalist() {
    return [
        {
            type: "lang",
            regex: /%comma-list%([\s\S]*?)%comma-list%/gi,
            replace(s, match) {
                let list = "";
                const bullets = match.split("* ");
                for (let i = 0; i < bullets.length; ++i) {
                    if (bullets[i] != null && bullets[i].trim() !== "") {
                        if (i < bullets.length - 1) {
                            list += bullets[i].trim() + ", ";
                        }
                        else {
                            list += bullets[i].trim();
                        }
                    }
                }
                return list;
            },
        },
    ];
}

import Vue from "vue";
import { ADD_RULE_CATEGORY_MUTATION, EDIT_RULE_CATEGORY_MUTATION, DELETE_RULE_CATEGORY_MUTATION } from "@/store/types";
import LanceSubcategories from "./Subcategories.vue";
import { mapMutations } from "vuex";
import { cloneDeep } from "lodash";
export default Vue.extend({
    name: "RuleGategoryList",
    components: {
        LanceSubcategories,
    },
    props: {
        categories: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            selected: null,
            form: null,
        };
    },
    computed: {
        orderedCategories() {
            const categories = Object.values(this.categories)
                .map((value) => ({ text: value.name, value }))
                .sort((a, b) => a.text.localeCompare(b.text));
            return [
                { text: this.fetchingSurvey ? "Loading categories..." : "Please select category", value: null },
                ...categories,
            ];
        },
        fetchingSurvey() {
            return this.$store.getters["editor/rules/fetchingSurvey"];
        },
        subcategories() {
            return this.$store.getters["editor/rules/subcategoriesForCategory"](this.selected);
        },
    },
    watch: {
        selected() {
            if (this.form && this.selected) {
                this.form = cloneDeep(this.selected);
            }
        },
    },
    methods: Object.assign({ add() {
            this.selected = null;
            this.form = {
                id: "",
                name: "",
                heading: "",
                subcategories: [],
            };
        },
        edit() {
            if (!this.selected) {
                return;
            }
            this.form = cloneDeep(this.selected);
        },
        remove() {
            if (!this.selected) {
                return;
            }
            this.$store.commit(`editor/rules/${DELETE_RULE_CATEGORY_MUTATION}`, this.selected.id);
            this.selected = null;
            this.form = null;
        },
        save() {
            var _a;
            if ((_a = this.form) === null || _a === void 0 ? void 0 : _a.id) {
                this.doEdit(this.form);
            }
            else {
                this.doAdd(this.form);
            }
            this.selected = this.form;
            this.form = null;
        } }, mapMutations("editor/rules", {
        doAdd: ADD_RULE_CATEGORY_MUTATION,
        doEdit: EDIT_RULE_CATEGORY_MUTATION,
    })),
});

import { async } from "@/api/util";
function deleteCompletedReview(id) {
    return async({
        method: "delete",
        url: `/review/${id}`,
        auth: true,
    });
}
function deleteUncompletedReview(id) {
    return async({
        method: "delete",
        url: `/review/${id}/deletereview`,
        auth: true,
    });
}
function revert(id) {
    return async({
        method: "post",
        url: `/review/${id}/return`,
        auth: true,
    });
}
function start(data) {
    return async({
        method: "post",
        url: `/review/start`,
        data: data,
        auth: true,
    });
}
function submit(data) {
    return async({
        method: "post",
        url: `/review/${data.reviewId}/submit`,
        data,
        auth: true,
    });
}
function report(id) {
    return async({
        method: "get",
        url: `/review/${id}/report`,
        auth: true,
    });
}
function update(data) {
    return async({
        method: "post",
        url: `/review/${data.report.report_id}/report`,
        data: data,
        auth: true,
    });
}
function resetScore(reportId) {
    return async({
        method: "get",
        url: `/review/${reportId}/resetscore`,
        data: reportId,
        auth: true,
    });
}
function resubmit(reportId) {
    return async({
        method: "post",
        url: `/review/${reportId}/resubmit`,
        data: {
            reviewId: reportId,
        },
        auth: true,
    });
}
function getAnswerSummary(id) {
    return async({
        method: "get",
        url: `/review/${id}/answersummary`,
        data: id,
        auth: true,
    });
}
export default {
    deleteCompletedReview,
    deleteUncompletedReview,
    revert,
    start,
    submit,
    report,
    update,
    resetScore,
    resubmit,
    getAnswerSummary,
};

import Vue from "vue";
export default Vue.extend({
    name: "LanceMetrics",
    props: {
        customerData: {
            type: Object,
            required: true,
        },
        content: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            userFilter: "",
        };
    },
    computed: {
        filteredUsers() {
            var tmpArray = [];
            var user;
            if (this.userFilter === "ALL" || this.userFilter === "") {
                tmpArray = JSON.parse(JSON.stringify(this.customerData.users));
            }
            else {
                for (user in this.customerData.users) {
                    if (this.customerData.users[user].userName === this.userFilter) {
                        tmpArray.push(this.customerData.users[user]);
                    }
                }
            }
            tmpArray.sort((a, b) => {
                return b.reviewSubmitted - a.reviewSubmitted;
            });
            return tmpArray;
        },
    },
    methods: {
        select(event) {
            this.userFilter = event.target.value;
        },
        unixTimeToDate(ll) {
            if (ll <= 0) {
                return "-";
            }
            var date = new Date(ll * 1000);
            return date.toDateString();
        },
    },
});

import { __awaiter } from "tslib";
import { DefaultApi, createConfiguration, ServerConfiguration } from "./openapi";
import { Auth } from "@aws-amplify/auth";
function getToken() {
    return Auth.currentSession().then((v) => v.getIdToken().getJwtToken());
}
export function newApi(url) {
    const conf = createConfiguration({
        baseServer: new ServerConfiguration(url, {}),
        authMethods: {
            bearerAuth: {
                tokenProvider: {
                    getToken,
                },
            },
        },
    });
    return new DefaultApi(conf);
}
export const api = newApi(process.env.VUE_APP_API_ENDPOINT || "");
// getReviewReportPDF returns PDF document.
//
// Generated OpenAPI client does not support "custom" mime type.
export function getReviewReportPDF(id, opts) {
    return __awaiter(this, void 0, void 0, function* () {
        const token = yield getToken();
        const remediated = (opts === null || opts === void 0 ? void 0 : opts.remediated) || false;
        return fetch(`${process.env.VUE_APP_API_ENDPOINT}/review/${id}/report?remediated=${remediated}`, {
            headers: {
                authorization: `Bearer ${token}`,
                accept: "application/pdf",
            },
        });
    });
}

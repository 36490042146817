import { isEqual } from "lodash";
import { SAVE_DRAFT_SURVEY_ACTION, SAVE_DRAFT_SURVEY_ASYNC, PUBLISH_DRAFT_SURVEY_ACTION, PUBLISH_DRAFT_SURVEY_ASYNC, } from "@/store/types";
import rules from "@/store/modules/editor/rules";
import responses from "@/store/modules/editor/responses";
import questions from "@/store/modules/editor/questions";
import definitions from "@/store/modules/editor/definitions";
import editor from "@/api/editor";
// initial state
export const state = {
    save: {
        pending: false,
        fetched: false,
        error: null,
    },
    publish: {
        pending: false,
        fetched: false,
        error: null,
    },
};
export const getters = {
    saving: (state) => state.save.pending,
    errorSaving: (state) => state.save.error,
    publishing: (state) => state.publish.pending,
    errorPublishing: (state) => state.publish.error,
    savingSuccess: (state) => (state.save.fetched && !state.save.error ? "Success" : ""),
    publishSuccess: (state) => (state.publish.fetched && !state.publish.error ? "Success" : ""),
};
// actions
export const actions = {
    [SAVE_DRAFT_SURVEY_ACTION]({ getters, commit }) {
        commit(SAVE_DRAFT_SURVEY_ASYNC.PENDING);
        // split this into 4 requests one for each survey type and save individually if there has been a change.
        let madeChange = false;
        // Responses
        const resOriginal = getters["responses/original"];
        const responses = getters["responses/responses"];
        const isEqualRes = isEqual(resOriginal, responses);
        if (!isEqualRes) {
            madeChange = true;
            editor
                .saveSurvey(responses, "responses")
                .then(() => commit(SAVE_DRAFT_SURVEY_ASYNC.SUCCESS))
                .catch((err) => commit(SAVE_DRAFT_SURVEY_ASYNC.FAILURE, err));
        }
        const rulesOriginal = getters["rules/original"];
        const rules = getters["rules/rules"];
        const isEqualRules = isEqual(rulesOriginal, rules);
        if (!isEqualRules) {
            madeChange = true;
            editor
                .saveSurvey(rules, "rules")
                .then(() => commit(SAVE_DRAFT_SURVEY_ASYNC.SUCCESS))
                .catch((err) => commit(SAVE_DRAFT_SURVEY_ASYNC.FAILURE, err));
        }
        const questionsOriginal = getters["questions/original"];
        const questions = getters["questions/questions"];
        const isEqualQuestions = isEqual(questionsOriginal, questions);
        if (!isEqualQuestions) {
            madeChange = true;
            editor
                .saveSurvey(questions, "questions")
                .then(() => commit(SAVE_DRAFT_SURVEY_ASYNC.SUCCESS))
                .catch((err) => commit(SAVE_DRAFT_SURVEY_ASYNC.FAILURE, err));
        }
        const definitionsOriginal = getters["definitions/original"];
        const definitions = getters["definitions/definitionsToSave"];
        const isEqualDefinitions = isEqual(definitionsOriginal, definitions);
        if (!isEqualDefinitions) {
            madeChange = true;
            const lowerCaseDef = {};
            Object.keys(definitions.definitions).map((key) => {
                definitions.definitions[key].id = key.toLowerCase();
                lowerCaseDef[key.toLowerCase()] = definitions.definitions[key];
            });
            definitions.definitions = lowerCaseDef;
            definitions.groups.map((group) => {
                group.definitions = group.definitions.map((def) => def.toLowerCase());
            });
            editor
                .saveSurvey(definitions, "definitions")
                .then(() => commit(SAVE_DRAFT_SURVEY_ASYNC.SUCCESS))
                .catch((err) => commit(SAVE_DRAFT_SURVEY_ASYNC.FAILURE, err));
        }
        if (!madeChange) {
            commit(SAVE_DRAFT_SURVEY_ASYNC.FAILURE, "No changes to save");
        }
    },
    [PUBLISH_DRAFT_SURVEY_ACTION]({ commit }) {
        commit(PUBLISH_DRAFT_SURVEY_ASYNC.PENDING);
        editor
            .publishSurvey()
            .then(() => commit(PUBLISH_DRAFT_SURVEY_ASYNC.SUCCESS))
            .catch((err) => commit(PUBLISH_DRAFT_SURVEY_ASYNC.FAILURE, err));
    },
};
export const mutations = {
    [SAVE_DRAFT_SURVEY_ASYNC.PENDING](state) {
        state.save.pending = true;
        state.save.fetched = false;
        state.publish.fetched = false;
    },
    [SAVE_DRAFT_SURVEY_ASYNC.SUCCESS](state) {
        state.save.fetched = true;
        state.save.pending = false;
    },
    [SAVE_DRAFT_SURVEY_ASYNC.FAILURE](state, error) {
        state.save.pending = false;
        state.save.error = error;
    },
    [PUBLISH_DRAFT_SURVEY_ASYNC.PENDING](state) {
        state.publish.pending = true;
        state.save.fetched = false;
        state.publish.fetched = false;
    },
    [PUBLISH_DRAFT_SURVEY_ASYNC.SUCCESS](state) {
        state.publish.fetched = true;
        state.publish.pending = false;
    },
    [PUBLISH_DRAFT_SURVEY_ASYNC.FAILURE](state, error) {
        state.publish.pending = false;
        state.publish.error = error;
    },
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    modules: {
        rules,
        responses,
        questions,
        definitions,
    },
};

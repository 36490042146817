import Vue from "vue";
export default Vue.extend({
    name: "LanceHeading",
    props: {
        weight: {
            type: String,
            required: true,
        },
        heading: {
            type: String,
            required: true,
        },
        // Not sure what's this is for, but to retain existing behaviour (applies css style), keep it. Only applies to H3.
        group: {
            type: Boolean,
            default: false,
        },
    },
});

import report from "@/api/report";
import { getReviewReportPDF } from "@/api";
import { GET_USER_REPORTS_ASYNC, GET_USER_REPORTS_ACTION, DOWNLOAD_REPORT_ACTION, DOWNLOAD_REPORT_ASYNC, } from "@/store/types";
import { sortedUniq, uniq } from "lodash";
// initial state
export const state = {
    report: null,
    userReports: null,
    getReports: {
        pending: false,
        error: null,
    },
    downloadReports: {
        pending: false,
        error: null,
    },
};
// getters
export const getters = {
    report: (state) => state.report,
    downloading: (state) => state.downloadReports.pending,
    userReports: (state) => state.userReports,
    liabilities: (state) => {
        var _a;
        if (!((_a = state.report) === null || _a === void 0 ? void 0 : _a.table)) {
            return [];
        }
        const liabilities = [];
        const report = state.report;
        Object.keys(report.table).forEach((typ) => {
            Object.keys(report.table[typ].entities).forEach((entity) => {
                Object.keys(report.table[typ].entities[entity]).forEach((key) => {
                    liabilities.push(key);
                });
            });
        });
        return sortedUniq(uniq(liabilities));
    },
};
// actions
export const actions = {
    [GET_USER_REPORTS_ACTION]({ commit }) {
        commit(GET_USER_REPORTS_ASYNC.PENDING);
        report
            .getUserReports()
            .then((res) => commit(GET_USER_REPORTS_ASYNC.SUCCESS, res))
            .catch((err) => commit(GET_USER_REPORTS_ASYNC.FAILURE, err));
    },
    [DOWNLOAD_REPORT_ACTION]({ commit }, { id, remediated, structure }) {
        commit(DOWNLOAD_REPORT_ASYNC.PENDING);
        getReviewReportPDF(id, { remediated })
            .then((res) => {
            if (!res.ok) {
                throw new Error(`failed to download PDF document: status code: ${res.status}`);
            }
            return res.blob();
        })
            .then((pdf) => commit(DOWNLOAD_REPORT_ASYNC.SUCCESS, {
            pdf,
            filename: remediated
                ? `LANCE Remediated Report - ${structure}.pdf`
                : `LANCE Original Report - ${structure}.pdf`,
        }))
            .catch((err) => commit(DOWNLOAD_REPORT_ASYNC.FAILURE, err));
    },
};
// mutations
export const mutations = {
    [GET_USER_REPORTS_ASYNC.PENDING](state) {
        state.getReports.pending = true;
    },
    [GET_USER_REPORTS_ASYNC.SUCCESS](state, res) {
        state.userReports = res;
        state.getReports.pending = false;
    },
    [GET_USER_REPORTS_ASYNC.FAILURE](state, error) {
        state.getReports.pending = false;
        state.getReports.error = error;
    },
    [DOWNLOAD_REPORT_ASYNC.PENDING](state) {
        state.downloadReports.pending = true;
    },
    [DOWNLOAD_REPORT_ASYNC.SUCCESS](state, { pdf, filename }) {
        state.downloadReports.pending = false;
        const url = window.URL.createObjectURL(pdf);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    },
    [DOWNLOAD_REPORT_ASYNC.FAILURE](state, error) {
        state.downloadReports.pending = false;
        state.downloadReports.error = error;
    },
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

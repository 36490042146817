import Vue from "vue";
export default Vue.extend({
    name: "LanceSurveyError",
    props: {
        error: {
            type: Object,
            required: true,
        },
    },
});

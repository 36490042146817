import { async as asyncFn } from "@/api/util";
import Storage from "@aws-amplify/storage";
import { v4 as uuid } from "uuid";
function getSurvey(rng) {
    return asyncFn({
        method: "get",
        url: `/editor/draft/${rng}`,
        auth: true,
    });
}
function saveSurvey(bundle, rng) {
    // 90% of Lambda payload size limit
    const lambdaSizeLimit = 6 * 1024 * 1024 * 0.8;
    const data = JSON.stringify(bundle);
    if (data.length < lambdaSizeLimit) {
        return asyncFn({
            method: "post",
            url: `/editor/${rng}/save`,
            auth: true,
            data,
        });
    }
    return Storage.put(`${uuid()}.json`, new Blob([data]), {
        customPrefix: { public: "" },
    }).then(({ key }) => asyncFn({
        method: "post",
        url: `/editor/${rng}/save?requestS3Key=${encodeURIComponent(key)}`,
        auth: true,
    }));
}
function publishSurvey() {
    return asyncFn({
        method: "post",
        url: `/editor/publish`,
        auth: true,
    });
}
export default {
    getSurvey,
    saveSurvey,
    publishSurvey,
};

import Vue from "vue";
import { DELETE_UNCOMPLETED_REVIEW_ACTION } from "@/store/types";
import { mapGetters } from "vuex";
export default Vue.extend({
    name: "LanceReportsList",
    props: {
        reportsMetaData: {
            required: true,
        },
        compact: {
            type: Boolean,
            required: true,
        },
        content: {
            required: true,
        },
        max: {
            // limit number of reports to show
            type: Number,
            default: 1000,
        },
    },
    data() {
        return {
            searchTerm: "",
            sortedReviews: [],
            completedType: "all",
            showReviewType: "company wide",
            completedTypes: ["all", "complete", "incomplete"],
            showReviewsTypes: ["company wide", "my own"],
        };
    },
    computed: Object.assign(Object.assign({}, mapGetters("auth", ["isAccountLead"])), { filteredReports() {
            const search = this.searchTerm.toLowerCase();
            let sortedReviews = JSON.parse(JSON.stringify(this.sortedReviews));
            let filteredReviews = [];
            switch (this.showReviewType) {
                case "company wide":
                    filteredReviews = sortedReviews;
                    break;
                case "mine":
                    filteredReviews = sortedReviews.filter((data) => data.is_own);
                    break;
            }
            switch (this.completedType) {
                case "all":
                    break;
                case "complete":
                    filteredReviews = filteredReviews.filter((data) => data.report);
                    break;
                case "incomplete":
                    filteredReviews = filteredReviews.filter((data) => !data.report);
                    break;
            }
            if (search !== "") {
                filteredReviews = filteredReviews.filter((data) => data.report
                    ? data.structure_name.toLowerCase().includes(search) ||
                        data.created_by.toLowerCase().includes(search) ||
                        data.risk_score.toString().includes(search)
                    : data.created_by.toLowerCase().includes(search) || data.structure_name.toLowerCase().includes(search));
            }
            return filteredReviews.slice(0, this.max);
        } }),
    watch: {
        reportsMetaData() {
            this.combineAndOrderData();
        },
    },
    methods: {
        deleteReview(id) {
            if (confirm("Are you sure you want to delete this review?\nThis action cannot be undone.")) {
                this.$store.dispatch(`review/${DELETE_UNCOMPLETED_REVIEW_ACTION}`, id).then(() => {
                    this.sortedReviews = this.sortedReviews.filter((data) => data.answer_id !== id);
                });
            }
        },
        combineAndOrderData() {
            var objCopy = JSON.parse(JSON.stringify(this.reportsMetaData));
            if (objCopy["ReportMetaDataList"]) {
                for (let i = 0; i < objCopy["ReportMetaDataList"].length; i++) {
                    objCopy["ReportMetaDataList"][i].time = new Date(objCopy["ReportMetaDataList"][i].submitted * 1000).toDateString();
                    objCopy["ReportMetaDataList"][i].timestamp = objCopy["ReportMetaDataList"][i].submitted;
                    objCopy["ReportMetaDataList"][i].report = 1;
                }
            }
            else {
                objCopy["ReportMetaDataList"] = [];
            }
            if (objCopy["SurveyAnswersMetaDataList"]) {
                for (let i = 0; i < objCopy["SurveyAnswersMetaDataList"].length; i++) {
                    objCopy["SurveyAnswersMetaDataList"][i].time = new Date(objCopy["SurveyAnswersMetaDataList"][i].last_modified * 1000).toDateString();
                    objCopy["SurveyAnswersMetaDataList"][i].timestamp = objCopy["SurveyAnswersMetaDataList"][i].last_modified;
                    objCopy["SurveyAnswersMetaDataList"][i].report = 0;
                }
            }
            else {
                objCopy["SurveyAnswersMetaDataList"] = [];
            }
            var combinedArray = objCopy["ReportMetaDataList"].concat(objCopy["SurveyAnswersMetaDataList"]);
            combinedArray.sort((a, b) => b.timestamp - a.timestamp);
            this.sortedReviews = combinedArray;
        },
    },
});

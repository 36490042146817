import * as SurveyVue from "survey-vue";
import Vue from "vue";
import Showdown from "showdown";
import { mapGetters } from "vuex";
SurveyVue.StylesManager.applyTheme("bootstrap");
SurveyVue.defaultBootstrapCss.navigationButton = "btn btn-primary";
SurveyVue.defaultBootstrapCss.matrixdynamic.button = "btn btn-primary";
SurveyVue.defaultBootstrapCss.matrixdynamic.buttonAdd = "btn btn-primary";
SurveyVue.defaultBootstrapCss.matrixdynamic.buttonRemove = "btn btn-primary";
SurveyVue.defaultBootstrapCss.checkbox.root = "";
SurveyVue.defaultBootstrapCss.checkbox.item = "lance-item";
SurveyVue.defaultBootstrapCss.checkbox.materialDecorator = "checkbox";
SurveyVue.defaultBootstrapCss.radiogroup.root = "";
SurveyVue.defaultBootstrapCss.radiogroup.item = "lance-item";
SurveyVue.defaultBootstrapCss.radiogroup.materialDecorator = "radio";
SurveyVue.defaultBootstrapCss.paneldynamic.button = "btn btn-primary";
SurveyVue.defaultBootstrapCss.paneldynamic.buttonPrev = "btn btn-primary";
SurveyVue.defaultBootstrapCss.paneldynamic.buttonPrev = "btn btn-primary";
SurveyVue.defaultBootstrapCss.paneldynamic.buttonAdd = "btn btn-primary";
SurveyVue.defaultBootstrapCss.paneldynamic.buttonRemove = "btn btn-primary";
// TODO: css does not exist on SurveyVue
SurveyVue.css = {
    matrix: {
        root: "table table-bordered",
    },
};
const converter = new Showdown.Converter();
export default Vue.extend({
    name: "LanceSurveyJS",
    components: {
    // SurveyVue,
    },
    props: {
        survey: {
            type: Object,
            required: true,
        },
        answers: {
            required: true,
        },
        onSubmit: {
            type: Function,
            required: true,
        },
        onBack: {
            type: Function,
            required: true,
        },
        fromBack: {
            required: true,
        },
        structureName: {
            required: true,
        },
        showBackButton: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            surveyModel: null,
            titles: [],
            title: "",
        };
    },
    computed: Object.assign(Object.assign({}, mapGetters({
        _progress: "review/progress",
    })), { progress() {
            return this._progress;
        },
        showBackButtonFunc() {
            var _a, _b;
            return (this.showBackButton || (((_a = this.surveyModel) === null || _a === void 0 ? void 0 : _a.currentPageNo) !== undefined && ((_b = this.surveyModel) === null || _b === void 0 ? void 0 : _b.currentPageNo) > 0));
        },
        progressStyleBarWidth() {
            return this.title === ""
                ? 0
                : Math.floor(((this.progress.current + this.survey.pages.findIndex((v) => v.name === this.title)) * 100) /
                    this.progress.total);
        },
        progressStyleBarStyle() {
            return `width: ${this.progressStyleBarWidth}%`;
        } }),
    watch: {
        survey() {
            this.create();
        },
    },
    created() {
        this.create();
    },
    methods: {
        create() {
            var _a, _b;
            SurveyVue.FunctionFactory.Instance.register("all", (params) => {
                if ((params === null || params === void 0 ? void 0 : params.length) < 2) {
                    return false;
                }
                var matrixValue = params[0];
                var value = params[1];
                for (var key in matrixValue) {
                    if (matrixValue[key] === value) {
                        continue;
                    }
                    return false;
                }
                return true;
            });
            for (let page in this.survey.pages) {
                for (let element in this.survey.pages[page].elements) {
                    // TODO: title does not exist
                    let title = this.survey.pages[page].elements[element].title;
                    if (title.includes("##li")) {
                        let splits = title.split("##li");
                        let newTitle = `${splits[0]}<ul>`;
                        for (let j = 1; j < splits.length; j++) {
                            newTitle += `<li>${splits[j].trim()}</li>`;
                        }
                        newTitle += "</ul>";
                        title = newTitle;
                    }
                    this.titles.push(title);
                }
            }
            this.surveyModel = new SurveyVue.Model(this.survey);
            if (this.surveyModel.state === "empty") {
                if (this.fromBack) {
                    this.onBack();
                }
                else {
                    // TODO: properties are required according to Survey TS
                    this.onSubmit({});
                }
            }
            if (this.fromBack) {
                this.surveyModel.currentPageNo = this.survey.pages.length - 1;
            }
            this.surveyModel.data = this.answers;
            this.surveyModel.showNavigationButtons = false;
            this.surveyModel.onComplete.add(this.onSubmit);
            this.surveyModel.clearInvisibleValues = "onHidden";
            this.$parent.$off();
            this.$parent.$on("nextPage", this.nextPage);
            this.$parent.$on("backPage", this.backPage);
            this.surveyModel.onTextMarkdown.add(function (survey, options) {
                // convert the mardown text to html
                var str = converter.makeHtml(options.text);
                // remove root paragraphs <p></p>
                str = str.replace("<p>", "");
                str = str.replace("</p>", "");
                // set html
                options.html = str;
            });
            this.title = ((_b = (_a = this.surveyModel) === null || _a === void 0 ? void 0 : _a.currentPage) === null || _b === void 0 ? void 0 : _b.toString()) || "";
        },
        nextPage() {
            var _a, _b, _c;
            if (!((_a = this.surveyModel) === null || _a === void 0 ? void 0 : _a.nextPage())) {
                (_b = this.surveyModel) === null || _b === void 0 ? void 0 : _b.completeLastPage();
            }
            this.title = (_c = this.surveyModel) === null || _c === void 0 ? void 0 : _c.currentPage.toString();
        },
        backPage() {
            var _a, _b, _c, _d;
            if (((_a = this.surveyModel) === null || _a === void 0 ? void 0 : _a.currentPageNo) !== undefined && ((_b = this.surveyModel) === null || _b === void 0 ? void 0 : _b.currentPageNo) > 0) {
                this.surveyModel.currentPageNo = this.surveyModel.currentPageNo - 1;
            }
            else {
                this.onBack((_c = this.surveyModel) === null || _c === void 0 ? void 0 : _c.data);
            }
            this.title = (_d = this.surveyModel) === null || _d === void 0 ? void 0 : _d.currentPage.toString();
        },
    },
});

import Vue from "vue";
import checkLogic from "@/lib/logic";
import { mapMutations } from "vuex";
import { UPDATE_QUESTIONS_MUTATION } from "@/store/types";
import * as SurveyCreator from "survey-creator";
import addRemoveProperties from "./add_remove_propoties";
import "survey-creator/survey-creator.css";
import { debounce } from "lodash";
export default Vue.extend({
    name: "LanceSurveyJSEditor",
    props: {
        survey: {
            required: true,
        },
        type: {
            required: true,
        },
        subtype: {
            required: true,
        },
    },
    mounted() {
        var _a;
        addRemoveProperties();
        const editorOptions = {
            showTestSurveyTab: true,
            showEmbededSurveyTab: true,
        };
        const editor = new SurveyCreator.SurveyCreator("surveyCreatorContainer", editorOptions);
        const checkLogicFunc = debounce(function (target) {
            checkLogic(target);
        });
        (_a = SurveyCreator.SurveyQuestionEditorDefinition.definition["itemvalue[]@choices"].properties) === null || _a === void 0 ? void 0 : _a.unshift("customLogic");
        editor.onPropertyEditorKeyDown.add(function (editor, options) {
            if (options.propertyName === "customLogic" || options.propertyName === "title") {
                checkLogicFunc(options.event.target);
            }
        });
        editor.haveCommercialLicense = true;
        editor.isAutoSave = true;
        editor.text = JSON.stringify(this.survey);
        editor.saveSurveyFunc = () => {
            this.saveSurvey({
                type: this.type,
                subtype: this.subtype,
                questions: JSON.parse(editor.text),
            });
        };
    },
    methods: Object.assign({}, mapMutations("editor/questions", {
        saveSurvey: UPDATE_QUESTIONS_MUTATION,
    })),
});

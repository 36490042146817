import { async } from "@/api/util";
export default {
    login(username, password) {
        return async({
            method: "post",
            url: `/auth/login`,
            auth: false,
            data: {
                username: username,
                password: password,
            },
        });
    },
    refresh() {
        return async({
            method: "post",
            url: `/auth/refresh`,
            auth: true,
            data: {
                refresh_token: localStorage.getItem("refresh"),
            },
        });
    },
    setUserEmailVerified(username) {
        return async({
            method: "post",
            url: `/user/verify`,
            data: {
                userName: username,
            },
            auth: true,
        });
    },
    setTermsAgreed(username, optIn) {
        return async({
            method: "post",
            url: `/user/termsagreed`,
            data: {
                userName: username,
                terms_agreed: "yes",
                trial_follow_up: optIn,
            },
            auth: true,
        });
    },
};

import Vue from "vue";
import LanceAttachments from "@/components/misc/Attachments.vue";
import { lang } from "@/conf";
export default Vue.extend({
    name: "LanceSupport",
    components: { LanceAttachments },
    data() {
        return {
            content: {
                title: [],
                contact: {
                    title: [],
                    instruction: [],
                },
                information: {
                    title: [],
                    instruction: [],
                    attachments: [],
                },
            },
        };
    },
    created() {
        this.getContent();
    },
    methods: {
        getContent() {
            this.$prismic.client.getSingle("support", { lang }).then((response) => {
                if (!response)
                    return;
                let result = response.data;
                this.content.title = result.title;
                this.content.contact.title = result.contact_title;
                this.content.contact.instruction = result.contact_instruction;
                this.content.information.title = result.information_title;
                this.content.information.instruction = result.information_instruction;
                this.content.information.attachments = result.information_attachments;
            });
        },
    },
});

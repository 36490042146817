import Vue from "vue";
import { ADD_RULE_SUBCATEGORY_MUTATION, EDIT_RULE_SUBCATEGORY_MUTATION, DELETE_RULE_SUBCATEGORY_MUTATION, } from "@/store/types";
import LanceGroups from "@/components/editor/rule/Groups.vue";
import { mapMutations } from "vuex";
import checkLogic from "@/lib/logic";
import { cloneDeep, debounce } from "lodash";
export default Vue.extend({
    name: "RuleSubcategoryList",
    components: {
        LanceGroups,
    },
    props: {
        subcategories: {
            type: Array,
            required: true,
        },
        categoryId: {
            type: String,
            required: true,
        },
        categories: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            selected: null,
            form: null,
        };
    },
    computed: {
        orderedSubcategories() {
            return [
                { text: "Please select subcategory", value: null },
                ...this.subcategories
                    .map((value) => ({ text: value.name, value }))
                    .sort((a, b) => a.text.localeCompare(b.text)),
            ];
        },
        categoriesOptions() {
            return [
                { text: "Please select category", value: "" },
                ...Object.values(this.categories)
                    .map((v) => ({ text: v.name, value: v.id }))
                    .sort((a, b) => a.text.localeCompare(b.text)),
            ];
        },
        subcategoriesOptions() {
            return [
                { text: "Please select subcategory", value: "" },
                ...this.$store.getters["editor/rules/subcategoriesForCategory"](this.form ? this.categories[this.form.transformedCatId] : null)
                    .map((v) => ({ text: v.name, value: v.id }))
                    .sort((a, b) => a.text.localeCompare(b.text)),
            ];
        },
        groups() {
            return this.selected !== null ? this.$store.getters["editor/rules/groupsForSubcategory"](this.selected) : [];
        },
    },
    watch: {
        categoryId() {
            this.selected = null;
            this.form = null;
        },
        "form.isTransformed"(v) {
            if (!v && this.form) {
                this.form.transformedCatId = "";
                this.form.transformedSubCatId = "";
            }
        },
    },
    methods: Object.assign({ checkLogic: debounce(function (e) {
            checkLogic(e.target);
        }, 500), add() {
            this.selected = null;
            this.form = {
                id: "",
                name: "",
                heading: "",
                isLooped: false,
                isTransformed: false,
                loopKey: "",
                transformedSubCatId: "",
                transformedCatId: "",
                groups: [],
            };
        },
        edit() {
            if (!this.selected) {
                return;
            }
            this.form = cloneDeep(this.selected);
        },
        remove() {
            if (!this.selected) {
                return;
            }
            this.$store.commit(`editor/rules/${DELETE_RULE_SUBCATEGORY_MUTATION}`, {
                categoryId: this.categoryId,
                subcategoryId: this.selected.id,
            });
            this.selected = null;
            this.form = null;
        },
        save() {
            var _a;
            if ((_a = this.form) === null || _a === void 0 ? void 0 : _a.id) {
                this.doEdit(this.form);
            }
            else {
                this.doAdd({
                    categoryId: this.categoryId,
                    subcategory: this.form,
                });
            }
            this.selected = this.form;
            this.form = null;
        } }, mapMutations("editor/rules", {
        doAdd: ADD_RULE_SUBCATEGORY_MUTATION,
        doEdit: EDIT_RULE_SUBCATEGORY_MUTATION,
    })),
});

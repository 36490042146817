import Vue from "vue";
import { ADD_DEFINITION_MUTATION } from "@/store/types";
export default Vue.extend({
    name: "LanceAdd",
    props: {
        groupId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            form: {
                id: "",
                logic: "",
            },
        };
    },
    computed: {
        definitionIds() {
            return Object.values(this.$store.getters["editor/definitions/definitions"]).map((v) => v.id);
        },
        valid() {
            return this.form.id ? !this.definitionIds.includes(this.form.id) : null;
        },
    },
    methods: {
        save() {
            this.$store.commit(`editor/definitions/${ADD_DEFINITION_MUTATION}`, {
                groupId: this.groupId,
                definition: this.form,
            });
            this.$emit("closeAdd");
        },
        cancel() {
            this.$emit("closeAdd");
        },
    },
});

import Vue from "vue";
import { mapGetters } from "vuex";
import { GET_USER_ACTION, UPDATE_USER_ACTION, DELETE_USER_ACTION } from "@/store/types";
export default Vue.extend({
    name: "UserDetail",
    data() {
        return {
            validationErrors: [],
            localState: null,
        };
    },
    computed: Object.assign(Object.assign({}, mapGetters("users", ["user", "updateUser", "deleteUser"])), mapGetters("auth", ["isAdmin"])),
    watch: {
        user: "setLocalState",
    },
    created() {
        this.$store.dispatch(`users/${GET_USER_ACTION}`, this.$route.params.id);
    },
    methods: {
        deleteDialog() {
            if (confirm("Are you sure you want to delete this user?\nThis action cannot be undone.")) {
                this.$store.dispatch(`users/${DELETE_USER_ACTION}`, this.localState.user_id);
                this.$router.push("/users");
            }
        },
        checkForm() {
            this.validationErrors = [];
            if (!this.localState.user_name) {
                this.validationErrors.push("Please enter a user name");
            }
            if (!this.localState.company_name) {
                this.validationErrors.push("Please enter a company name");
            }
            if (!this.localState.review_cap) {
                this.validationErrors.push("Please enter a number of allowed reviews");
            }
            if (!this.validationErrors.length)
                return true;
        },
        save() {
            if (!this.checkForm())
                return;
            this.$store.dispatch(`users/${UPDATE_USER_ACTION}`, this.localState);
        },
        setLocalState(value) {
            this.localState = Object.assign({}, value);
            this.localState.terms_agreed = this.localState.terms_agreed === "yes";
        },
    },
});

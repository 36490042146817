import { async } from "@/api/util";
function getReport(reportId) {
    return async({
        method: "get",
        url: `/survey/report/${reportId}`,
        auth: true,
    });
}
function getUserReports() {
    return async({
        method: "get",
        url: `/user/reports`,
        auth: true,
    });
}
export default {
    getReport,
    getUserReports,
};

import Vue from "vue";
import { REORDER_DEFINITION_MUTATION } from "@/store/types";
import LanceAdd from "./Add.vue";
import LanceEdit from "./Edit.vue";
import { mapMutations } from "vuex";
export default Vue.extend({
    name: "LanceDefinitions",
    components: {
        LanceAdd,
        LanceEdit,
    },
    props: {
        group: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            selected: null,
            isEdit: false,
            isAdd: false,
        };
    },
    computed: {
        mapDefinitions() {
            const definitions = this.$store.getters["editor/definitions/definitions"];
            return this.group ? this.group.definitions.map((v) => definitions[v]) : [];
        },
    },
    methods: Object.assign(Object.assign({}, mapMutations("editor/definitions", {
        doReorder: REORDER_DEFINITION_MUTATION,
    })), { closeAddFunc() {
            this.selected = null;
            this.isEdit = false;
            this.isAdd = false;
        },
        select(definition) {
            var _a;
            if (((_a = this.selected) === null || _a === void 0 ? void 0 : _a.id) === definition.id) {
                this.selected = null;
                this.isEdit = false;
                this.isAdd = false;
            }
            else {
                this.selected = definition;
                this.isEdit = false;
                this.isAdd = false;
            }
        },
        add() {
            this.selected = null;
            this.isEdit = false;
            this.isAdd = true;
        },
        edit(definition) {
            this.selected = definition;
            this.isEdit = true;
            this.isAdd = false;
        },
        onUpdate(e) {
            this.doReorder({
                groupId: this.group,
                from: e.oldIndex,
                to: e.newIndex,
            });
        } }),
});

import Vue from "vue";
import { ADD_RESPONSE_CATEGORY_MUTATION, UPDATE_RESPONSE_CATEGORY_MUTATION, DELETE_RESPONSE_CATEGORY_MUTATION, SET_RESPONSE_ERROR_MUTATION, } from "@/store/types";
import LanceGroups from "./Groups.vue";
import { cloneDeep } from "lodash";
export default Vue.extend({
    name: "ResponseGategoryList",
    components: {
        LanceGroups,
    },
    props: {
        categories: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            selected: null,
            form: null,
        };
    },
    computed: {
        fetchingSurvey() {
            return this.$store.getters["editor/responses/fetchingSurvey"];
        },
        options() {
            return [
                {
                    value: null,
                    text: this.fetchingSurvey ? "Loading categories..." : "Please select category",
                },
                ...Object.values(this.categories).map((value) => ({ value, text: value.name })),
            ];
        },
        groups() {
            return this.$store.getters["editor/responses/groupsForCategory"](this.selected);
        },
        error() {
            return this.$store.getters[`editor/responses/error`];
        },
    },
    methods: {
        add() {
            this.selected = null;
            this.form = {
                id: "",
                name: "",
                heading: "",
                groups: [],
                order: 0,
            };
        },
        edit() {
            if (this.selected) {
                this.form = cloneDeep(this.selected);
            }
        },
        cancel() {
            this.form = null;
        },
        removeError() {
            this.$store.commit(`editor/responses/${SET_RESPONSE_ERROR_MUTATION}`, null);
        },
        saveCategory(category) {
            if (category.id) {
                this.$store.commit(`editor/responses/${UPDATE_RESPONSE_CATEGORY_MUTATION}`, category);
            }
            else {
                this.$store.commit(`editor/responses/${ADD_RESPONSE_CATEGORY_MUTATION}`, category);
            }
            this.selected = this.form; // for b-select v-model
            this.form = null;
        },
        removeCategory(categoryId) {
            this.$store.commit(`editor/responses/${DELETE_RESPONSE_CATEGORY_MUTATION}`, categoryId);
            this.selected = null;
            this.form = null;
        },
    },
});

import Vue from "vue";
import { mapGetters } from "vuex";
import { CREATE_USER_ACTION } from "@/store/types";
export default Vue.extend({
    name: "UserCreate",
    data() {
        return {
            validationErrors: [],
            user_name: "",
            email: "",
            company_name: "",
            review_cap: "-1",
            terms_agreed: false,
            is_trial_user: false,
        };
    },
    computed: Object.assign(Object.assign({}, mapGetters("users", ["createUser"])), mapGetters("auth", ["isAdmin"])),
    methods: {
        checkForm() {
            this.validationErrors = [];
            if (!this.user_name) {
                this.validationErrors.push("Please enter a user name");
            }
            if (this.isAdmin && !this.company_name) {
                this.validationErrors.push("Please enter a company name");
            }
            if (this.isAdmin && !this.review_cap) {
                this.validationErrors.push("Please enter a number of allowed reviews");
            }
            if (!this.email || !this.validEmail(this.email)) {
                this.validationErrors.push("Please enter an email address");
            }
            if (!this.validationErrors.length)
                return true;
        },
        save() {
            if (!this.checkForm())
                return;
            const data = {
                name: this.user_name,
                email: this.email,
                company: this.company_name,
                review_cap: this.review_cap,
                is_trial_user: this.is_trial_user,
                terms_agreed: this.terms_agreed ? "yes" : "no",
            };
            this.$store.dispatch(`users/${CREATE_USER_ACTION}`, data);
        },
        validEmail(email) {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; /* eslint-disable-line */
            return re.test(email);
        },
    },
});

import Vue from "vue";
import { mapMutations } from "vuex";
import { EDIT_DEFINITION_MUTATION, DELETE_DEFINITION_MUTATION } from "@/store/types";
import checkLogic from "@/lib/logic";
import { cloneDeep, debounce } from "lodash";
export default Vue.extend({
    name: "LanceEdit",
    props: {
        groupId: {
            type: String,
            default: "",
            required: true,
        },
        definition: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            form: {
                id: "",
                logic: "",
            },
        };
    },
    computed: {
        definitionIds() {
            return Object.values(this.$store.getters["editor/definitions/definitions"])
                .filter((v) => v.id !== this.definition.id)
                .map((v) => v.id);
        },
        valid() {
            return this.form.id !== "" && !this.definitionIds.includes(this.form.id);
        },
    },
    watch: {
        definition(v) {
            this.form = cloneDeep(v);
        },
    },
    created() {
        this.form = cloneDeep(this.definition);
    },
    methods: Object.assign(Object.assign({}, mapMutations("editor/definitions", {
        deleteDef: DELETE_DEFINITION_MUTATION,
    })), { save() {
            this.$store.commit(`editor/definitions/${EDIT_DEFINITION_MUTATION}`, {
                groupId: this.groupId,
                previousDefinitionId: this.definition.id,
                definition: this.form,
            });
            this.$emit("closeEdit");
        },
        cancel() {
            this.$emit("closeEdit");
        },
        remove() {
            this.deleteDef({
                definitionId: this.definition.id,
                groupId: this.groupId,
            });
            this.$emit("closeEdit");
        }, checkLogic: debounce(function (e) {
            checkLogic(e.target);
        }, 500) }),
});

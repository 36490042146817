export const createAsyncMutations = (type) => ({
    SUCCESS: `${type}_SUCCESS`,
    FAILURE: `${type}_FAILURE`,
    PENDING: `${type}_PENDING`,
});
/*
 *  ASYNC MUTATIONS
 */
// AUTH
export const LOGIN_ASYNC = createAsyncMutations("LOGIN_ASYNC");
export const REFRESH_ASYNC = createAsyncMutations("REFRESH_ASYNC");
// SURVEY
export const GET_QUESTIONS_ASYNC = createAsyncMutations("GET_QUESTIONS_ASYNC");
export const SUBMIT_ANSWER_ASYNC = createAsyncMutations("SUBMIT_ANSWER_ASYNC");
// EDITOR
export const GET_SURVEY_ASYNC = createAsyncMutations("GET_SURVEY_ASYNC");
export const GET_DRAFT_SURVEY_ASYNC_RESPONSE = createAsyncMutations("GET_DRAFT_SURVEY_ASYNC_RESPONSE");
export const GET_DRAFT_SURVEY_ASYNC_RULES = createAsyncMutations("GET_DRAFT_SURVEY_ASYNC_RULES");
export const GET_DRAFT_SURVEY_ASYNC_QUESTIONS = createAsyncMutations("GET_DRAFT_SURVEY_ASYNC_QUESTIONS");
export const GET_DRAFT_SURVEY_ASYNC_DEFINITIONS = createAsyncMutations("GET_DRAFT_SURVEY_ASYNC_DEFINITIONS");
export const SAVE_DRAFT_SURVEY_ASYNC = createAsyncMutations("SAVE_DRAFT_SURVEY_ASYNC");
export const PUBLISH_DRAFT_SURVEY_ASYNC = createAsyncMutations("PUBLISH_DRAFT_SURVEY_ASYNC");
// REPORT
export const GET_REPORT_ASYNC = createAsyncMutations("GET_REPORT_ASYNC");
export const GET_USER_REPORTS_ASYNC = createAsyncMutations("GET_USER_REPORTS_ASYNC");
export const PUBLISH_SURVEY_ASYNC = createAsyncMutations("PUBLISH_SURVEY_ASYNC");
export const SAVE_SURVEY_ASYNC = createAsyncMutations("SAVE_SURVEY_ASYNC");
export const DELETE_COMPLETED_REVIEW_ASYNC = createAsyncMutations("DELETE_COMPLETED_REVIEW_ASYNC");
export const DELETE_UNCOMPLETED_REVIEW_ASYNC = createAsyncMutations("DELETE_UNCOMPLETED_REVIEW_ASYNC");
export const REVERT_REVIEW_ASYNC = createAsyncMutations("REVERT_REVIEW_ASYNC");
export const START_REVIEW_ASYNC = createAsyncMutations("START_REVIEW_ASYNC");
export const NEXT_QUESTIONS_ASYNC = createAsyncMutations("NEXT_QUESTIONS_ASYNC");
export const SUBMIT_REVIEW_ASYNC = createAsyncMutations("SUBMIT_REVIEW_ASYNC");
export const UPDATE_REPORT_ASYNC = createAsyncMutations("UPDATE_REPORT_ASYNC");
export const RESET_REPORT_SCORE_ASYNC = createAsyncMutations("RESET_REPORT_SCORE_ASYNC");
export const RESUBMIT_REPORT_ASYNC = createAsyncMutations("RESUBMIT_REPORT_ASYNC");
export const UPDATE_REMEDIATION_ISSUE_ASYNC = createAsyncMutations("UPDATE_REMEDIATION_ISSUE_ASYNC");
export const GET_ANSWER_SUMMARY_ASYNC = createAsyncMutations("GET_ANSWER_SUMMARY_ASYNC");
export const DOWNLOAD_REPORT_ASYNC = createAsyncMutations("DOWNLOAD_REPORT_ASYNC");
// CUSTOMER METRICS
export const GET_CUSTOMER_METRICS_ASYNC = createAsyncMutations("GET_CUSTOMER_METRICS_ASYNC");
// USER LIST
export const GET_USER_LIST_ASYNC = createAsyncMutations("GET_USER_LIST_ASYNC");
export const GET_USER_ASYNC = createAsyncMutations("GET_USER_ASYNC");
export const GET_USER_REVIEWS_ASYNC = createAsyncMutations("GET_USER_REVIEWS_ASYNC");
export const CREATE_USER_ASYNC = createAsyncMutations("CREATE_USER_ASYNC");
export const UPDATE_USER_ASYNC = createAsyncMutations("UPDATE_USER_ASYNC");
export const DELETE_USER_ASYNC = createAsyncMutations("DELETE_USER_ASYNC");
export const DELETE_CUSTOMER_ASYNC = createAsyncMutations("DELETE_CUSTOMER_ASYNC");
/*
 *  ACTIONS
 */
export const SAVE_SURVEY_ACTION = "SAVE_SURVEY_ACTION";
export const PUBLISH_SURVEY_ACTION = "PUBLISH_SURVEY_ACTION";
// AUTH
export const LOGIN_ACTION = "LOGIN_ACTION";
export const REFRESH_ACCESS_TOKEN_ACTION = "REFRESH_ACCESS_TOKEN_ACTION";
// SURVEY
export const GET_QUESTIONS_ACTION = "GET_QUESTIONS_ACTION";
export const SUBMIT_ANSWERS_ACTION = "SUBMIT_ANSWERS_ACTION";
// EDITOR
export const GET_SURVEY_ACTION = "GET_SURVEY_ACTION";
export const SAVE_DRAFT_SURVEY_ACTION = "SAVE_DRAFT_SURVEY_ACTION";
export const PUBLISH_DRAFT_SURVEY_ACTION = "PUBLISH_DRAFT_SURVEY_ACTION";
export const GET_DRAFT_SURVEY_ACTION_RESPONSE = "GET_DRAFT_SURVEY_ACTION_RESPONSE";
export const GET_DRAFT_SURVEY_ACTION_RULES = "GET_DRAFT_SURVEY_ACTION_RULES";
export const GET_DRAFT_SURVEY_ACTION_QUESTIONS = "GET_DRAFT_SURVEY_ACTION_QUESTIONS";
export const GET_DRAFT_SURVEY_ACTION_DEFINITIONS = "GET_DRAFT_SURVEY_ACTION_DEFINITIONS";
// REPORT
export const GET_REPORT_ACTION = "GET_REPORT_ACTION";
export const GET_USER_REPORTS_ACTION = "GET_USER_REPORTS_ACTION";
export const DELETE_COMPLETED_REVIEW_ACTION = "DELETE_COMPLETED_REVIEW_ACTION";
export const DELETE_UNCOMPLETED_REVIEW_ACTION = "DELETE_UNCOMPLETED_REVIEW_ACTION";
export const REVERT_REVIEW_ACTION = "REVERT_REVIEW_ACTION";
export const START_REVIEW_ACTION = "START_REVIEW_ACTION";
export const NEXT_QUESTIONS_ACTION = "NEXT_QUESTIONS_ACTION";
export const SUBMIT_REVIEW_ACTION = "SUBMIT_REVIEW_ACTION";
export const UPDATE_REPORT_ACTION = "UPDATE_REPORT_ACTION";
export const RESET_REPORT_SCORE_ACTION = "RESET_REPORT_SCORE_ACTION";
export const RESUBMIT_REPORT_ACTION = "RESUBMIT_REPORT_ACTION";
export const UPDATE_REMEDIATION_ISSUE_ACTION = "UPDATE_REMEDIATION_ISSUE_ACTION";
export const GET_ANSWER_SUMMARY_ACTION = "GET_ANSWER_SUMMARY_ACTION";
export const DOWNLOAD_REPORT_ACTION = "DOWNLOAD_REPORT_ACTION";
// CUSTOMER METRICS
export const GET_CUSTOMER_METRICS_ACTION = "GET_CUSTOMER_METRICS_ACTION";
// USER LIST
export const GET_USER_LIST_ACTION = "GET_USER_LIST_ACTION";
export const GET_USER_REVIEWS_ACTION = "GET_USER_REVIEWS_ACTION";
export const GET_USER_ACTION = "GET_USER_ACTION";
export const CREATE_USER_ACTION = "CREATE_USER_ACTION";
export const UPDATE_USER_ACTION = "UPDATE_USER_ACTION";
export const DELETE_USER_ACTION = "DELETE_USER_ACTION";
export const DELETE_CUSTOMER_ACTION = "DELETE_CUSTOMER_ACTION";
/*
 *  MUTATIONS
 */
// AUTH
export const UPDATE_LOGIN_RESULT_MUTATION = "UPDATE_LOGIN_RESULT_MUTATION";
export const UPDATE_REFRESH_RESULT_MUTATION = "UPDATE_REFRESH_RESULT_MUTATION";
export const LOGOUT_MUTATION = "LOGOUT_MUTATION";
// SURVEY
export const UPDATE_SURVEY_MUTATION = "UPDATE_SURVEY_MUTATION";
export const UPDATE_SUBMIT_SURVEY_ANSWER_MUTATION = "UPDATE_SUBMIT_SURVEY_ANSWER_MUTATION";
export const UPDATE_PUBLISH_SURVEY_ANSWER_MUTATION = "UPDATE_PUBLISH_SURVEY_ANSWER_MUTATION";
// EDITOR
export const UPDATE_QUESTIONS_MUTATION = "UPDATE_QUESTIONS_MUTATION";
export const UPDATE_ANSWER_KEYS_MUTATION = "UPDATE_ANSWER_KEYS_MUTATION";
// NEW KEEP
export const SET_RESPONSE_ERROR_MUTATION = "SET_RESPONSE_ERROR_MUTATION";
export const ADD_RULE_CATEGORY_MUTATION = "ADD_RULE_CATEGORY_MUTATION";
export const ADD_RULE_SUBCATEGORY_MUTATION = "ADD_RULE_SUBCATEGORY_MUTATION";
export const ADD_RULE_GROUP_MUTATION = "ADD_RULE_GROUP_MUTATION";
export const ADD_RULE_SUBGROUP_MUTATION = "ADD_RULE_SUBGROUP_MUTATION";
export const ADD_RULE_MUTATION = "ADD_RULE_MUTATION";
export const EDIT_RULE_CATEGORY_MUTATION = "EDIT_RULE_CATEGORY_MUTATION";
export const EDIT_RULE_SUBCATEGORY_MUTATION = "EDIT_RULE_SUBCATEGORY_MUTATION";
export const EDIT_RULE_GROUP_MUTATION = "EDIT_RULE_GROUP_MUTATION";
export const EDIT_RULE_SUBGROUP_MUTATION = "EDIT_RULE_SUBGROUP_MUTATION";
export const EDIT_RULE_MUTATION = "EDIT_RULE_MUTATION";
export const DELETE_RULE_CATEGORY_MUTATION = "DELETE_RULE_CATEGORY_MUTATION";
export const DELETE_RULE_SUBCATEGORY_MUTATION = "DELETE_RULE_SUBCATEGORY_MUTATION";
export const DELETE_RULE_GROUP_MUTATION = "DELETE_RULE_GROUP_MUTATION";
export const DELETE_RULE_SUBGROUP_MUTATION = "DELETE_RULE_SUBGROUP_MUTATION";
export const DELETE_RULE_MUTATION = "DELETE_RULE_MUTATION";
export const REORDER_RULE_GROUP_MUTATION = "REORDER_RULE_GROUP_MUTATION";
export const REORDER_RULE_SUBGROUP_MUTATION = "REORDER_RULE_SUBGROUP_MUTATION";
export const REORDER_RULE_MUTATION = "REORDER_RULE_MUTATION";
export const ADD_RESPONSE_CATEGORY_MUTATION = "ADD_RESPONSE_CATEGORY_MUTATION";
export const ADD_RESPONSE_GROUP_MUTATION = "ADD_RESPONSE_GROUP_MUTATION";
export const ADD_RESPONSE_SUBGROUP_MUTATION = "ADD_RESPONSE_SUBGROUP_MUTATION";
export const ADD_RESPONSE_MUTATION = "ADD_RESPONSE_MUTATION";
export const UPDATE_RESPONSE_CATEGORY_MUTATION = "UPDATE_RESPONSE_CATEGORY_MUTATION";
export const EDIT_RESPONSE_GROUP_MUTATION = "EDIT_RESPONSE_GROUP_MUTATION";
export const EDIT_RESPONSE_SUBGROUP_MUTATION = "EDIT_RESPONSE_SUBGROUP_MUTATION";
export const EDIT_RESPONSE_MUTATION = "EDIT_RESPONSE_MUTATION";
export const DELETE_RESPONSE_CATEGORY_MUTATION = "DELETE_RESPONSE_CATEGORY_MUTATION";
export const DELETE_RESPONSE_GROUP_MUTATION = "DELETE_RESPONSE_GROUP_MUTATION";
export const DELETE_RESPONSE_SUBGROUP_MUTATION = "DELETE_RESPONSE_SUBGROUP_MUTATION";
export const DELETE_RESPONSE_MUTATION = "DELETE_RESPONSE_MUTATION";
export const REORDER_RESPONSE_GROUP_MUTATION = "REORDER_RESPONSE_GROUP_MUTATION";
export const REORDER_RESPONSE_SUBGROUP_MUTATION = "REORDER_RESPONSE_SUBGROUP_MUTATION";
export const REORDER_RESPONSE_MUTATION = "REORDER_RESPONSE_MUTATION";
export const ADD_DEFINITION_GROUP_MUTATION = "ADD_DEFINITION_GROUP_MUTATION";
export const EDIT_DEFINITION_GROUP_MUTATION = "EDIT_DEFINITION_GROUP_MUTATION";
export const DELETE_DEFINITION_GROUP_MUTATION = "DELETE_DEFINITION_GROUP_MUTATION";
export const ADD_DEFINITION_MUTATION = "ADD_DEFINITION_MUTATION";
export const EDIT_DEFINITION_MUTATION = "EDIT_DEFINITION_MUTATION";
export const DELETE_DEFINITION_MUTATION = "DELETE_DEFINITION_MUTATION";
export const REORDER_DEFINITION_GROUP_MUTATION = "REORDER_DEFINITION_GROUP_MUTATION";
export const REORDER_DEFINITION_MUTATION = "REORDER_DEFINITION_MUTATION";
export const SET_RULES = "SET_RULES";
export const SET_QUESTIONS = "SET_QUESTIONS";
export const SET_RESPONSES = "SET_RESPONSES";
export const SET_DEFINITIONS = "SET_DEFINITIONS";
export const RESET_ENTITIES = "RESET_ENTITIES";
// USER LIST
export const UPDATE_USER_FILTER_MUTATION = "UPDATE_USER_FILTER_MUTATION";
// STRUCTURE
export const STRUCTURE_ENTITY = {
    TRUST: "trust",
    FOUNDATION: "foundation",
    COMPANY: "company",
    SETTLOR: "settlor",
    FOUNDER: "founder",
    SETTLEMENT: "settlement",
    CONTRIBUTION: "contribution",
    SHAREHOLDER: "shareholder",
    SHAREHOLDER_DATES: "shareholderdates",
    NOMINEE: "nominee",
};
// DECEASED
export const DECEASED = {
    YES: "yes",
    NO: "no",
};

import { __awaiter } from "tslib";
import { cloneDeep } from "lodash";
import { SET_RULES, GET_DRAFT_SURVEY_ACTION_RULES, GET_DRAFT_SURVEY_ASYNC_RULES, ADD_RULE_CATEGORY_MUTATION, ADD_RULE_SUBCATEGORY_MUTATION, ADD_RULE_GROUP_MUTATION, ADD_RULE_SUBGROUP_MUTATION, ADD_RULE_MUTATION, EDIT_RULE_CATEGORY_MUTATION, EDIT_RULE_SUBCATEGORY_MUTATION, EDIT_RULE_GROUP_MUTATION, EDIT_RULE_SUBGROUP_MUTATION, EDIT_RULE_MUTATION, DELETE_RULE_CATEGORY_MUTATION, DELETE_RULE_SUBCATEGORY_MUTATION, DELETE_RULE_GROUP_MUTATION, DELETE_RULE_SUBGROUP_MUTATION, DELETE_RULE_MUTATION, REORDER_RULE_GROUP_MUTATION, REORDER_RULE_SUBGROUP_MUTATION, REORDER_RULE_MUTATION, } from "@/store/types";
import Vue from "vue";
import { v4 as uuidv4 } from "uuid";
import { api } from "@/api";
const reorder = (arr, from, to) => {
    const movedItem = arr.find((item, index) => index === from);
    if (!movedItem) {
        return arr;
    }
    const remainingItems = arr.filter((item, index) => index !== from);
    return [...remainingItems.slice(0, to), movedItem, ...remainingItems.slice(to)];
};
// initial state
export const state = {
    original: {
        categories: {},
        subcategories: {},
        groups: {},
        subgroups: {},
        rules: {},
    },
    categories: {},
    subcategories: {},
    groups: {},
    subgroups: {},
    rules: {},
    get: {
        pending: false,
        error: null,
        fetched: false,
    },
};
// getters
export const getters = {
    categories: (state) => state.categories,
    subcategoriesForCategory: (state) => (category) => { var _a; return ((_a = category === null || category === void 0 ? void 0 : category.subcategories) === null || _a === void 0 ? void 0 : _a.map((subcategoryId) => (Object.assign({}, state.subcategories[subcategoryId])))) || []; },
    subcategories: (state) => state.subcategories,
    groupsForSubcategory: (state) => (subcategory) => { var _a; return ((_a = subcategory === null || subcategory === void 0 ? void 0 : subcategory.groups) === null || _a === void 0 ? void 0 : _a.map((group) => (Object.assign({}, state.groups[group])))) || []; },
    groups: (state) => state.groups,
    subgroupsForGroup: (state) => (group) => { var _a; return ((_a = group === null || group === void 0 ? void 0 : group.subgroups) === null || _a === void 0 ? void 0 : _a.map((subgroup) => (Object.assign({}, state.subgroups[subgroup])))) || []; },
    subgroups: (state) => state.subgroups,
    rulesForSubgroup: (state) => (subgroup) => { var _a; return ((_a = subgroup === null || subgroup === void 0 ? void 0 : subgroup.rules) === null || _a === void 0 ? void 0 : _a.map((rule) => (Object.assign({}, state.rules[rule])))) || []; },
    fetchingSurvey: (state) => state.get.pending,
    rules: (state) => ({
        categories: state.categories,
        subcategories: state.subcategories,
        groups: state.groups,
        subgroups: state.subgroups,
        rules: state.rules,
    }),
    original: (state) => state.original,
};
// actions
export const actions = {
    [GET_DRAFT_SURVEY_ACTION_RULES]({ commit }) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (state.get.fetched) {
                    return;
                }
                commit(GET_DRAFT_SURVEY_ASYNC_RULES.PENDING);
                const rules = yield api.getSurveyRules("draft");
                commit(GET_DRAFT_SURVEY_ASYNC_RULES.SUCCESS);
                commit(SET_RULES, rules);
            }
            catch (err) {
                commit(GET_DRAFT_SURVEY_ASYNC_RULES.FAILURE, err);
            }
        });
    },
};
// mutations
export const mutations = {
    [GET_DRAFT_SURVEY_ASYNC_RULES.PENDING](state) {
        state.get.pending = true;
    },
    [GET_DRAFT_SURVEY_ASYNC_RULES.SUCCESS](state) {
        state.get.fetched = true;
        state.get.pending = false;
    },
    [GET_DRAFT_SURVEY_ASYNC_RULES.FAILURE](state, error) {
        state.get.pending = false;
        state.get.error = error;
    },
    [SET_RULES](state, rules) {
        rules = clean(rules);
        state.original = cloneDeep(rules);
        state.categories = rules.categories;
        state.subcategories = rules.subcategories;
        state.groups = rules.groups;
        state.subgroups = rules.subgroups;
        state.rules = rules.rules;
    },
    // category
    [ADD_RULE_CATEGORY_MUTATION](state, category) {
        const id = uuidv4();
        category.id = id;
        Vue.set(category, "subcategories", []);
        if (!state.categories) {
            Vue.set(state, "categories", {});
        }
        Vue.set(state.categories, id, category);
    },
    [EDIT_RULE_CATEGORY_MUTATION](state, category) {
        state.categories[category.id] = category;
    },
    [DELETE_RULE_CATEGORY_MUTATION](state, categoryId) {
        // delete all rule subcategories referenced by rule category
        [...state.categories[categoryId].subcategories].forEach((subcategoryId) => this.commit(`editor/rules/${DELETE_RULE_SUBCATEGORY_MUTATION}`, { categoryId, subcategoryId }));
        // delete rule category
        Vue.delete(state.categories, categoryId);
    },
    // subcategory
    [ADD_RULE_SUBCATEGORY_MUTATION](state, { categoryId, subcategory }) {
        subcategory.id = uuidv4();
        const subcategories = state.categories[categoryId].subcategories;
        if (subcategories) {
            subcategories.push(subcategory.id);
        }
        else {
            Vue.set(state.categories[categoryId], "subcategories", [subcategory.id]);
        }
        Vue.set(state.subcategories, subcategory.id, subcategory);
    },
    [EDIT_RULE_SUBCATEGORY_MUTATION](state, subcategory) {
        state.subcategories[subcategory.id] = subcategory;
    },
    [DELETE_RULE_SUBCATEGORY_MUTATION](state, { categoryId, subcategoryId }) {
        // delete all rule groups referenced by rule subcategory
        [...state.subcategories[subcategoryId].groups].forEach((groupId) => this.commit(`editor/rules/${DELETE_RULE_GROUP_MUTATION}`, { subcategoryId, groupId }));
        // delete rule subcategory
        const subcategoryIds = state.categories[categoryId].subcategories;
        subcategoryIds.splice(subcategoryIds.indexOf(subcategoryId), 1);
        Vue.delete(state.subcategories, subcategoryId);
    },
    // group
    [ADD_RULE_GROUP_MUTATION](state, { subcategoryId, group }) {
        group.id = uuidv4();
        Vue.set(group, "subgroups", []);
        if (!state.subcategories[subcategoryId].groups) {
            Vue.set(state.subcategories[subcategoryId], "groups", []);
        }
        state.subcategories[subcategoryId].groups.push(group.id);
        if (!state.groups) {
            Vue.set(state, "groups", {});
        }
        Vue.set(state.groups, group.id, group);
    },
    [EDIT_RULE_GROUP_MUTATION](state, group) {
        state.groups[group.id] = group;
    },
    [DELETE_RULE_GROUP_MUTATION](state, { subcategoryId, groupId }) {
        // delete all rule subgroups referenced by rule group
        [...state.groups[groupId].subgroups].forEach((subgroupId) => this.commit(`editor/rules/${DELETE_RULE_SUBGROUP_MUTATION}`, {
            groupId,
            subgroupId,
        }));
        // delete rule group
        const groupIds = state.subcategories[subcategoryId].groups;
        groupIds.splice(groupIds.indexOf(groupId), 1);
        Vue.delete(state.groups, groupId);
    },
    [REORDER_RULE_GROUP_MUTATION](state, { subcategoryId, from, to }) {
        state.subcategories[subcategoryId].groups = reorder(state.subcategories[subcategoryId].groups, from, to);
    },
    // subgroup
    [ADD_RULE_SUBGROUP_MUTATION](state, { groupId, subgroup }) {
        subgroup.id = uuidv4();
        const subgroups = state.groups[groupId].subgroups;
        if (subgroups) {
            subgroups.push(subgroup.id);
        }
        else {
            Vue.set(state.groups[groupId], "subgroups", [subgroup.id]);
        }
        if (!state.subgroups) {
            Vue.set(state, "subgroups", {});
        }
        Vue.set(state.subgroups, subgroup.id, subgroup);
    },
    [EDIT_RULE_SUBGROUP_MUTATION](state, subgroup) {
        state.subgroups[subgroup.id] = subgroup;
    },
    [DELETE_RULE_SUBGROUP_MUTATION](state, { groupId, subgroupId }) {
        // delete all rules referenced by rule subgroup
        [...state.subgroups[subgroupId].rules].forEach((ruleId) => this.commit(`editor/rules/${DELETE_RULE_MUTATION}`, { subgroupId, ruleId }));
        // delete rule subgroup
        const subgroupIds = state.groups[groupId].subgroups;
        subgroupIds.splice(subgroupIds.indexOf(subgroupId), 1);
        Vue.delete(state.subgroups, subgroupId);
    },
    [REORDER_RULE_SUBGROUP_MUTATION](state, { groupId, from, to }) {
        state.groups[groupId].subgroups = reorder(state.groups[groupId].subgroups || [], from, to);
    },
    // rule
    [ADD_RULE_MUTATION](state, { subgroupId, rule }) {
        rule.id = uuidv4();
        if (!state.subgroups[subgroupId].rules) {
            Vue.set(state.subgroups[subgroupId], "rules", []);
        }
        state.subgroups[subgroupId].rules.push(rule.id);
        if (!state.rules) {
            Vue.set(state, "rules", {});
        }
        Vue.set(state.rules, rule.id, rule);
    },
    [EDIT_RULE_MUTATION](state, rule) {
        state.rules[rule.id] = rule;
    },
    [DELETE_RULE_MUTATION](state, { subgroupId, ruleId }) {
        const ruleIds = state.subgroups[subgroupId].rules;
        ruleIds.splice(ruleIds.indexOf(ruleId), 1);
        Vue.delete(state.rules, ruleId);
    },
    [REORDER_RULE_MUTATION](state, { subgroupId, from, to }) {
        state.subgroups[subgroupId].rules = reorder(state.subgroups[subgroupId].rules, from, to);
    },
};
// TODO: can be remove after the first persist.
function clean(rules) {
    const tidy = cloneDeep(rules);
    const subcategoryIds = Object.values(rules.categories).reduce((r, v) => r.concat(v.subcategories), []);
    for (const subcategoryId in tidy.subcategories) {
        if (!subcategoryIds.includes(subcategoryId)) {
            delete tidy.subcategories[subcategoryId];
        }
    }
    const groupIds = Object.values(rules.subcategories).reduce((r, v) => r.concat(v.groups), []);
    for (const groupId in tidy.subcategories) {
        if (!groupIds.includes(groupId)) {
            delete tidy.groups[groupId];
        }
    }
    const subgroupIds = Object.values(rules.groups).reduce((r, v) => r.concat(v.subgroups), []);
    for (const subgroupId in tidy.subgroups) {
        if (!subgroupIds.includes(subgroupId)) {
            delete tidy.subgroups[subgroupId];
        }
    }
    const ruleIds = Object.values(rules.subgroups).reduce((r, v) => r.concat(v.rules), []);
    for (const ruleId in tidy.rules) {
        if (!ruleIds.includes(ruleId)) {
            delete tidy.rules[ruleId];
        }
    }
    return tidy;
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

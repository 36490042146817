export default function indent1() {
    return [
        {
            type: "output",
            regex: /%indent-1%([\s\S]*?)%indent-1%/gi,
            replace(s, match) {
                return '<div style="margin-left: 40px;">' + match + "</div>";
            },
        },
    ];
}

import Vue from "vue";
import { ADD_RULE_GROUP_MUTATION, EDIT_RULE_GROUP_MUTATION, DELETE_RULE_GROUP_MUTATION, REORDER_RULE_GROUP_MUTATION, } from "@/store/types";
import LanceSubgroups from "@/components/editor/rule/Subgroups.vue";
import { mapMutations, mapGetters } from "vuex";
import { cloneDeep } from "lodash";
export default Vue.extend({
    name: "RuleGroupList",
    components: {
        LanceSubgroups,
    },
    props: {
        groups: {
            type: Array,
            required: true,
        },
        subcategoryId: {
            type: String,
            required: true,
        },
        transformedSubCatId: {
            type: String,
            required: true,
        },
        isTransformed: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            selected: null,
            form: null,
        };
    },
    computed: Object.assign(Object.assign({}, mapGetters("editor/rules", ["subgroupsForGroup", "groupsForSubcategory", "subcategories"])), { subgroups() {
            return this.selected !== null ? this.subgroupsForGroup(this.selected) : [];
        },
        subgroupsDropDown() {
            let subCats = this.subcategories;
            return subCats[this.transformedSubCatId] !== null
                ? this.groupsForSubcategory(subCats[this.transformedSubCatId])
                : null;
        } }),
    watch: {
        subcategoryId() {
            this.selected = null;
            this.form = null;
        },
    },
    methods: Object.assign(Object.assign({}, mapMutations("editor/rules", {
        doAdd: ADD_RULE_GROUP_MUTATION,
        doEdit: EDIT_RULE_GROUP_MUTATION,
        doReorder: REORDER_RULE_GROUP_MUTATION,
    })), { onUpdate(e) {
            this.doReorder({
                subcategoryId: this.subcategoryId,
                from: e.oldIndex,
                to: e.newIndex,
            });
        },
        select(group) {
            var _a;
            this.selected = ((_a = this.selected) === null || _a === void 0 ? void 0 : _a.id) == group.id ? null : group;
            this.form = null;
        },
        add() {
            this.selected = null;
            this.form = {
                id: "",
                name: "",
                transformedGroupId: "",
                heading: "",
                subgroups: [],
            };
        },
        edit(group) {
            this.form = cloneDeep(group);
            this.selected = group;
        },
        remove() {
            if (!this.selected) {
                return;
            }
            this.$store.commit(`editor/rules/${DELETE_RULE_GROUP_MUTATION}`, {
                subcategoryId: this.subcategoryId,
                groupId: this.selected.id,
            });
            this.selected = null;
            this.form = null;
        },
        save() {
            var _a;
            if ((_a = this.form) === null || _a === void 0 ? void 0 : _a.id) {
                this.doEdit(this.form);
            }
            else {
                this.doAdd({
                    subcategoryId: this.subcategoryId,
                    group: this.form,
                });
            }
            this.selected = this.form;
            this.form = null;
        } }),
});

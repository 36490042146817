import { __awaiter } from "tslib";
import { cloneDeep } from "lodash";
import { SET_RESPONSES, GET_DRAFT_SURVEY_ACTION_RESPONSE, GET_DRAFT_SURVEY_ASYNC_RESPONSE, ADD_RESPONSE_CATEGORY_MUTATION, ADD_RESPONSE_GROUP_MUTATION, ADD_RESPONSE_SUBGROUP_MUTATION, ADD_RESPONSE_MUTATION, UPDATE_RESPONSE_CATEGORY_MUTATION, EDIT_RESPONSE_GROUP_MUTATION, EDIT_RESPONSE_SUBGROUP_MUTATION, EDIT_RESPONSE_MUTATION, DELETE_RESPONSE_CATEGORY_MUTATION, DELETE_RESPONSE_GROUP_MUTATION, DELETE_RESPONSE_SUBGROUP_MUTATION, DELETE_RESPONSE_MUTATION, REORDER_RESPONSE_GROUP_MUTATION, REORDER_RESPONSE_SUBGROUP_MUTATION, REORDER_RESPONSE_MUTATION, SET_RESPONSE_ERROR_MUTATION, } from "@/store/types";
import Vue from "vue";
import { v4 as uuidv4 } from "uuid";
import { api } from "@/api";
// initial state
export const state = {
    previous: {
        categories: {},
        groups: {},
        subgroups: {},
        responses: {},
    },
    current: {
        categories: {},
        groups: {},
        subgroups: {},
        responses: {},
    },
    get: {
        pending: false,
        error: null,
        fetched: false,
    },
    error: null,
};
// getters
export const getters = {
    categories: (state) => state.current.categories,
    groupsForCategory: (state) => (category) => { var _a; return ((_a = category === null || category === void 0 ? void 0 : category.groups) === null || _a === void 0 ? void 0 : _a.map((id) => (Object.assign({}, state.current.groups[id])))) || []; },
    groups: (state) => state.current.groups,
    subgroupsForGroup: (state) => (group) => { var _a; return ((_a = group === null || group === void 0 ? void 0 : group.subgroups) === null || _a === void 0 ? void 0 : _a.map((id) => (Object.assign({}, state.current.subgroups[id])))) || []; },
    subgroups: (state) => state.current.subgroups,
    responsesForSubgroup: (state) => (subgroup) => (subgroup === null || subgroup === void 0 ? void 0 : subgroup.responses.map((id) => (Object.assign({}, state.current.responses[id])))) || [],
    fetchingSurvey: (state) => state.get.pending,
    responses: (state) => state.current,
    original: (state) => state.previous,
    error: (state) => state.error,
};
export const actions = {
    [GET_DRAFT_SURVEY_ACTION_RESPONSE]({ commit }) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (state.get.fetched) {
                    return;
                }
                commit(GET_DRAFT_SURVEY_ASYNC_RESPONSE.PENDING);
                const responses = yield api.getSurveyResponses("draft");
                commit(GET_DRAFT_SURVEY_ASYNC_RESPONSE.SUCCESS);
                commit(SET_RESPONSES, responses);
            }
            catch (err) {
                commit(GET_DRAFT_SURVEY_ASYNC_RESPONSE.FAILURE, err);
            }
        });
    },
};
// TODO: can be removed after first persist
function clean(responses) {
    const tidy = cloneDeep(responses);
    // tidy groups
    const groupIds = Object.values(responses.categories).reduce((r, v) => r.concat(v.groups), []);
    for (const groupId in responses.groups) {
        if (!groupIds.includes(groupId)) {
            delete tidy.groups[groupId];
        }
    }
    // tidy subgroups
    const subgroupIds = Object.values(responses.groups).reduce((r, v) => r.concat(v.subgroups), []);
    for (const subgroupId in responses.subgroups) {
        if (!subgroupIds.includes(subgroupId)) {
            delete tidy.subgroups[subgroupId];
        }
    }
    // tidy responses
    const responseIds = Object.values(responses.subgroups).reduce((r, v) => r.concat(v.responses), []);
    for (const responseId in responses.responses) {
        if (!responseIds.includes(responseId)) {
            delete tidy.responses[responseId];
        }
    }
    return tidy;
}
// mutations
export const mutations = {
    // ASYNC
    [GET_DRAFT_SURVEY_ASYNC_RESPONSE.PENDING](state) {
        state.get.pending = true;
    },
    [GET_DRAFT_SURVEY_ASYNC_RESPONSE.SUCCESS](state) {
        state.get.fetched = true;
        state.get.pending = false;
    },
    [GET_DRAFT_SURVEY_ASYNC_RESPONSE.FAILURE](state, error) {
        state.get.pending = false;
        state.get.error = error;
    },
    [SET_RESPONSES](state, responses) {
        responses = clean(responses);
        state.previous = responses;
        state.current = cloneDeep(responses);
    },
    // category
    [ADD_RESPONSE_CATEGORY_MUTATION](state, category) {
        category.id = uuidv4();
        Vue.set(state.current.categories, category.id, category);
    },
    [UPDATE_RESPONSE_CATEGORY_MUTATION](state, category) {
        state.current.categories[category.id] = category;
    },
    [DELETE_RESPONSE_CATEGORY_MUTATION](state, categoryId) {
        // delete all groups referenced by category
        [...state.current.categories[categoryId].groups].forEach((groupId) => this.commit(`editor/responses/${DELETE_RESPONSE_GROUP_MUTATION}`, { categoryId, groupId }));
        Vue.delete(state.current.categories, categoryId);
    },
    // group
    [ADD_RESPONSE_GROUP_MUTATION](state, { categoryId, group }) {
        group.id = uuidv4();
        if (!state.current.categories[categoryId].groups) {
            Vue.set(state.current.categories[categoryId], "groups", []);
        }
        state.current.categories[categoryId].groups.push(group.id);
        if (!state.current.groups) {
            Vue.set(state, "groups", {});
        }
        Vue.set(state.current.groups, group.id, group);
    },
    [EDIT_RESPONSE_GROUP_MUTATION](state, group) {
        state.current.groups[group.id] = group;
    },
    [DELETE_RESPONSE_GROUP_MUTATION](state, { categoryId, groupId }) {
        // delete all subgroups referenced by group
        [...state.current.groups[groupId].subgroups].forEach((subgroupId) => this.commit(`editor/responses/${DELETE_RESPONSE_SUBGROUP_MUTATION}`, { groupId, subgroupId }));
        // delete group
        const groupIds = state.current.categories[categoryId].groups;
        groupIds.splice(groupIds.indexOf(groupId), 1);
        Vue.delete(state.current.groups, groupId);
    },
    [REORDER_RESPONSE_GROUP_MUTATION](state, { categoryId, from, to }) {
        state.current.categories[categoryId].groups = reorder(state.current.categories[categoryId].groups, from, to);
    },
    // subgroup
    [ADD_RESPONSE_SUBGROUP_MUTATION](state, { groupId, subgroup }) {
        var _a;
        subgroup.id = uuidv4();
        Vue.set(subgroup, "responses", []);
        if (!state.current.groups[groupId].subgroups) {
            Vue.set(state.current.groups[groupId], "subgroups", []);
        }
        (_a = state.current.groups[groupId].subgroups) === null || _a === void 0 ? void 0 : _a.push(subgroup.id);
        if (!state.current.subgroups) {
            Vue.set(state, "subgroups", {});
        }
        Vue.set(state.current.subgroups, subgroup.id, subgroup);
    },
    [EDIT_RESPONSE_SUBGROUP_MUTATION](state, subgroup) {
        state.current.subgroups[subgroup.id] = subgroup;
    },
    [DELETE_RESPONSE_SUBGROUP_MUTATION](state, { groupId, subgroupId }) {
        // delete all responses referenced by subgroup
        [...state.current.subgroups[subgroupId].responses].forEach((responseId) => this.commit(`editor/responses/${DELETE_RESPONSE_MUTATION}`, { subgroupId, responseId }));
        // delete subgroup
        const subgroupIds = state.current.groups[groupId].subgroups;
        subgroupIds.splice(subgroupIds.indexOf(subgroupId), 1);
        Vue.delete(state.current.subgroups, subgroupId);
    },
    [REORDER_RESPONSE_SUBGROUP_MUTATION](state, { group, from, to }) {
        state.current.groups[group].subgroups = reorder(state.current.groups[group].subgroups, from, to);
    },
    // response
    [ADD_RESPONSE_MUTATION](state, { subgroupId, response }) {
        const id = uuidv4();
        response.id = id;
        if (!state.current.subgroups[subgroupId].responses) {
            Vue.set(state.current.subgroups[subgroupId], "responses", []);
        }
        state.current.subgroups[subgroupId].responses.push(id);
        if (!state.current.responses) {
            Vue.set(state, "responses", {});
        }
        Vue.set(state.current.responses, id, response);
    },
    [EDIT_RESPONSE_MUTATION](state, response) {
        state.current.responses[response.id] = response;
    },
    [DELETE_RESPONSE_MUTATION](state, { subgroupId, responseId }) {
        const responseIds = state.current.subgroups[subgroupId].responses;
        responseIds.splice(responseIds.indexOf(responseId), 1);
        Vue.delete(state.current.responses, responseId);
    },
    [REORDER_RESPONSE_MUTATION](state, { subgroupId, from, to }) {
        state.current.subgroups[subgroupId].responses = reorder(state.current.subgroups[subgroupId].responses, from, to);
    },
    // set
    [SET_RESPONSE_ERROR_MUTATION](state, error) {
        state.error = error;
    },
};
function reorder(arr, from, to) {
    const movedItem = arr.find((item, index) => index === from);
    const remainingItems = arr.filter((item, index) => index !== from);
    const reorderedItems = [...remainingItems.slice(0, to), movedItem, ...remainingItems.slice(to)];
    return reorderedItems;
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

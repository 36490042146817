export default function indent3() {
    return [
        {
            type: "output",
            regex: /%indent-3%([\s\S]*?)%indent-3%/gi,
            replace(s, match) {
                return '<div style="margin-left: 120px;">' + match + "</div>";
            },
        },
    ];
}

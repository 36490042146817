import Vue from "vue";
export default Vue.extend({
    name: "LanceLanguage",
    data() {
        return {
            languages: [
                {
                    id: "en-GB",
                    name: "English",
                },
                {
                    id: "de-DE",
                    name: "Deutsche",
                },
                {
                    id: "en-PI",
                    name: "Pirate",
                },
            ],
        };
    },
    computed: {
        prod: () => process.env.NODE_ENV === "production",
    },
});

import { __awaiter } from "tslib";
import Vue from "vue";
import LanceNavigation from "@/components/misc/Navigation.vue";
import LanceFooter from "@/components/misc/Footer.vue";
import { mapGetters, mapActions } from "vuex";
import pdf from "pdfvuer";
import { lang } from "./conf";
export default Vue.extend({
    name: "LanceApp",
    components: {
        LanceNavigation,
        LanceFooter,
        pdf,
    },
    data() {
        return {
            show_terms: false,
            terms_checked: false,
            opt_in: true,
            // PDF
            page: 1,
            numPages: 0,
            pdfdata: undefined,
            errors: [],
            scale: "page-width",
            // CMS
            content: {
                title: "LANCE | Liability & Non-Compliance Evaluator",
                trial_terms: "https://prismic-io.s3.amazonaws.com/lance%2Fad1952d7-7982-4e0d-8a34-684ccbed0c5d_software+trial+licence+agreement+-+terms+and+conditions+format.pdf",
                navigation: {
                    links: [
                        {
                            key: "dashboard",
                            title: "Dashboard",
                            description: "Review your progress and start a new review",
                        },
                        {
                            key: "product",
                            title: "About",
                            description: "Tax Risk Assessment",
                        },
                        {
                            key: "new_review",
                            title: "New Review",
                            description: "Start a new review",
                        },
                        {
                            key: "survey_editor",
                            title: "Survey Editor",
                            description: "Survey Editor",
                        },
                        {
                            key: "response_editor",
                            title: "Response Editor",
                            description: "Response Editor",
                        },
                        {
                            key: "rule_editor",
                            title: "Rule Editor",
                            description: "Rule Editor",
                        },
                        {
                            key: "definition_editor",
                            title: "Definition Editor",
                            description: "Definition Editor",
                        },
                        {
                            key: "survey_preview",
                            title: "Survey Preview",
                            description: "Survey Preview",
                        },
                        {
                            key: "reviews",
                            title: "Reviews",
                            description: "Reviews",
                        },
                        {
                            key: "support",
                            title: "Support",
                            description: "Support",
                        },
                        {
                            key: "user_list",
                            title: "User List",
                            description: "User List",
                        },
                        {
                            key: "change_password",
                            title: "Change Password",
                            description: "Change your password if you think your security has been compromised",
                        },
                        {
                            key: "logout",
                            title: "Logout",
                            description: "Logout",
                        },
                        {
                            key: "login",
                            title: "Login",
                            description: "Login",
                        },
                        {
                            key: "customer_metrics",
                            title: "Customer Metrics",
                            description: "Customer Metrics",
                        },
                    ],
                },
                footer: {
                    links: [
                        {
                            key: "privacy_policy_public",
                            title: "Privacy Policy",
                            description: "Download a copy of our Privacy Policy",
                            sensitivity: "Public",
                            media: {
                                link_type: "Media",
                                name: "star.gif",
                                kind: "image",
                                url: "https://wroomdev.s3.amazonaws.com/tutoblanktemplate%2F97109f41-140e-4dc9-a2c8-96fb10f14051_star.gif",
                                size: "627291",
                                height: "800",
                                width: "960",
                            },
                        },
                        {
                            key: "privacy_policy",
                            title: "Privacy Policy",
                            description: "Download a copy of our Privacy Policy",
                            sensitivity: "Members",
                            media: {
                                link_type: "Media",
                                name: "Privacy-Policy.pdf",
                                kind: "document",
                                url: "https://lance.cdn.prismic.io/lance%2Fcacd161b-2316-44f3-b206-18c8b39910b3_privacy-policy.pdf",
                                size: "213204",
                            },
                        },
                        {
                            key: "terms_and_conditions",
                            title: "Terms and Conditions",
                            description: "Download a copy of our Terms and Conditions",
                            sensitivity: "Members",
                            media: {
                                link_type: "Media",
                                name: "Terms-and-Conditions.pdf",
                                kind: "document",
                                url: "https://lance.cdn.prismic.io/lance%2Fe67ce2e0-a9dd-42f8-a832-4477e247bea0_terms-and-conditions.pdf",
                                size: "2651448",
                            },
                        },
                    ],
                    copyright: "Copyright © 2018 LANCE Platform Limited - All rights reserved.",
                    company_details: "Company number 06595918. Registered office address 55 Hoghton Street, Southport, Merseyside, United Kingdom, PR9 0PG.",
                },
            },
        };
    },
    computed: Object.assign(Object.assign({}, mapGetters("auth", ["authenticatedUser", "isTrialUser"])), mapGetters("users", ["user"])),
    watch: {
        authenticatedUser(user) {
            user === null || user === void 0 ? void 0 : user.getUserAttributes((err, attributes) => {
                var _a;
                if (err) {
                    console.error(err);
                    return;
                }
                if (!attributes) {
                    return;
                }
                this.show_terms = attributes.some((v) => v.Name === "custom:terms_agreed" && v.Value !== "yes");
                const email = (_a = attributes.find((v) => v.Name === "email")) === null || _a === void 0 ? void 0 : _a.Value;
                this.$intercom.update({ email });
            });
        },
    },
    mounted() {
        this.$intercom.boot();
        this.getPdf();
    },
    created() {
        this.getContent();
    },
    methods: Object.assign(Object.assign({}, mapActions("auth", ["setTermsAgreed", "setOptInStatus", "signOut"])), { getContent() {
            this.$prismic.client.getSingle("application_shell", { lang }).then((response) => {
                if (!response)
                    return;
                let result = response.data;
                this.content.title = result.title;
                this.content.trial_terms = result.trial_terms;
                this.content.navigation.links = result.navigation_items;
                this.content.footer.links = result.footer_navigation_items;
                this.content.footer.copyright = result.copyright;
                this.content.footer.company_details = result.company_details;
            });
        },
        hideTerms() {
            const userHasOptedin = this.opt_in ? "yes" : "no";
            this.setTermsAgreed(userHasOptedin).then(() => (this.show_terms = false));
        },
        validEmail(email) {
            var re = 
            // eslint-disable-next-line no-useless-escape
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        logOut() {
            return __awaiter(this, void 0, void 0, function* () {
                yield this.signOut();
                this.$router.push({ name: "Home" });
            });
        },
        getPdf() {
            this.pdfdata = pdf.createLoadingTask(this.content.trial_terms);
            this.pdfdata.then((pdf) => (this.numPages = pdf.numPages));
        } }),
});

import Vue from "vue";
import LanceScoreTriangle from "./ScoreTriangle.vue";
export default Vue.extend({
    components: { LanceScoreTriangle },
    props: {
        table: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            scoreTableHeaders: ["IT", "CGT", "IHT", "CT", "ATED"],
            categories: [
                {
                    type: "Trust",
                    subcategories: ["Trustees", "Settlor", "Beneficiaries"],
                },
                {
                    type: "Foundation",
                    subcategories: ["Foundation", "Founder", "Beneficiaries"],
                },
                {
                    type: "Company",
                    subcategories: ["Company", "Shareholder", "Over Settlors"],
                },
            ],
        };
    },
    computed: {
        liabilities() {
            if (!this.table || !this.table.rowGroups) {
                return [];
            }
            let liabilities = [];
            Object.values(this.table.rowGroups)
                .filter((v) => v.rows)
                .forEach((rowGroup) => {
                Object.values(rowGroup.rows)
                    .filter((row) => row.columns)
                    .forEach((row) => {
                    liabilities.push(...Object.values(row.columns)
                        .filter((v) => liabilities.includes(v.name))
                        .map((v) => v.name));
                });
            });
            return liabilities;
        },
    },
    methods: {
        scoreType(row, liability) {
            const column = Object.values(row.columns).find((v) => v.name === liability);
            if (column === undefined) {
                return "n/a";
            }
            // TODO: "!v.remediationStatus" can be safely removed in the future - transitional empty state of status.
            const riskPresent = Object.values(column.values).some((value) => (value.isLooped ? Object.values(value.loopedValues) : [value]).some((v) => (v.remediationStatus === "unresolved" || !v.remediationStatus) && v.score > 0));
            return riskPresent ? "riskPresent" : "noRisk";
        },
        sortRowGroups(rowGroups) {
            const groups = Object.values(rowGroups);
            return this.categories
                .map((c) => c.type)
                .reduce((r, category) => r.concat(groups.filter((v) => v.type === category)), []);
        },
        sortRows(rows, type) {
            var _a;
            const subcategories = ((_a = this.categories.find((v) => v.type === type)) === null || _a === void 0 ? void 0 : _a.subcategories) || [];
            return Object.values(rows).sort((a, b) => {
                const i = subcategories.findIndex((v) => a.type.includes(v));
                const j = subcategories.findIndex((v) => b.type.includes(v));
                return i === j ? 0 : i - j;
            });
        },
    },
});

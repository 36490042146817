import Vue from "vue";
import LanceCategories from "@/components/editor/response/Categories.vue";
import { GET_DRAFT_SURVEY_ACTION_RESPONSE } from "@/store/types";
export default Vue.extend({
    name: "LanceResponseEditor",
    components: {
        LanceCategories,
    },
    computed: {
        categories() {
            return this.$store.getters["editor/responses/categories"];
        },
    },
    created() {
        this.$store.dispatch(`editor/responses/${GET_DRAFT_SURVEY_ACTION_RESPONSE}`, true);
    },
});

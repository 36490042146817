import Vue from "vue";
import { mapMutations } from "vuex";
import { ADD_DEFINITION_GROUP_MUTATION } from "@/store/types";
export default Vue.extend({
    name: "LanceAdd",
    data() {
        return {
            name: "",
        };
    },
    computed: {
        groupIds() {
            return this.$store.getters["editor/definitions/groups"].map((v) => v.id);
        },
        valid() {
            return this.name ? !this.groupIds.includes(this.name) : null;
        },
    },
    methods: Object.assign(Object.assign({}, mapMutations("editor/definitions", {
        addGroup: ADD_DEFINITION_GROUP_MUTATION,
    })), { save() {
            this.addGroup(this.name);
            this.name = "";
            this.$emit("closeAdd");
        },
        cancel() {
            this.name = "";
            this.$emit("closeAdd");
        } }),
});

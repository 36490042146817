export default function indent2() {
    return [
        {
            type: "output",
            regex: /%indent-2%([\s\S]*?)%indent-2%/gi,
            replace(s, match) {
                return '<div style="margin-left: 80px;">' + match + "</div>";
            },
        },
    ];
}

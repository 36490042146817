import { __awaiter } from "tslib";
import Vue from "vue";
import Router from "vue-router";
import { Auth } from "@aws-amplify/auth";
import store from "@/store";
import LanceHome from "@/views/Home.vue";
import LanceLogin from "@/components/auth/Login.vue";
import LanceResetPassword from "@/components/auth/ResetPassword.vue";
import LanceSignUp from "@/components/auth/SignUp.vue";
import LanceConfirmSignUp from "@/components/auth/ConfirmSignUp.vue";
import LanceConfirmPasswordReset from "@/components/auth/ConfirmPasswordReset.vue";
import LanceChangePassword from "@/components/auth/ChangePassword.vue";
import LanceReviewSurvey from "@/views/review/Survey.vue";
import LanceReviewSubmit from "@/views/review/Submit.vue";
import LanceReviewReport from "@/views/review/Report.vue";
// import LanceReviewStructure from "@/views/review/Structure.vue";
import LanceReviewStructure from "@/views/review/StructureLegacy.vue";
import LanceEditor from "@/views/admin/editor/Editor.vue";
import LanceRuleEditor from "@/views/admin/editor/Rules.vue";
import LanceResponseEditor from "@/views/admin/editor/Response.vue";
import LanceSurveyEditor from "@/views/admin/editor/Survey.vue";
import LanceDefinitionEditor from "@/views/admin/editor/Definition.vue";
import LanceUserList from "@/views/users/UsersList.vue";
import LanceUserCreate from "@/views/users/UserCreate.vue";
import LanceUserDetail from "@/views/users/UserDetail.vue";
import LanceUserDetailReports from "@/views/users/UserDetailReports.vue";
import LanceUserReports from "@/views/user/UserReports.vue";
import LanceDashboard from "@/views/user/Dashboard.vue";
import LanceProduct from "@/views/marketing/Product.vue";
import LanceCustomerMetrics from "@/views/metrics/Metrics.vue";
import LanceSupport from "../views/user/Support.vue";
Vue.use(Router);
const router = new Router({
    mode: "history",
    routes: [
        {
            path: "/",
            component: LanceHome,
            children: [
                {
                    name: "Home",
                    path: "",
                    component: LanceLogin,
                },
                {
                    name: "Login",
                    path: "login",
                    component: LanceLogin,
                },
                {
                    name: "ResetPassword",
                    path: "resetpassword",
                    component: LanceResetPassword,
                },
                {
                    name: "SignUp",
                    path: "signup",
                    component: LanceSignUp,
                    meta: {
                        forbidden: process.env.NODE_ENV === "production",
                    },
                },
                {
                    name: "ConfirmSignup",
                    path: "confirmsignup",
                    component: LanceConfirmSignUp,
                },
                {
                    name: "ConfirmPasswordReset",
                    path: "confirmpasswordreset",
                    component: LanceConfirmPasswordReset,
                },
                {
                    name: "ChangePassword",
                    path: "changepassword",
                    component: LanceChangePassword,
                },
            ],
        },
        {
            name: "Review",
            path: "/review/:id",
            component: LanceReviewSurvey,
            meta: {
                auth: true,
            },
        },
        {
            name: "Review Structure",
            path: "/review/:id/structure",
            component: LanceReviewStructure,
            meta: {
                auth: true,
            },
        },
        {
            name: "Submit Review",
            path: "/review/:id/submit",
            component: LanceReviewSubmit,
            meta: {
                auth: true,
            },
        },
        {
            name: "Review Report",
            path: "/review/:id/report",
            component: LanceReviewReport,
            meta: {
                auth: true,
            },
        },
        {
            name: "Structure",
            path: "/structure",
            component: LanceReviewStructure,
            meta: {
                auth: true,
            },
            props: {
                preview: false,
            },
        },
        {
            name: "Editor",
            path: "/admin/editor",
            component: LanceEditor,
            meta: {
                auth: true,
                admin: true,
            },
            children: [
                {
                    name: "RuleEditor",
                    path: "rules",
                    component: LanceRuleEditor,
                    meta: {
                        auth: true,
                        admin: true,
                    },
                },
                {
                    name: "ResponseEditor",
                    path: "response",
                    component: LanceResponseEditor,
                    meta: {
                        auth: true,
                        admin: true,
                    },
                },
                {
                    name: "SurveyEditor",
                    path: "survey",
                    component: LanceSurveyEditor,
                    meta: {
                        auth: true,
                        admin: true,
                    },
                },
                {
                    name: "DefinitionEditor",
                    path: "definition",
                    component: LanceDefinitionEditor,
                    meta: {
                        auth: true,
                        admin: true,
                    },
                },
                {
                    name: "Preview",
                    path: "preview",
                    component: LanceReviewStructure,
                    meta: {
                        auth: true,
                        admin: true,
                    },
                    props: {
                        preview: true,
                    },
                },
            ],
        },
        {
            name: "UserList",
            path: "/users",
            component: LanceUserList,
            meta: {
                auth: true,
            },
        },
        {
            name: "UserCreate",
            path: "/users/create",
            component: LanceUserCreate,
            meta: {
                auth: true,
            },
        },
        {
            name: "UserDetail",
            path: "/users/:id",
            component: LanceUserDetail,
            meta: {
                auth: true,
            },
        },
        {
            name: "UserDetailReports",
            path: "/users/:id/reports",
            component: LanceUserDetailReports,
            meta: {
                auth: true,
            },
        },
        {
            name: "UserReports",
            path: "/user/reports",
            component: LanceUserReports,
            meta: {
                auth: true,
            },
        },
        {
            name: "Dashboard",
            path: "/user/dashboard",
            component: LanceDashboard,
            meta: {
                auth: true,
            },
        },
        {
            name: "Product",
            path: "/product",
            component: LanceProduct,
            meta: {
                auth: false,
            },
        },
        {
            name: "CustomerMetrics",
            path: "/metrics",
            component: LanceCustomerMetrics,
            meta: {
                auth: false,
            },
        },
        {
            name: "Support",
            path: "/support",
            component: LanceSupport,
            meta: {
                auth: true,
            },
        },
    ],
    linkActiveClass: "active",
});
router.beforeEach((to, from, next) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        store.commit("auth/setUserAuthenticated", yield Auth.currentAuthenticatedUser());
    }
    catch (err) {
        console.log(err);
    }
    if (to.meta && to.meta.forbidden) {
        next("/");
        return;
    }
    if (to.meta && to.meta.auth !== undefined && to.meta.auth) {
        if (!store.getters["auth/isAuthenticated"]) {
            router.push({ name: "Home" });
            return;
        }
        if (to.meta.admin) {
            if (store.getters["auth/isAdmin"]) {
                next();
                return;
            }
            router.push({ name: "Home" });
            return;
        }
        next();
        return;
    }
    next();
}));
export default router;

import { v4 as uuidv4 } from "uuid";
import { DECEASED } from "@/store/types";
import { STRUCTURE_ENTITY } from "@/store/modules/review/types";
function orderEntityIds(structureJson) {
    let entityOrder = [];
    for (const index in structureJson.rootEntitiesIds) {
        const id = structureJson.rootEntitiesIds[index];
        if (structureJson.entities[id].overs.length === 0) {
            entityOrder = [...entityOrder, id];
        }
    }
    let idsLeft = true;
    while (idsLeft) {
        // start of with the assumption that all the ids are in the correct order
        idsLeft = false;
        for (const rootIndex in structureJson.rootEntitiesIds) {
            const rootId = structureJson.rootEntitiesIds[rootIndex];
            if (entityOrder.includes(rootId))
                continue;
            // if we get to here then it means that the there is Ids left and so we should check again
            idsLeft = true;
            let includeRoot = true;
            for (const overIndex in structureJson.entities[rootId].overs) {
                if (!entityOrder.includes(structureJson.entities[rootId].overs[overIndex])) {
                    includeRoot = false;
                    break;
                }
            }
            if (includeRoot) {
                entityOrder = [...entityOrder, rootId];
            }
        }
    }
    return entityOrder;
}
// parseDate parses date formatted as DD/MM/YY.
function parseDate(date) {
    const parts = date.split("/");
    return new Date(+parts[2], +parts[1], +parts[0]);
}
function AddNonShareHolderOversChildChild(root, oversChildChildId, copiedChildId, over, s) {
    const childChildEntity = s.entities[oversChildChildId];
    // First create the new entity object
    const entityObject = {
        id: uuidv4(),
        type: childChildEntity.type,
        meta: {
            date: root.dates.filter((date) => date.id === over.originalOver)[0].date,
        },
        parent: copiedChildId,
        actingAs: root.type === STRUCTURE_ENTITY.TRUST ? STRUCTURE_ENTITY.SETTLEMENT : STRUCTURE_ENTITY.CONTRIBUTION,
        copiedFrom: childChildEntity.id,
    };
    // add new entity object to s
    s.entities[entityObject.id] = entityObject; // TODO: not true, it is partial SurveyEntity
    // amend the copiedChildId's children array
    s.entities[copiedChildId].children.push(entityObject.id);
    // amend the oversChildChildId to have the copied to field in
    if (s.entities[oversChildChildId].copiedTo) {
        s.entities[oversChildChildId].copiedTo.push(entityObject.id);
    }
    else {
        s.entities[oversChildChildId].copiedTo = [];
        s.entities[oversChildChildId].copiedTo.push(entityObject.id);
    }
}
function AddNonShareHolderOversChild(root, oversChildId, s) {
    const childEntity = s.entities[oversChildId];
    // First create the new entity object
    const entityObject = {
        id: uuidv4(),
        children: [],
        parent: root.id,
        type: childEntity.type,
        meta: childEntity.meta,
        actingAs: root.type === STRUCTURE_ENTITY.TRUST ? STRUCTURE_ENTITY.SETTLOR : STRUCTURE_ENTITY.FOUNDER,
        copiedFrom: childEntity.id,
    };
    // add new entity object to s
    s.entities[entityObject.id] = entityObject; // TODO: not true, it is partial SurveyEntity
    // amend the root's children array
    s.entities[root.id].children.push(entityObject.id);
    // amend the oversChildId to have the copied to field in
    if (s.entities[oversChildId].copiedTo) {
        s.entities[oversChildId].copiedTo.push(entityObject.id);
    }
    else {
        s.entities[oversChildId].copiedTo = [];
        s.entities[oversChildId].copiedTo.push(entityObject.id);
    }
    return entityObject.id;
}
// This function checks the date for the overs children children (contributions) and checks the dates
// and returns a array with the index of the children children that are ok to add
function checkOversChildrenChildren(root, over, childId, s) {
    const originalOver = s.entities[over.originalOver];
    // For a non shareholder the settlement date or contribution date must be after the original parent
    const originalDate = parseDate(root.dates.filter((dateObj) => dateObj.id === originalOver.id)[0].date);
    const okArray = [];
    s.entities[childId].children.forEach((childId, i) => {
        const childDate = parseDate(s.entities[childId].meta.date);
        if (childDate <= originalDate) {
            okArray.push(i);
        }
    });
    return okArray;
}
function checkShareholderDates(root, over, oversChildId, s) {
    const originalOver = s.entities[over.originalOver];
    // For a shareholder the link date between entities has to be between the time the shareholder was active
    const originalDate = parseDate(root.dates.filter((dateObj) => dateObj.id === originalOver.id)[0].date);
    const shareholder = s.entities[oversChildId];
    // Can use 0 index as there will only ever be 1 child for a shareholder (its just the date)
    const startDate = parseDate(s.entities[shareholder.children[0]].meta.date);
    const endDate = parseDate(s.entities[shareholder.children[0]].meta.end);
    return originalDate >= startDate && originalDate <= endDate;
}
function checkAndAddShareholderCopy(root, over, oversChildId, s) {
    if (!checkShareholderDates(root, over, oversChildId, s))
        return;
    const childEntity = s.entities[oversChildId];
    // First create the new entity object
    const shEntityObject = {
        id: uuidv4(),
        children: [],
        parent: root.id,
        type: childEntity.type,
        meta: childEntity.meta,
        actingAs: root.type === STRUCTURE_ENTITY.TRUST ? STRUCTURE_ENTITY.SETTLOR : STRUCTURE_ENTITY.FOUNDER,
        copiedFrom: childEntity.id,
    };
    // add new entity object to s
    s.entities[shEntityObject.id] = shEntityObject; // TOOD: not true, it is is partial SurveyEntity
    // amend the root's children array
    s.entities[root.id].children.push(shEntityObject.id);
    // amend the oversChildId to have the copied to field in
    if (s.entities[oversChildId].copiedTo) {
        s.entities[oversChildId].copiedTo.push(shEntityObject.id);
    }
    else {
        s.entities[oversChildId].copiedTo = [];
        s.entities[oversChildId].copiedTo.push(shEntityObject.id);
    }
    // For sh we need to create a dummy settlement or foundation entity
    const originalOver = s.entities[over.originalOver];
    // First create the new entity object
    const childEntityObject = {
        id: uuidv4(),
        type: root.type === STRUCTURE_ENTITY.TRUST ? STRUCTURE_ENTITY.SETTLEMENT : STRUCTURE_ENTITY.CONTRIBUTION,
        meta: {
            date: root.dates.filter((dateObj) => dateObj.id === originalOver.id)[0].date,
        },
        parent: shEntityObject.id,
    };
    // add new entity object to s
    s.entities[childEntityObject.id] = childEntityObject; // TODO: not true, it is partial SurveyEntity
    // amend the copiedChildId's children array
    s.entities[shEntityObject.id].children.push(childEntityObject.id);
}
function oversChildNotCopied(oversChildChildId, s) {
    return s.entities[oversChildChildId].copiedFrom === undefined;
}
function checkOversChildren(root, over, oversChildId, s) {
    if (!oversChildNotCopied(oversChildId, s))
        return;
    if (s.entities[oversChildId].type === STRUCTURE_ENTITY.SHAREHOLDER) {
        checkAndAddShareholderCopy(root, over, oversChildId, s);
    }
    else {
        // Check the oversChildrenChildren ie. the settlements or contributions
        const okArray = checkOversChildrenChildren(root, over, oversChildId, s);
        if (okArray.length === 0)
            return;
        const copiedChildId = AddNonShareHolderOversChild(root, oversChildId, s);
        okArray.forEach((i) => {
            AddNonShareHolderOversChildChild(root, s.entities[oversChildId].children[i], copiedChildId, over, s);
        });
    }
}
// Check the all the root entities overs, and find what needs to be copied into it.
function checkRootEntitiesOvers(root, over, s) {
    s.entities[over.overId].children.forEach((childId) => {
        checkOversChildren(root, over, childId, s);
    });
}
// Creates a array of objects with the overId and th> original over that it came from,
// as there can be overs of overs and we need the original over for the date work, see p2-13 section 7
function getOversArray(rootEntityId, s) {
    const overArray = [];
    let originalOver;
    let linkDate;
    function calcOvers(overs) {
        if (overs.length === 0)
            return;
        overs.forEach((over) => {
            // first need to check the overs over to see if it has a exit date. If so check it is after the link to
            // the root entity we are checking
            if (s.entities[over].exit_date) {
                if (s.entities[over].exit_date < linkDate)
                    return;
            }
            if (overArray.map((i) => i.overId).includes(over))
                return;
            overArray.push({
                overId: over,
                originalOver,
            });
            calcOvers(s.entities[over].overs);
        });
    }
    s.entities[rootEntityId].overs.forEach((over) => {
        if (overArray.map((i) => i.overId).includes(over))
            return;
        overArray.push({
            overId: over,
            originalOver: over,
        });
        originalOver = over;
        linkDate = parseDate(s.entities[rootEntityId].dates.filter((date) => date.id === over)[0].date);
        calcOvers(s.entities[over].overs);
    });
    return overArray;
}
function checkRootEntity(rootEntityId, s) {
    const root = s.entities[rootEntityId];
    if (root.type === STRUCTURE_ENTITY.COMPANY) {
        return;
    }
    // if the root entity has no overs nothing needs t> get copied to it
    if (root.overs.length === 0) {
        return;
    }
    // Get all the overs for the array including all the overs, overs etc..
    // TODO get over of overs
    const oversArray = getOversArray(rootEntityId, s);
    oversArray.forEach((over) => {
        checkRootEntitiesOvers(root, over, s);
    });
}
function removeUnwantedEntities(data) {
    Object.keys(data)
        .filter((entityId) => data[entityId].type === STRUCTURE_ENTITY.SHAREHOLDER_DATES) // Create array of IDs to remove
        .map((item) => {
        const parentId = data[item].parent; // Get ID of parent entity
        data[parentId].children = data[parentId].children.filter((value) => value !== item); // Remove reference to child entity from parent entity
        delete data[item]; // Remove entity itself
    });
    return data;
}
function addChildrenToMetaData(s) {
    for (const id in s.entities) {
        const entity = s.entities[id];
        if (entity.type === STRUCTURE_ENTITY.SHAREHOLDER_DATES ||
            entity.type === STRUCTURE_ENTITY.SHAREHOLDER ||
            entity.type === STRUCTURE_ENTITY.CONTRIBUTION ||
            entity.type === STRUCTURE_ENTITY.SETTLEMENT) {
            continue;
        }
        else {
            s.entities[id].metaArray = { children: entity.children };
            if (entity.type === STRUCTURE_ENTITY.FOUNDATION || entity.type === STRUCTURE_ENTITY.TRUST) {
                const grandChildren = [];
                for (const childIndex in entity.children) {
                    for (const grandChildIndex in s.entities[entity.children[childIndex]].children) {
                        const grandChildId = s.entities[entity.children[childIndex]].children[grandChildIndex];
                        grandChildren.push(grandChildId);
                    }
                }
                s.entities[id].metaArray.grandchildren = grandChildren;
            }
        }
    }
}
function orderSettlorsByDeceased(s) {
    s.rootEntitiesIds.forEach((rootEntityId) => {
        if ((s.entities[rootEntityId].type === STRUCTURE_ENTITY.FOUNDATION ||
            s.entities[rootEntityId].type === STRUCTURE_ENTITY.TRUST) &&
            s.entities[rootEntityId].children.length > 1) {
            const children = s.entities[rootEntityId].children;
            children.forEach((child, index) => {
                if (s.entities[child].meta.deceased === DECEASED.YES) {
                    if (index === 0)
                        return s.entries;
                    s.entities[rootEntityId].children.splice(index, 1);
                    s.entities[rootEntityId].children = [child, ...s.entities[rootEntityId].children];
                    return s.entities;
                }
            });
        }
    });
    return s.entities;
}
function addCopiedMetaData(s) {
    Object.keys(s.entities).forEach((entityId) => {
        const entity = s.entities[entityId];
        if (entity.copiedFrom && entity.copiedFrom.length > 0 && entity.type === "shareholder") {
            const metaTmp = Object.assign(Object.assign({}, entity.meta), { copied: "YES", deceased: "no" });
            s.entities[entityId].meta = metaTmp;
        }
        else if (entity.copiedFrom && entity.copiedFrom.length > 0) {
            const metaTmp = Object.assign(Object.assign({}, entity.meta), { copied: "YES" });
            s.entities[entityId].meta = metaTmp;
        }
        else {
            const metaTmp = Object.assign(Object.assign({}, entity.meta), { copied: "NO" });
            s.entities[entityId].meta = metaTmp;
        }
    });
    return s.entities;
}
export default function (structure) {
    const s = Object.assign({}, structure);
    // Reorder root entities Ids p2-13- answer top of the entity structure first
    const orderedEntityArray = orderEntityIds(s);
    s.rootEntitiesIds = orderedEntityArray;
    addChildrenToMetaData(s);
    // Copy over Child entities to the structure tree
    // First Loop though all the root entities
    s.rootEntitiesIds.forEach((rootEntityId) => {
        checkRootEntity(rootEntityId, s);
    });
    s.entities = removeUnwantedEntities(s.entities);
    s.entities = orderSettlorsByDeceased(s);
    s.entities = addCopiedMetaData(s);
    return s;
}

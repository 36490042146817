import { GET_QUESTIONS_ASYNC, GET_QUESTIONS_ACTION, SUBMIT_ANSWER_ASYNC, SUBMIT_ANSWERS_ACTION } from "@/store/types";
// initial state
export const state = {
    survey: {
        surveyId: null,
        questions: null,
        pending: false,
        error: null,
    },
    answers: {
        pending: false,
        error: null,
    },
    reportId: null,
};
// getters
export const getters = {
    questions: (state) => state.survey.questions,
    surveyId: (state) => state.survey.surveyId,
    reportId: (state) => state.reportId,
};
// actions
export const actions = {
    [GET_QUESTIONS_ACTION]({ commit }) {
        commit(GET_QUESTIONS_ASYNC.PENDING);
        // survey.getQuestions()
        //   .then(res => commit(GET_QUESTIONS_ASYNC.SUCCESS, res))
        //   .catch(err => commit(GET_QUESTIONS_ASYNC.FAILURE, err))
    },
    [SUBMIT_ANSWERS_ACTION]({ commit }, answers) {
        commit(SUBMIT_ANSWER_ASYNC.PENDING);
        // return survey.submitAnswer({
        //   surveyId: state.survey.surveyId,
        //   answers
        // })
        //   .then(res => commit(SUBMIT_ANSWER_ASYNC.SUCCESS, res))
        //   .catch(err => commit(SUBMIT_ANSWER_ASYNC.FAILURE, err))
    },
};
// mutations
export const mutations = {
    [GET_QUESTIONS_ASYNC.PENDING](state) {
        state.survey.pending = true;
    },
    [GET_QUESTIONS_ASYNC.SUCCESS](state, res) {
        state.survey.surveyId = res.survey_id;
        state.survey.questions = res.questions;
        state.survey.pending = false;
    },
    [GET_QUESTIONS_ASYNC.FAILURE](state, error) {
        state.survey.pending = false;
        state.survey.error = error;
    },
    [SUBMIT_ANSWER_ASYNC.PENDING](state) {
        state.answers.pending = true;
    },
    [SUBMIT_ANSWER_ASYNC.SUCCESS](state, report) {
        state.answers.pending = false;
        state.reportId = report.report_id;
    },
    [SUBMIT_ANSWER_ASYNC.FAILURE](state, error) {
        state.answers.pending = false;
        state.answers.error = error;
    },
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

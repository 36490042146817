import Vue from "vue";
import { converter } from "@/lib/shodown";
export default Vue.extend({
    name: "LanceComments",
    computed: {
        report() {
            return this.$store.getters["review/report"];
        },
    },
    methods: {
        text(selector) {
            const v = this.$store.getters["review/selectResponse"](selector);
            return converter.makeHtml((v === null || v === void 0 ? void 0 : v.text) || "");
        },
        orderCommentsByDate(comments) {
            return Object.values(comments).sort((a, b) => a.timestamp - b.timestamp);
        },
    },
});

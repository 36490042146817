import en from "./en";
import de from "./de";
import pi from "./pi";
// TODO: override is never used, function signature is unknown.
export function override(base, translation) {
    return Object.assign(base, translation);
}
export const messages = {
    "en-GB": en,
    "de-DE": de,
    "en-PI": pi,
};

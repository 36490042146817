import Vue from "vue";
import LanceAttachment from "@/components/misc/Attachment.vue";
export default Vue.extend({
    name: "LanceAttachments",
    components: {
        LanceAttachment,
    },
    props: {
        items: Object,
    },
});

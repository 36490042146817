import { __awaiter } from "tslib";
/**
 * Applies http authentication to the request context.
 */
export class BearerAuthAuthentication {
    /**
     * Configures the http authentication with the required details.
     *
     * @param tokenProvider service that can provide the up-to-date token when needed
     */
    constructor(tokenProvider) {
        this.tokenProvider = tokenProvider;
    }
    getName() {
        return "bearerAuth";
    }
    applySecurityAuthentication(context) {
        return __awaiter(this, void 0, void 0, function* () {
            context.setHeaderParam("Authorization", "Bearer " + (yield this.tokenProvider.getToken()));
        });
    }
}
/**
 * Creates the authentication methods from a swagger description.
 *
 */
export function configureAuthMethods(config) {
    let authMethods = {};
    if (!config) {
        return authMethods;
    }
    if (config["bearerAuth"]) {
        authMethods["bearerAuth"] = new BearerAuthAuthentication(config["bearerAuth"]["tokenProvider"]);
    }
    return authMethods;
}

import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
export default Vue.extend({
    name: "ConfirmSignUp",
    data() {
        return {
            username: "",
            code: "",
        };
    },
    computed: Object.assign({}, mapGetters("auth", ["pending", "authenticationStatus"])),
    methods: Object.assign({}, mapActions("auth", ["confirmSignUp"])),
});

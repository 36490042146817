import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
export default Vue.extend({
    name: "ChangePassword",
    data() {
        return {
            currentPassword: "",
            newPassword: "",
            confirmNewPassword: "",
            confirmError: "",
        };
    },
    computed: Object.assign({}, mapGetters("auth", ["pending", "authenticationStatus", "passwordChangeRequired"])),
    methods: Object.assign(Object.assign({}, mapActions("auth", ["passwordChange", "passwordSetFirstTime"])), { newPasswordFunc() {
            if (this.newPassword !== this.confirmNewPassword) {
                this.confirmError = "Passwords don't match";
            }
            else {
                this.passwordSetFirstTime(this.newPassword).then((res) => {
                    if (res)
                        this.$router.push({ name: "Dashboard" });
                });
            }
        },
        changePasswordFunc() {
            if (this.newPassword !== this.confirmNewPassword) {
                this.confirmError = "Passwords don't match";
            }
            else {
                const data = {
                    currentPassword: this.currentPassword,
                    newPassword: this.newPassword,
                };
                this.passwordChange(data);
            }
        } }),
});

import Vue from "vue";
import { mapGetters } from "vuex";
import LanceHeading from "./Heading.vue";
import LanceResponseWithRisk from "./ResponseWithRisk.vue";
import { converter } from "@/lib/shodown";
export default Vue.extend({
    name: "LanceResponses",
    components: { LanceHeading, LanceResponseWithRisk },
    props: {
        entityResponses: {
            type: Object,
            required: true,
        },
        responseOrder: { type: Object, required: true },
        reportType: { type: String, required: true },
    },
    data() {
        return {
            toggledResponses: {},
        };
    },
    computed: Object.assign(Object.assign({}, mapGetters("review", ["submittingAction"])), { report() {
            return this.$store.getters["review/report"];
        },
        // remediableResponseIds returns all response ids with score greater than 0.
        remediableResponseIds() {
            return this.$store.getters["review/remediableResponseIds"];
        },
        orderedResponseGroups() {
            const groupIds = Object.keys(this.entityResponses.groups);
            const categories = Object.values(this.responseOrder.categories);
            return categories.reduce((r, category) => {
                r = r.concat(category.groups
                    .filter((groupId) => groupIds.includes(groupId))
                    .map((groupId) => this.entityResponses.groups[groupId]));
                return r;
            }, []);
        } }),
    methods: {
        parse(text) {
            return converter.makeHtml(text);
        },
        toggleMoreDetail(id) {
            this.toggledResponses = Object.assign(Object.assign({}, this.toggledResponses), { [id]: this.toggledResponses[id] === undefined ? true : !this.toggledResponses[id] });
        },
        orderedSubgroups(group) {
            const subgroups = group.subgroups;
            return this.responseOrder.groups[group.id].subgroups
                .filter((subgroupId) => subgroups[subgroupId])
                .map((subgroupId) => subgroups[subgroupId]);
        },
        orderedResponses(subgroup) {
            var _a;
            const responses = subgroup.responses;
            const responseIds = responses.map((v) => v.id);
            return (_a = this.responseOrder.subgroups[subgroup.id]) === null || _a === void 0 ? void 0 : _a.responses.filter((responseId) => responseIds.includes(responseId)).map((responseId) => responses.find((v) => v.id === responseId));
        },
        orderedLoopedResponses(subgroup, responses) {
            const responseIds = responses.map((v) => v.id);
            return this.responseOrder.subgroups[subgroup.id].responses
                .filter((responseId) => responseIds.includes(responseId))
                .map((responseId) => responses.find((v) => v.id === responseId));
        },
    },
});

import Vue from "vue";
import { mapGetters } from "vuex";
import { GET_USER_REPORTS_ACTION } from "@/store/types";
import LanceReportsList from "@/components/ReportsList.vue";
import { lang } from "@/conf";
export default Vue.extend({
    name: "UserReports",
    components: {
        LanceReportsList,
    },
    data() {
        return {
            content: {
                review: {
                    title: [
                        {
                            type: "heading1",
                            text: "Reviews",
                            spans: [],
                        },
                    ],
                    instruction: [
                        {
                            type: "paragraph",
                            text: "Use the filters to find partially completed reviews or access reports for completed reviews.",
                            spans: [],
                        },
                    ],
                    show_all: "All",
                    show_complete: "Complete",
                    show_incomplete: "Incomplete",
                    search: "Search Reviews",
                    reference: "Review Id",
                    creator: "Created By",
                    entity_count: "Num. Entities",
                    submitted_date: "Submitted",
                    structure_name: "Structure Name",
                    risk_score: "Risk Score",
                    completed: "Completed",
                    review: "View Report",
                    resume: "Continue Review",
                },
            },
        };
    },
    computed: Object.assign({}, mapGetters("report", ["userReports"])),
    created() {
        this.$store.dispatch(`report/${GET_USER_REPORTS_ACTION}`, true);
        this.getContent();
    },
    methods: {
        getContent() {
            this.$prismic.client.getSingle("review", { lang }).then((response) => {
                if (!response)
                    return;
                this.content.review = response.data;
            });
        },
    },
});

export * from './CompanyUsers';
export * from './Definition';
export * from './DefinitionGroup';
export * from './Definitions';
export * from './EntityType';
export * from './ErrorResponse';
export * from './GetUserResponse';
export * from './Part';
export * from './QuestionChoice';
export * from './QuestionChunk';
export * from './QuestionElement';
export * from './QuestionPage';
export * from './QuestionPart';
export * from './QuestionSequence';
export * from './RemediationStatus';
export * from './Report';
export * from './ReportComment';
export * from './ReportCommentResponseSelector';
export * from './ReportResponse';
export * from './ReportResponseGroup';
export * from './ReportResponseLoopedResponses';
export * from './ReportResponseResponse';
export * from './ReportResponseResponses';
export * from './ReportResponseSubgroup';
export * from './ReportTable';
export * from './ReportTableColumn';
export * from './ReportTableLoopedValues';
export * from './ReportTableRow';
export * from './ReportTableRowGroup';
export * from './ReportTableValue';
export * from './ResponseCategory';
export * from './ResponseGroup';
export * from './ResponseResponse';
export * from './ResponseResponses';
export * from './ResponseSubgroup';
export * from './ReviewAnswerSummary';
export * from './ReviewAnswerSummaryAnswer';
export * from './ReviewAnswerSummaryPage';
export * from './ReviewNextRequest';
export * from './ReviewNextResponse';
export * from './ReviewNextResponseProgress';
export * from './ReviewResetScoreResponse';
export * from './ReviewResponse';
export * from './ReviewStartRequest';
export * from './ReviewStartResponse';
export * from './ReviewSubmitResponse';
export * from './ReviewUpdateRemediationRequest';
export * from './ReviewUpdateRemediationResponse';
export * from './ReviewUpdateRequest';
export * from './ReviewUpdateResponse';
export * from './Rule';
export * from './RuleCategory';
export * from './RuleGroup';
export * from './RuleSubcategory';
export * from './RuleSubgroup';
export * from './Rules';
export * from './SurveyDate';
export * from './SurveyEntity';
export * from './SurveyStructure';
export * from './User';
export * from './UserReview';
export * from './UserWithGroups';
import { CompanyUsers } from './CompanyUsers';
import { Definition } from './Definition';
import { DefinitionGroup } from './DefinitionGroup';
import { Definitions } from './Definitions';
import { ErrorResponse } from './ErrorResponse';
import { GetUserResponse } from './GetUserResponse';
import { QuestionChoice } from './QuestionChoice';
import { QuestionChunk } from './QuestionChunk';
import { QuestionElement } from './QuestionElement';
import { QuestionPage } from './QuestionPage';
import { QuestionPart } from './QuestionPart';
import { QuestionSequence } from './QuestionSequence';
import { Report } from './Report';
import { ReportComment } from './ReportComment';
import { ReportCommentResponseSelector } from './ReportCommentResponseSelector';
import { ReportResponse } from './ReportResponse';
import { ReportResponseGroup } from './ReportResponseGroup';
import { ReportResponseLoopedResponses } from './ReportResponseLoopedResponses';
import { ReportResponseResponse } from './ReportResponseResponse';
import { ReportResponseResponses } from './ReportResponseResponses';
import { ReportResponseSubgroup } from './ReportResponseSubgroup';
import { ReportTable } from './ReportTable';
import { ReportTableColumn } from './ReportTableColumn';
import { ReportTableLoopedValues } from './ReportTableLoopedValues';
import { ReportTableRow } from './ReportTableRow';
import { ReportTableRowGroup } from './ReportTableRowGroup';
import { ReportTableValue } from './ReportTableValue';
import { ResponseCategory } from './ResponseCategory';
import { ResponseGroup } from './ResponseGroup';
import { ResponseResponse } from './ResponseResponse';
import { ResponseResponses } from './ResponseResponses';
import { ResponseSubgroup } from './ResponseSubgroup';
import { ReviewAnswerSummary } from './ReviewAnswerSummary';
import { ReviewAnswerSummaryAnswer } from './ReviewAnswerSummaryAnswer';
import { ReviewAnswerSummaryPage } from './ReviewAnswerSummaryPage';
import { ReviewNextRequest } from './ReviewNextRequest';
import { ReviewNextResponse } from './ReviewNextResponse';
import { ReviewNextResponseProgress } from './ReviewNextResponseProgress';
import { ReviewResetScoreResponse } from './ReviewResetScoreResponse';
import { ReviewResponse } from './ReviewResponse';
import { ReviewStartRequest } from './ReviewStartRequest';
import { ReviewStartResponse } from './ReviewStartResponse';
import { ReviewSubmitResponse } from './ReviewSubmitResponse';
import { ReviewUpdateRemediationRequest } from './ReviewUpdateRemediationRequest';
import { ReviewUpdateRemediationResponse } from './ReviewUpdateRemediationResponse';
import { ReviewUpdateRequest } from './ReviewUpdateRequest';
import { ReviewUpdateResponse } from './ReviewUpdateResponse';
import { Rule } from './Rule';
import { RuleCategory } from './RuleCategory';
import { RuleGroup } from './RuleGroup';
import { RuleSubcategory } from './RuleSubcategory';
import { RuleSubgroup } from './RuleSubgroup';
import { Rules } from './Rules';
import { SurveyDate } from './SurveyDate';
import { SurveyEntity } from './SurveyEntity';
import { SurveyStructure } from './SurveyStructure';
import { User } from './User';
import { UserReview } from './UserReview';
import { UserWithGroups } from './UserWithGroups';
/* tslint:disable:no-unused-variable */
let primitives = [
    "string",
    "boolean",
    "double",
    "integer",
    "long",
    "float",
    "number",
    "any"
];
const supportedMediaTypes = {
    "application/json": Infinity,
    "application/octet-stream": 0
};
let enumsMap = new Set([
    "EntityType",
    "Part",
    "RemediationStatus",
    "ReportTableRowTypeEnum",
    "ReportTableRowGroupTypeEnum",
    "ResponseResponseStatusEnum",
]);
let typeMap = {
    "CompanyUsers": CompanyUsers,
    "Definition": Definition,
    "DefinitionGroup": DefinitionGroup,
    "Definitions": Definitions,
    "ErrorResponse": ErrorResponse,
    "GetUserResponse": GetUserResponse,
    "QuestionChoice": QuestionChoice,
    "QuestionChunk": QuestionChunk,
    "QuestionElement": QuestionElement,
    "QuestionPage": QuestionPage,
    "QuestionPart": QuestionPart,
    "QuestionSequence": QuestionSequence,
    "Report": Report,
    "ReportComment": ReportComment,
    "ReportCommentResponseSelector": ReportCommentResponseSelector,
    "ReportResponse": ReportResponse,
    "ReportResponseGroup": ReportResponseGroup,
    "ReportResponseLoopedResponses": ReportResponseLoopedResponses,
    "ReportResponseResponse": ReportResponseResponse,
    "ReportResponseResponses": ReportResponseResponses,
    "ReportResponseSubgroup": ReportResponseSubgroup,
    "ReportTable": ReportTable,
    "ReportTableColumn": ReportTableColumn,
    "ReportTableLoopedValues": ReportTableLoopedValues,
    "ReportTableRow": ReportTableRow,
    "ReportTableRowGroup": ReportTableRowGroup,
    "ReportTableValue": ReportTableValue,
    "ResponseCategory": ResponseCategory,
    "ResponseGroup": ResponseGroup,
    "ResponseResponse": ResponseResponse,
    "ResponseResponses": ResponseResponses,
    "ResponseSubgroup": ResponseSubgroup,
    "ReviewAnswerSummary": ReviewAnswerSummary,
    "ReviewAnswerSummaryAnswer": ReviewAnswerSummaryAnswer,
    "ReviewAnswerSummaryPage": ReviewAnswerSummaryPage,
    "ReviewNextRequest": ReviewNextRequest,
    "ReviewNextResponse": ReviewNextResponse,
    "ReviewNextResponseProgress": ReviewNextResponseProgress,
    "ReviewResetScoreResponse": ReviewResetScoreResponse,
    "ReviewResponse": ReviewResponse,
    "ReviewStartRequest": ReviewStartRequest,
    "ReviewStartResponse": ReviewStartResponse,
    "ReviewSubmitResponse": ReviewSubmitResponse,
    "ReviewUpdateRemediationRequest": ReviewUpdateRemediationRequest,
    "ReviewUpdateRemediationResponse": ReviewUpdateRemediationResponse,
    "ReviewUpdateRequest": ReviewUpdateRequest,
    "ReviewUpdateResponse": ReviewUpdateResponse,
    "Rule": Rule,
    "RuleCategory": RuleCategory,
    "RuleGroup": RuleGroup,
    "RuleSubcategory": RuleSubcategory,
    "RuleSubgroup": RuleSubgroup,
    "Rules": Rules,
    "SurveyDate": SurveyDate,
    "SurveyEntity": SurveyEntity,
    "SurveyStructure": SurveyStructure,
    "User": User,
    "UserReview": UserReview,
    "UserWithGroups": UserWithGroups,
};
export class ObjectSerializer {
    static findCorrectType(data, expectedType) {
        if (data == undefined) {
            return expectedType;
        }
        else if (primitives.indexOf(expectedType.toLowerCase()) !== -1) {
            return expectedType;
        }
        else if (expectedType === "Date") {
            return expectedType;
        }
        else {
            if (enumsMap.has(expectedType)) {
                return expectedType;
            }
            if (!typeMap[expectedType]) {
                return expectedType; // w/e we don't know the type
            }
            // Check the discriminator
            let discriminatorProperty = typeMap[expectedType].discriminator;
            if (discriminatorProperty == null) {
                return expectedType; // the type does not have a discriminator. use it.
            }
            else {
                if (data[discriminatorProperty]) {
                    var discriminatorType = data[discriminatorProperty];
                    if (typeMap[discriminatorType]) {
                        return discriminatorType; // use the type given in the discriminator
                    }
                    else {
                        return expectedType; // discriminator did not map to a type
                    }
                }
                else {
                    return expectedType; // discriminator was not present (or an empty string)
                }
            }
        }
    }
    static serialize(data, type, format) {
        if (data == undefined) {
            return data;
        }
        else if (primitives.indexOf(type.toLowerCase()) !== -1) {
            return data;
        }
        else if (type.lastIndexOf("Array<", 0) === 0) { // string.startsWith pre es6
            let subType = type.replace("Array<", ""); // Array<Type> => Type>
            subType = subType.substring(0, subType.length - 1); // Type> => Type
            let transformedData = [];
            for (let index in data) {
                let date = data[index];
                transformedData.push(ObjectSerializer.serialize(date, subType, format));
            }
            return transformedData;
        }
        else if (type === "Date") {
            if (format == "date") {
                let month = data.getMonth() + 1;
                month = month < 10 ? "0" + month.toString() : month.toString();
                let day = data.getDate();
                day = day < 10 ? "0" + day.toString() : day.toString();
                return data.getFullYear() + "-" + month + "-" + day;
            }
            else {
                return data.toISOString();
            }
        }
        else {
            if (enumsMap.has(type)) {
                return data;
            }
            if (!typeMap[type]) { // in case we dont know the type
                return data;
            }
            // Get the actual type of this object
            type = this.findCorrectType(data, type);
            // get the map for the correct type.
            let attributeTypes = typeMap[type].getAttributeTypeMap();
            let instance = {};
            for (let index in attributeTypes) {
                let attributeType = attributeTypes[index];
                instance[attributeType.baseName] = ObjectSerializer.serialize(data[attributeType.name], attributeType.type, attributeType.format);
            }
            return instance;
        }
    }
    static deserialize(data, type, format) {
        // polymorphism may change the actual type.
        type = ObjectSerializer.findCorrectType(data, type);
        if (data == undefined) {
            return data;
        }
        else if (primitives.indexOf(type.toLowerCase()) !== -1) {
            return data;
        }
        else if (type.lastIndexOf("Array<", 0) === 0) { // string.startsWith pre es6
            let subType = type.replace("Array<", ""); // Array<Type> => Type>
            subType = subType.substring(0, subType.length - 1); // Type> => Type
            let transformedData = [];
            for (let index in data) {
                let date = data[index];
                transformedData.push(ObjectSerializer.deserialize(date, subType, format));
            }
            return transformedData;
        }
        else if (type === "Date") {
            return new Date(data);
        }
        else {
            if (enumsMap.has(type)) { // is Enum
                return data;
            }
            if (!typeMap[type]) { // dont know the type
                return data;
            }
            let instance = new typeMap[type]();
            let attributeTypes = typeMap[type].getAttributeTypeMap();
            for (let index in attributeTypes) {
                let attributeType = attributeTypes[index];
                instance[attributeType.name] = ObjectSerializer.deserialize(data[attributeType.baseName], attributeType.type, attributeType.format);
            }
            return instance;
        }
    }
    /**
     * Normalize media type
     *
     * We currently do not handle any media types attributes, i.e. anything
     * after a semicolon. All content is assumed to be UTF-8 compatible.
     */
    static normalizeMediaType(mediaType) {
        if (mediaType === undefined) {
            return undefined;
        }
        return mediaType.split(";")[0].trim().toLowerCase();
    }
    /**
     * From a list of possible media types, choose the one we can handle best.
     *
     * The order of the given media types does not have any impact on the choice
     * made.
     */
    static getPreferredMediaType(mediaTypes) {
        /** According to OAS 3 we should default to json */
        if (!mediaTypes) {
            return "application/json";
        }
        const normalMediaTypes = mediaTypes.map(this.normalizeMediaType);
        let selectedMediaType = undefined;
        let selectedRank = -Infinity;
        for (const mediaType of normalMediaTypes) {
            if (supportedMediaTypes[mediaType] > selectedRank) {
                selectedMediaType = mediaType;
                selectedRank = supportedMediaTypes[mediaType];
            }
        }
        if (selectedMediaType === undefined) {
            throw new Error("None of the given media types are supported: " + mediaTypes.join(", "));
        }
        return selectedMediaType;
    }
    /**
     * Convert data to a string according the given media type
     */
    static stringify(data, mediaType) {
        if (mediaType === "application/json") {
            return JSON.stringify(data);
        }
        throw new Error("The mediaType " + mediaType + " is not supported by ObjectSerializer.stringify.");
    }
    /**
     * Parse data from a string according to the given media type
     */
    static parse(rawData, mediaType) {
        if (mediaType === undefined) {
            throw new Error("Cannot parse content. No Content-Type defined.");
        }
        if (mediaType === "application/json") {
            return JSON.parse(rawData);
        }
        throw new Error("The mediaType " + mediaType + " is not supported by ObjectSerializer.parse.");
    }
}

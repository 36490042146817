import Vue from "vue";
import { mapGetters } from "vuex";
import { GET_USER_REPORTS_ACTION } from "@/store/types";
import LanceReportsList from "@/components/ReportsList.vue";
import { lang } from "@/conf";
export default Vue.extend({
    name: "LanceDashboard",
    components: {
        LanceReportsList,
    },
    data() {
        return {
            content: {
                title: [
                    {
                        type: "heading1",
                        text: "Dashboard",
                        spans: [],
                    },
                ],
                your_ratings: {
                    title: [
                        {
                            type: "heading2",
                            text: "Your Ratings",
                            spans: [],
                        },
                    ],
                    last_updated: "Last Updated",
                    average_score: "Average Score",
                    completed_reviews: "Completed Reviews",
                },
                start_review: {
                    title: [
                        {
                            type: "heading2",
                            text: "Start New Review",
                            spans: [],
                        },
                    ],
                    button_text: "Start Review",
                    instruction: [
                        {
                            type: "paragraph",
                            text: "Press the button to begin.",
                            spans: [],
                        },
                    ],
                },
                reviews: {
                    title: [
                        {
                            type: "heading2",
                            text: "Recent Reviews",
                            spans: [],
                        },
                    ],
                    instruction: [
                        {
                            type: "paragraph",
                            text: "Below are your recent reviews. Use the filters to find partially completed reviews or access reports for completed reviews.",
                            spans: [],
                        },
                    ],
                },
                review: {
                    // For the review view
                    title: [
                        {
                            type: "heading1",
                            text: "Reviews",
                            spans: [],
                        },
                    ],
                    instruction: [
                        {
                            type: "paragraph",
                            text: "Use the filters to find partially completed reviews or access reports for completed reviews.",
                            spans: [],
                        },
                    ],
                    show_all: "All",
                    show_complete: "Complete",
                    show_incomplete: "Incomplete",
                    search: "Search Reviews",
                    reference: "Review Id",
                    creator: "Created By",
                    entity_count: "Num. Entities",
                    submitted_date: "Submitted",
                    structure_name: "Structure Name",
                    risk_score: "Risk Score",
                    completed: "Status",
                    review: "View Report",
                    resume: "Continue Review",
                },
            },
        };
    },
    computed: Object.assign(Object.assign({}, mapGetters("report", ["userReports"])), { hasReports() {
            return this.userReports && this.userReports.length > 0;
        },
        numberOfReviews() {
            return this.userReports && this.userReports["ReportMetaDataList"]
                ? this.userReports["ReportMetaDataList"].length
                : 0;
        },
        averageScore() {
            if (this.userReports &&
                this.userReports["ReportMetaDataList"] &&
                this.userReports["ReportMetaDataList"].length > 0) {
                const sum = this.userReports["ReportMetaDataList"]
                    .map((metaData) => metaData.risk_score)
                    .reduce((sum, score) => sum + score);
                return (sum / this.userReports["ReportMetaDataList"].length).toFixed(2);
            }
            return 0;
        } }),
    created() {
        this.$store.dispatch(`report/${GET_USER_REPORTS_ACTION}`);
        this.getContent();
        this.getReviewContent();
    },
    methods: {
        getContent() {
            this.$prismic.client.getSingle("dashboard", { lang }).then((response) => {
                if (!response)
                    return;
                let result = response.data;
                this.content.title = result.title;
                this.content.your_ratings.title = result.your_ratings_title;
                this.content.your_ratings.last_updated = result.your_ratings_last_updated;
                this.content.your_ratings.average_score = result.your_ratings_average_score;
                this.content.your_ratings.completed_reviews = result.your_ratings_completed_reviews;
                this.content.start_review.title = result.start_review_title;
                this.content.start_review.instruction = result.start_review_instruction;
                this.content.start_review.button_text = result.start_review_button_text;
                this.content.reviews.title = result.reviews_title;
                this.content.reviews.instruction = result.reviews_instruction;
            });
        },
        getReviewContent() {
            this.$prismic.client.getSingle("review", { lang }).then((response) => {
                if (!response)
                    return;
                this.content.review = response.data;
            });
        },
    },
});

import * as Survey from "survey-knockout";
export default () => {
    Survey.Serializer.addProperty("question", {
        name: "customLogic:text",
        default: "",
        category: "logic",
        visibleIndex: 0,
    });
    Survey.Serializer.addProperty("itemvalue", {
        name: "customLogic:text",
        default: "",
        category: "logic",
        visibleIndex: 0,
    });
    Survey.Serializer.addProperty("choices", {
        name: "customLogic:text",
        default: "",
        category: "logic",
        visibleIndex: 0,
    });
    Survey.Serializer.removeProperty("survey", "locale");
    Survey.Serializer.removeProperty("survey", "cookieName");
    Survey.Serializer.removeProperty("survey", "mode");
    Survey.Serializer.removeProperty("survey", "logoWidth");
    Survey.Serializer.removeProperty("survey", "logoHeight");
    Survey.Serializer.removeProperty("survey", "logoFit");
    Survey.Serializer.removeProperty("survey", "logoPosition");
    Survey.Serializer.removeProperty("survey", "logo");
    Survey.Serializer.removeProperty("survey", "navigation");
    Survey.Serializer.removeProperty("survey", "focusFirstQuestionAutomatic");
    Survey.Serializer.removeProperty("survey", "showPrevButton");
    Survey.Serializer.removeProperty("survey", "questionsOrder");
    Survey.Serializer.removeProperty("survey", "showProgressBar");
    // survey.js v1.8.x fails with an error if the below property is removed
    // Survey.Serializer.removeProperty("survey", "progressBarType");
    Survey.Serializer.removeProperty("survey", "maxTextLength");
    Survey.Serializer.removeProperty("survey", "maxOthersLength");
    Survey.Serializer.removeProperty("survey", "goNextPageAutomatic");
    Survey.Serializer.removeProperty("survey", "startSurveyText");
    Survey.Serializer.removeProperty("survey", "pagePrevText");
    Survey.Serializer.removeProperty("survey", "pageNextText");
    Survey.Serializer.removeProperty("survey", "completeText");
    Survey.Serializer.removeProperty("survey", "previewText");
    Survey.Serializer.removeProperty("survey", "editText");
    Survey.Serializer.removeProperty("survey", "questionStartIndex");
    Survey.Serializer.removeProperty("survey", "questionTitlePattern");
    Survey.Serializer.removeProperty("survey", "firstPageIsStarted");
    Survey.Serializer.removeProperty("survey", "questionsOnPageMode");
    Survey.Serializer.removeProperty("survey", "showPreviewBeforeComplete");
    Survey.Serializer.removeProperty("survey", "calculatedValues");
    Survey.Serializer.removeProperty("survey", "showQuestionNumbers");
    Survey.Serializer.removeProperty("survey", "triggers");
    Survey.Serializer.removeProperty("survey", "sendResultOnPageNext");
    Survey.Serializer.removeProperty("survey", "storeOthersAsComment");
    Survey.Serializer.removeProperty("survey", "clearInvisibleValues");
    Survey.Serializer.removeProperty("survey", "textUpdateMode");
    Survey.Serializer.removeProperty("survey", "focusOnFirstError");
    Survey.Serializer.removeProperty("survey", "completedHtml");
    Survey.Serializer.removeProperty("survey", "completedBeforeHtml");
    Survey.Serializer.removeProperty("survey", "completedHtmlOnCondition");
    Survey.Serializer.removeProperty("survey", "showCompletedPage");
    Survey.Serializer.removeProperty("survey", "navigateToUrl");
    Survey.Serializer.removeProperty("survey", "navigateToUrlOnCondition");
    Survey.Serializer.removeProperty("survey", "maxTimeToFinish");
    Survey.Serializer.removeProperty("survey", "maxTimeToFinishPage");
    Survey.Serializer.removeProperty("survey", "showTimerPanel");
    Survey.Serializer.removeProperty("survey", "showTimerPanelMode");
    Survey.Serializer.removeProperty("survey", "checkErrorsMode");
    Survey.Serializer.removeProperty("survey", "loadingHtml");
    Survey.Serializer.removeProperty("question", "readOnly");
    Survey.Serializer.removeProperty("question", "visibleIf");
    Survey.Serializer.removeProperty("question", "enableIf");
    Survey.Serializer.removeProperty("question", "requiredIf");
    Survey.Serializer.removeProperty("question", "page");
    Survey.Serializer.removeProperty("question", "width");
    Survey.Serializer.removeProperty("question", "startWithNewLine");
    Survey.Serializer.removeProperty("question", "indent");
    Survey.Serializer.removeProperty("question", "hideNumber");
    Survey.Serializer.removeProperty("question", "requiredErrorText");
    Survey.Serializer.removeProperty("question", "readOnly");
    Survey.Serializer.removeProperty("question", "validators");
    Survey.Serializer.removeProperty("question", "inputType");
    Survey.Serializer.removeProperty("question", "size");
    Survey.Serializer.removeProperty("question", "maxLength");
    Survey.Serializer.removeProperty("itemvalue", "visibleIf");
    Survey.Serializer.removeProperty("itemvalue", "enableIf");
    Survey.Serializer.removeProperty("itemvalue", "requiredIf");
};

import { async } from "@/api/util";
export default function (target) {
    return async({
        method: "post",
        url: `/editor/checklogic`,
        auth: true,
        data: {
            logic: target.value,
        },
    }).then((res) => {
        var _a;
        const newNode = document.createElement("div");
        const textarea = target;
        const c = (textarea === null || textarea === void 0 ? void 0 : textarea.parentNode).getElementsByClassName("logic-check");
        let html = "";
        for (const index in res.logicResponse) {
            let hints = "";
            res.logicResponse[index].hints.forEach((hint) => {
                hints += '<li class="mb-2">' + hint + "</li>";
            });
            html += hints ? '<ul class="list-unstyled">' + hints + "</ul>" : "";
            html +=
                '<div class="mb-0"><pre><code>' +
                    JSON.stringify(res.logicResponse[index].logicChecked, null, 2) +
                    "</code></pre></div><br>";
        }
        // TODO: verify if this ever worked.
        // Element.classList is read only property (https://developer.mozilla.org/en-US/docs/Web/API/Element/classList).
        newNode.classList = "logic-check alert alert-warning";
        newNode.innerHTML = html;
        if (c.length === 0) {
            (_a = textarea === null || textarea === void 0 ? void 0 : textarea.parentNode) === null || _a === void 0 ? void 0 : _a.insertBefore(newNode, textarea);
            newNode.innerHTML = html;
        }
        else {
            const tmp = (textarea === null || textarea === void 0 ? void 0 : textarea.parentNode).getElementsByClassName("logic-check");
            tmp[0].innerHTML = html;
        }
    });
}

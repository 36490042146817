import Vue from "vue";
import LanceCategories from "@/components/editor/rule/Categories.vue";
import { GET_DRAFT_SURVEY_ACTION_RULES, GET_DRAFT_SURVEY_ACTION_RESPONSE } from "@/store/types";
export default Vue.extend({
    name: "RuleEditor",
    components: {
        LanceCategories,
    },
    computed: {
        categories() {
            return this.$store.getters["editor/rules/categories"];
        },
    },
    created() {
        this.$store.dispatch(`editor/rules/${GET_DRAFT_SURVEY_ACTION_RULES}`, true);
        this.$store.dispatch(`editor/responses/${GET_DRAFT_SURVEY_ACTION_RESPONSE}`, true);
    },
});

import { __awaiter } from "tslib";
import { Auth } from "@aws-amplify/auth";
import authApi from "@/api/auth";
// initial state
const state = {
    user: null,
    optIn: true,
    isAuthenticated: false,
    authenticationStatus: {
        pending: false,
        success: false,
        message: null,
    },
};
// hasRole returns true if user has any of the given roles.
function hasRole(user, ...roles) {
    var _a, _b;
    const groups = ((_b = (_a = state.user) === null || _a === void 0 ? void 0 : _a.getSignInUserSession()) === null || _b === void 0 ? void 0 : _b.getIdToken().payload["cognito:groups"]) || [];
    return groups.some((group) => roles.includes(group));
}
const getters = {
    authenticatedUser: (state) => state.user,
    isAuthenticated: (state) => state.isAuthenticated,
    isAdmin: (state) => hasRole(state.user, "admin"),
    isTrialUser: (state) => hasRole(state.user, "trialuser"),
    isAccountLead: (state) => hasRole(state.user, "accountlead"),
    isSubmitter: (state) => hasRole(state.user, "submitter"),
    canSubmit: (state) => hasRole(state.user, "admin", "accountlead", "approval"),
    authenticationStatus: (state) => state.authenticationStatus,
    pending: (state) => state.authenticationStatus.pending,
    passwordChangeRequired: (state) => { var _a; return ((_a = state.user) === null || _a === void 0 ? void 0 : _a.challengeName) === "NEW_PASSWORD_REQUIRED"; },
    termsConfirmationRequired: (state) => hasRole(state.user, "trialuser"),
};
const mutations = {
    setAuthenticationError(state, err) {
        state.authenticationStatus = {
            pending: false,
            success: false,
            message: err.message,
        };
    },
    clearAuthenticationStatus: (state) => {
        state.authenticationStatus = {
            pending: true,
        };
    },
    setUserAuthenticated(state, user) {
        state.user = user;
        state.isAuthenticated = true;
    },
    clearAuthentication(state) {
        state.authenticationStatus = {
            pending: true,
        };
        state.user = null;
        state.userId = null;
        state.isAuthenticated = false;
        if (window) {
            window.Intercom("shutdown");
        }
    },
    successAuthentication(state) {
        state.authenticationStatus = {
            pending: false,
            success: true,
        };
    },
};
const actions = {
    clearAuthenticationStatus: (context) => {
        context.commit("clearAuthenticationStatus", null);
    },
    signIn(context, { username, password }) {
        return __awaiter(this, void 0, void 0, function* () {
            context.commit("clearAuthenticationStatus", null);
            try {
                const user = yield Auth.signIn(username, password);
                context.commit("setUserAuthenticated", user);
                context.commit("successAuthentication");
                return user;
            }
            catch (err) {
                context.commit("setAuthenticationError", err);
                return undefined;
            }
        });
    },
    signOut(context) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield Auth.signOut();
            }
            catch (err) {
                console.log(err);
            }
            context.commit("clearAuthentication", null);
        });
    },
    signUp(context, params) {
        return __awaiter(this, void 0, void 0, function* () {
            context.commit("clearAuthenticationStatus", null);
            try {
                yield Auth.signUp(params);
                context.commit("successAuthentication");
            }
            catch (err) {
                context.commit("setAuthenticationError", err);
            }
        });
    },
    confirmSignUp(context, { username, code }) {
        return __awaiter(this, void 0, void 0, function* () {
            context.commit("clearAuthenticationStatus", null);
            try {
                yield Auth.confirmSignUp(username, code);
                context.commit("successAuthentication");
            }
            catch (err) {
                context.commit("setAuthenticationError", err);
            }
        });
    },
    confirmResend(context, { username }) {
        return __awaiter(this, void 0, void 0, function* () {
            context.commit("clearAuthenticationStatus", null);
            try {
                yield Auth.resendSignUp(username);
                context.commit("successAuthentication");
            }
            catch (err) {
                context.commit("setAuthenticationError", err);
            }
        });
    },
    passwordReset(context, { username }) {
        return __awaiter(this, void 0, void 0, function* () {
            context.commit("clearAuthenticationStatus", null);
            try {
                yield Auth.forgotPassword(username);
                context.commit("successAuthentication");
            }
            catch (err) {
                context.commit("setAuthenticationError", err);
            }
        });
    },
    confirmPasswordReset(context, { username, code, password }) {
        return __awaiter(this, void 0, void 0, function* () {
            context.commit("clearAuthenticationStatus", null);
            try {
                yield Auth.forgotPasswordSubmit(username, code, password);
                context.commit("successAuthentication");
            }
            catch (err) {
                context.commit("setAuthenticationError", err);
            }
        });
    },
    passwordResetResend(context, { username }) {
        return __awaiter(this, void 0, void 0, function* () {
            context.commit("clearAuthenticationStatus", null);
            try {
                // TODO: Auth.passwordResetResend does not exist, and this action is never called
                // await Auth.passwordResetResend(username);
                context.commit("successAuthentication");
            }
            catch (err) {
                context.commit("setAuthenticationError", err);
            }
        });
    },
    passwordChange(context, { currentPassword, newPassword }) {
        return __awaiter(this, void 0, void 0, function* () {
            context.commit("clearAuthenticationStatus", null);
            try {
                const user = yield Auth.currentAuthenticatedUser();
                yield Auth.changePassword(user, currentPassword, newPassword);
                context.commit("successAuthentication");
            }
            catch (err) {
                context.commit("setAuthenticationError", err);
            }
        });
    },
    passwordSetFirstTime(context, newPassword) {
        return __awaiter(this, void 0, void 0, function* () {
            context.commit("clearAuthenticationStatus", null);
            const user = context.getters["authenticatedUser"];
            let success = false;
            yield Auth.completeNewPassword(user, newPassword, null)
                .then(() => {
                context.commit("successAuthentication");
                authApi.setUserEmailVerified(user.username);
                success = true;
            })
                .catch((err) => {
                context.commit("setAuthenticationError", err);
            });
            return success;
        });
    },
    setTermsAgreed(context, optIn) {
        return __awaiter(this, void 0, void 0, function* () {
            const user = yield Auth.currentAuthenticatedUser();
            context.commit("clearAuthenticationStatus", null);
            try {
                yield authApi.setTermsAgreed(user.attributes.email, optIn);
                context.commit("successAuthentication");
            }
            catch (err) {
                context.commit("setAuthenticationError", err);
            }
        });
    },
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

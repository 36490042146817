// import CustomerUserList from '@/components/users/CustomerUserList'
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import { GET_USER_LIST_ACTION, UPDATE_USER_FILTER_MUTATION, DELETE_CUSTOMER_ACTION } from "@/store/types";
import { lang } from "@/conf";
export default Vue.extend({
    name: "UsersList",
    data() {
        return {
            content: {
                title: [
                    {
                        type: "heading1",
                        text: "User List",
                        spans: [],
                    },
                ],
                description: [
                    {
                        type: "paragraph",
                        text: "",
                        spans: [],
                    },
                ],
                userName: "User Name",
                userEmail: "User Email",
                userCompany: "Use Company",
            },
        };
    },
    computed: Object.assign(Object.assign(Object.assign({}, mapGetters("users", ["deleteCustomer"])), mapGetters("auth", ["isAccountLead", "isAdmin"])), { companyFilter() {
            return this.$store.getters["users/companyFilter"];
        },
        companyUsers() {
            return this.$store.getters["users/userList"];
        },
        companies() {
            return this.companyUsers.map((v) => v.companyName).filter((v) => v) || [];
        },
        selectedCompanyUsers() {
            return this.companyFilter
                ? this.companyUsers.filter((v) => v.companyName === this.companyFilter)
                : this.companyUsers;
        } }),
    created() {
        this.getContent();
        this.$store.dispatch(`users/${GET_USER_LIST_ACTION}`);
    },
    methods: Object.assign({ deleteDialog(customerID) {
            if (confirm("Are you sure you want to delete this customer?\nThis action cannot be undone.")) {
                this.$store.dispatch(`users/${DELETE_CUSTOMER_ACTION}`, customerID);
            }
        },
        getContent() {
            this.$prismic.client.getSingle("user_list", { lang }).then((response) => {
                if (!response)
                    return;
                this.content = response.data;
            });
        },
        changeCompanyFilter(event) {
            this.doChangeCompanyFilter(event.target.value);
        } }, mapMutations("users", {
        doChangeCompanyFilter: UPDATE_USER_FILTER_MUTATION,
    })),
});

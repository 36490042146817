import { ObservableDefaultApi } from './ObservableAPI';
export class PromiseDefaultApi {
    constructor(configuration, requestFactory, responseProcessor) {
        this.api = new ObservableDefaultApi(configuration, requestFactory, responseProcessor);
    }
    /**
     * @param id review id
     */
    deleteCompletedReview(id, _options) {
        const result = this.api.deleteCompletedReview(id, _options);
        return result.toPromise();
    }
    /**
     * @param id review id
     */
    deleteUncompletedReview(id, _options) {
        const result = this.api.deleteUncompletedReview(id, _options);
        return result.toPromise();
    }
    /**
     * @param id review id
     */
    getReviewAnswerSummary(id, _options) {
        const result = this.api.getReviewAnswerSummary(id, _options);
        return result.toPromise();
    }
    /**
     * @param id review id
     * @param remediated only when requesting \&quot;application/pdf\&quot;
     */
    getReviewReport(id, remediated, _options) {
        const result = this.api.getReviewReport(id, remediated, _options);
        return result.toPromise();
    }
    /**
     * @param surveyId
     */
    getSurveyDefinitions(surveyId, _options) {
        const result = this.api.getSurveyDefinitions(surveyId, _options);
        return result.toPromise();
    }
    /**
     * @param surveyId
     */
    getSurveyResponses(surveyId, _options) {
        const result = this.api.getSurveyResponses(surveyId, _options);
        return result.toPromise();
    }
    /**
     * @param surveyId
     */
    getSurveyRules(surveyId, _options) {
        const result = this.api.getSurveyRules(surveyId, _options);
        return result.toPromise();
    }
    /**
     * @param id user id
     */
    getUser(id, _options) {
        const result = this.api.getUser(id, _options);
        return result.toPromise();
    }
    /**
     * @param id review id
     */
    getUserReviews(id, _options) {
        const result = this.api.getUserReviews(id, _options);
        return result.toPromise();
    }
    /**
     */
    getUsers(_options) {
        const result = this.api.getUsers(_options);
        return result.toPromise();
    }
    /**
     * @param id review id
     * @param ReviewNextRequest
     */
    nextReview(id, ReviewNextRequest, _options) {
        const result = this.api.nextReview(id, ReviewNextRequest, _options);
        return result.toPromise();
    }
    /**
     * @param id review id
     */
    resetReviewScore(id, _options) {
        const result = this.api.resetReviewScore(id, _options);
        return result.toPromise();
    }
    /**
     * @param id review id
     */
    resubmitReview(id, _options) {
        const result = this.api.resubmitReview(id, _options);
        return result.toPromise();
    }
    /**
     * @param id review id
     */
    revertReview(id, _options) {
        const result = this.api.revertReview(id, _options);
        return result.toPromise();
    }
    /**
     * @param ReviewStartRequest
     */
    startReview(ReviewStartRequest, _options) {
        const result = this.api.startReview(ReviewStartRequest, _options);
        return result.toPromise();
    }
    /**
     * @param id review id
     */
    submitReview(id, _options) {
        const result = this.api.submitReview(id, _options);
        return result.toPromise();
    }
    /**
     * @param id review id
     * @param ReviewUpdateRemediationRequest
     */
    updateReviewRemediation(id, ReviewUpdateRemediationRequest, _options) {
        const result = this.api.updateReviewRemediation(id, ReviewUpdateRemediationRequest, _options);
        return result.toPromise();
    }
    /**
     * @param id review id
     * @param ReviewUpdateRequest
     */
    updateReviewReport(id, ReviewUpdateRequest, _options) {
        const result = this.api.updateReviewReport(id, ReviewUpdateRequest, _options);
        return result.toPromise();
    }
    /**
     * @param id review id
     */
    useLatestSurvey(id, _options) {
        const result = this.api.useLatestSurvey(id, _options);
        return result.toPromise();
    }
}

import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
export default Vue.extend({
    name: "ResetPassword",
    data() {
        return {
            username: "",
        };
    },
    computed: Object.assign({}, mapGetters("auth", ["pending", "authenticationStatus"])),
    methods: Object.assign({}, mapActions("auth", ["passwordReset"])),
});
